import { baseApi } from "src/shared/api";
import { ApiTag } from "src/shared/constants/api-tag";

import { MeResponse } from "./types";

export const authApi = baseApi.injectEndpoints({
  endpoints: ({ query }) => ({
    currentUser: query<MeResponse, void>({
      query: () => "/api/getme",
      providesTags: [ApiTag.ME],
    }),
  }),
});
