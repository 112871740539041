import { useState } from 'react';
import { useAuth } from 'src/services/CheckAuth/AuthContext';
import { useNavigate } from 'react-router-dom';
import useAxios from 'src/services/CheckAuth/useAxiosInstance';

export const useLogin = (email, password, rememberMe) => {
  const { login } = useAuth();
  const axiosInstance = useAxios();
  const [error, setError] = useState('');
  const navigate = useNavigate()

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await axiosInstance.post('api/login', {
        Email: email,
        Password: password
      });

      if (response.status === 200) {
        const userData = response.data;
        console.log(userData)
        login({
          UserID: userData.UserID, 
          Token: userData.Token, 
          RedmineUserID: userData.RedmineUserID,
          RedmineToken: userData.RedmineToken,
          RefreshToken: userData.RefreshToken,
          Name: userData.Name,
          Email: userData.Email,
          role:'11',
          saved: rememberMe
        });
        navigate('/tasklist')
      }
    } catch (err) {
      if (!err.response) {
        setError('Нет доступа к интернету');
      } else if (err.response.status >= 500) {
        setError('Ошибка сервера, попробуйте позже');
      } else if (err.response.status >= 400) {
        setError('Неправильный логин или пароль');
      } else {
        setError('Произошла неизвестная ошибка');
      }
    }
  };

  return [error, handleLogin];
};
