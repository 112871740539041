import React from 'react';
import styles from '../../assets/styles/LoginPage.module.css';
import { Link } from 'react-router-dom';

const WelcomeSection = ({title, text, buttonName, buttonPath}) => {
  return (
    <div className={styles.overlay}>
      <h1 className={styles.welcomeTitle}>{title}</h1>
      <p className={styles.welcomeText}>
        {text}
      </p>
      <Link to={buttonPath} className={styles.registerButton}>{buttonName}</Link>
    </div>
  );
};

export default WelcomeSection;