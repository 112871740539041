import { styled } from "@mui/material";

import { useIsSidebarOpen } from "src/entities/chat/hooks";

export const ChatLayout = (props: React.PropsWithChildren) => {
  const { children } = props;

  const { isSidebarOpen } = useIsSidebarOpen();

  return (
    <ChatLayoutContainer isOpen={isSidebarOpen}>{children}</ChatLayoutContainer>
  );
};

const ChatLayoutContainer = styled("div")<{ isOpen?: boolean }>`
  * {
    text-align: initial;
  }
  display: grid;
  grid-template-areas: "sidebar body";
  grid-template-columns: 350px 1fr;
  height: calc(100vh - 80px - 2px);
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin-left: ${({ isOpen }) => (isOpen ? "" : "-350px")};
  transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const ChatLayoutSidebar = styled("div")`
  overflow: hidden;
  grid-area: "sidebar";
  display: grid;
  grid-template-areas: "header" "content";
  grid-template-rows: auto 1fr;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
`;

const ChatLayoutContent = styled("div")`
  overflow: hidden;
  grid-area: "content";
  height: 100%;
`;

const ChatLayoutBody = styled("div")`
  overflow: hidden;
  grid-area: "body";
  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: 60px 1fr 60px;
  & > :nth-child(2) {
    background: #f8f9fa;
  }
`;

const ChatLayoutHeader = styled("div")`
  grid-area: "header";
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

const ChatLayoutFooter = styled("div")`
  grid-area: "footer";
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

ChatLayout.Sidebar = ChatLayoutSidebar;
ChatLayout.Body = ChatLayoutBody;

ChatLayout.Header = ChatLayoutHeader;
ChatLayout.Content = ChatLayoutContent;
ChatLayout.Footer = ChatLayoutFooter;
