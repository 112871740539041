export function buildTree(data, parentIdField = 'parent', idField = 'id') {
    const map = {};

    data.forEach(item => {
        map[item[idField]] = { ...item, children: [] };
    });
    const tree = [];
    data.forEach(item => {
        if (item[parentIdField] && item[parentIdField][idField]) {
            const parent = map[item[parentIdField][idField]];
            if (parent) {
                parent.children.push(map[item[idField]]);
            }
        } else {
            tree.push(map[item[idField]]);
        }
    });
    return tree;
}

export function formatTree(tree, prefix = '', nameField = 'name', idField = 'id') {
    let result = [];
    tree.forEach(node => {
        result.push({ id: node[idField], name: `${prefix}${node[nameField]}` });
        if (node.children.length > 0) {
            result = result.concat(formatTree(node.children, `${prefix}>>`, nameField, idField));
        }
    });
    return result;
}
