import { styled } from '@mui/material/styles';
import logo from '../../../../assets/NavBarIcons/logo_navbar.png';

const Container = styled('div')`
  padding: 20px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Logo = styled('img')`
  width: 150px;
  height: 150px;
  margin-right: 20px;
`;

const CompanyInfo = styled('div')`
  h1 {
    margin: 0;
    font-size: 2em;
  }

  p {
    margin: 5px 0;
  }
`;

const InfoTable = styled('table')`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  td:first-of-type {
    font-weight: bold;
    background-color: #f9f9f9;
  }
`;

const CounteragentViewer = ({ counteragent }) => {
  return (
    <Container>
      <Header>
        <Logo src={logo} alt="Logo" />
        <CompanyInfo>
          <h1>{counteragent.Shortname}</h1>
          <p>{counteragent.LegalAddress}</p>
          <p>Телефон: {counteragent.PhoneNumber}</p>
          <p>Email: {counteragent.ContactEmail}</p>
          <p>Web-сайт: {counteragent.Website}</p>
        </CompanyInfo>
      </Header>
      <InfoTable>
        <tbody>
          <tr>
            <td>Полное наименование:</td>
            <td>{counteragent.Name}</td>
          </tr>
          <tr>
            <td>Сокращенное наименование:</td>
            <td>{counteragent.Shortname}</td>
          </tr>
          <tr>
            <td>Юридический адрес:</td>
            <td>{counteragent.LegalAddress}</td>
          </tr>
          <tr>
            <td>Фактический адрес:</td>
            <td>{counteragent.PhysicalAddress}</td>
          </tr>
          <tr>
            <td>ИНН:</td>
            <td>{counteragent.Iinn}</td>
          </tr>
          <tr>
            <td>ОГРН:</td>
            <td>{counteragent.Ogrn}</td>
          </tr>
          <tr>
            <td>КПП:</td>
            <td>{counteragent.Kpp}</td>
          </tr>
          <tr>
            <td>Банковский счёт:</td>
            <td>{counteragent.BankAccountNumber}</td>
          </tr>
          <tr>
            <td>Корреспондентский счёт:</td>
            <td>{counteragent.CorrespondentAccount}</td>
          </tr>
          <tr>
            <td>БИК банка:</td>
            <td>{counteragent.BankBIK}</td>
          </tr>
          <tr>
            <td>Банк:</td>
            <td>{counteragent.BankName}</td>
          </tr>
          <tr>
            <td>Адрес банка:</td>
            <td>{counteragent.BankAddress}</td>
          </tr>
          <tr>
            <td>Генеральный директор:</td>
            <td>{counteragent.CEO}</td>
          </tr>
          <tr>
            <td>Действующий на основании:</td>
            <td>{counteragent.Acting_basis}</td>
          </tr>
          <tr>
            <td>Заместитель генерального директора:</td>
            <td>{counteragent.Deputy_CEO}</td>
          </tr>
          <tr>
            <td>Главный бухгалтер:</td>
            <td>{counteragent.Chief_accountant}</td>
          </tr>
        </tbody>
      </InfoTable>
    </Container>
  );
};

export default CounteragentViewer;
