import { useState, useEffect } from "react";
import ObjectCreateForm from "./ObjectCreateForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";

const ObjectCreateContainer = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Address: "",
    CompletionDate: "",
    ProjectID: "",
  });
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get(
          "api/list/project",
          {
            headers: { "X-dmtoken": token },
          }
        );
        setProjects(response.data[0]);
      } catch (error) {
        console.error("Ошибка при загрузке проектов", error);
      }
    };

    fetchProjects();
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const selectedProject = projects.find(
        (project) => project.ID === parseInt(formData.ProjectID)
      );
      console.log(selectedProject);
      const requestData = {
        Name: formData.Name,
        Address: formData.Address,
        CompletionDate:
          new Date(formData.CompletionDate).toISOString().split("T")[0] +
          "T00:00:00Z",
        ProjectID: parseInt(formData.ProjectID),
        ProjectName: selectedProject ? selectedProject.Name : "",
      };

      await axiosInstance.post(
        "api/bp/object",
        requestData,
        {
          headers: { "X-dmtoken": token },
        }
      );

      navigate("/work/objectlist");
    } catch (error) {
      console.error("Ошибка при создании объекта", error);
      alert("Ошибка при создании объекта");
    }
  };

  return (
    <ObjectCreateForm
      formData={formData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      projects={projects}
    />
  );
};

export default ObjectCreateContainer;
