import { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';

const ResourceModal = ({ newResource, onChange, onSave }) => {
  const [errors, setErrors] = useState({ name: '', url: '' });

  const validate = () => {
    let tempErrors = { name: '', url: '' };
    let isValid = true;

    if (!newResource.name) {
      tempErrors.name = 'Название обязательно';
      isValid = false;
    }

    if (!newResource.url) {
      tempErrors.url = 'URL обязателен';
      isValid = false;
    } else if (!/^https?:\/\/.+/.test(newResource.url)) {
      tempErrors.url = 'Некорректный URL';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSave = () => {
    if (validate()) {
      onSave();
    }
  };

  return (
    <>
      <Typography variant="h6">Добавление ресурса</Typography>
      <TextField 
        label="Название" 
        name="name" 
        value={newResource.name} 
        onChange={onChange} 
        fullWidth 
        margin="normal"
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField 
        label="URL" 
        name="url" 
        value={newResource.url} 
        onChange={onChange} 
        fullWidth 
        margin="normal"
        error={!!errors.url}
        helperText={errors.url}
      />
      <Button variant="contained" color="primary" onClick={handleSave} fullWidth>Сохранить</Button>
    </>
  );
};

export default ResourceModal;
