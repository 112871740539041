import { Button, IconButton, Typography, Box } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const FileInput = styled('input')({
  display: 'none',
});

const FileUpload = ({ onFileUpload, fileName, onFileRemove }) => {
  return (
    <Box>
      <label htmlFor="file-upload">
        <FileInput
          id="file-upload"
          type="file"
          accept=".csv"
          onChange={onFileUpload}
        />
        <Button variant="contained" component="span" startIcon={<UploadFileIcon />}>
          Выбрать файл .csv
        </Button>
      </label>
      {fileName && (
        <Box display="flex" alignItems="center" marginTop={2}>
          <Typography variant="body1">{fileName}</Typography>
          <IconButton onClick={onFileRemove} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
