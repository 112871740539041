import { Helmet } from 'react-helmet';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const FormGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const ObjectCreateForm = ({ formData, handleChange, handleSubmit, projects }) => {
  return (
    <Container>
      <Helmet>
        <title>Добавить объект</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <Typography variant="h4" component="h1">Создание объекта</Typography>
        <FormGroup>
          <TextField
            label="Название"
            name="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Адрес"
            name="Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Дата завершения строительства"
            type="date"
            name="CompletionDate"
            value={formData.completionDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            select
            label="Проект"
            name="ProjectID"
            value={formData.ProjectID}
            onChange={handleChange}
            required
          >
            <MenuItem value="">
              <em>Выберите проект</em>
            </MenuItem>
            {projects.map(project => (
              <MenuItem key={project.ID} value={project.ID}>
                {project.Name}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <Button type="submit" variant="contained" color="primary">
          Сохранить
        </Button>
      </Form>
    </Container>
  );
};

export default ObjectCreateForm;
