import React from 'react';
import styles from '../../../assets/styles/LoginPage.module.css';
import RegisterForm from './RegisterForm';
import WelcomeSection from '../WelcomeSection';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <div className={styles.rightSide}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h2 className={styles.authTitle}>Регистрация</h2>
        <RegisterForm />
      </div>
      <div className={styles.leftSide}>
        <WelcomeSection title='Уже зарегистрированы?' text='Вы на странице регистрации. Укажите данные для создания аккаунта.' buttonName='Авторизоваться' buttonPath='/login'/>
      </div>
    </div>
  );
};

export default LoginPage;
