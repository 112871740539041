import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query";

import { BASE_URL } from "./constants/config";
import { ApiTag } from "./constants/api-tag";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers) => {
    const userData = JSON.parse(String(sessionStorage.getItem('user')));
    const token = userData ? userData.Token : null;

    if (token) {
      headers.set("X-dmtoken", token);
    }

    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const baseApi = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: [...Object.values(ApiTag)],
  endpoints: () => ({}),
});
