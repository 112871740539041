import { Box, styled } from '@mui/material';
import ResourceList from './ResourceList';
import ResourceForm from './ResourceForm';

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
});

const ModalContainer = styled(Box)({
  backgroundColor: '#fff',
  border: '1px solid #000000',
  borderRadius: '8px',
  padding: '20px',
  width: '90%',
  maxWidth: '900px',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  zIndex: 1001,
});

const ModalContent = ({
  selectedResource,
  isAnalogue,
  setIsAnalogue,
  qty,
  setQty,
  requiredAtDateTime,
  setRequiredAtDateTime,
  note,
  setNote,
  excess,
  setExcess,
  handleBackClick,
  handleSaveClick,
  handleCancelClick,
  isActiveSave,
  resources,
  onResourceSelect,
  residue,
  name,
  setName,
  measure,
  setMeasure,
  requestItems,
  isEditing,
  onSearch
}) => {
  return (
    <Overlay>
      <ModalContainer>
        {!selectedResource ? (
          <ResourceList
            resources={resources}
            requestItems={requestItems}
            onResourceSelect={onResourceSelect}
            handleCancelClick={handleCancelClick}
            onSearch={onSearch}
          />
        ) : (
          <ResourceForm
            selectedResource={selectedResource}
            residue={residue}
            name={name}
            setName={setName}
            measure={measure}
            setMeasure={setMeasure}
            isAnalogue={isAnalogue}
            setIsAnalogue={setIsAnalogue}
            qty={qty}
            setQty={setQty}
            requiredAtDateTime={requiredAtDateTime}
            setRequiredAtDateTime={setRequiredAtDateTime}
            note={note}
            setNote={setNote}
            excess={excess}
            setExcess={setExcess}
            handleBackClick={handleBackClick}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            isActiveSave={isActiveSave}
            isEditing={isEditing}
          />
        )}
      </ModalContainer>
    </Overlay>
  );
};

export default ModalContent;
