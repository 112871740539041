import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/shared/hooks";

import { chatApi } from "./api";
import { chatSlice } from "./slice";
import { Chat } from "./types";

export const {
  useMessagesQuery,
  useParticipantsQuery,
  useCreateChatMutation,
  useChatListQuery,
  useChatByIdQuery,
  useLazyChatByIdQuery,
  useSendMessageMutation,
  useDeleteChatMutation,
} = chatApi;

export const useCurrentChat = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const { data, isLoading } = useChatListQuery();
  const dispatch = useAppDispatch();
  const currentChat = useAppSelector(({ chat }) => chat.currentChat);

  const handleSetCurrentChat = useCallback(
    (chat: Chat) => {
      dispatch(setCurrentChat(chat));
    },
    [dispatch]
  );

  useEffect(() => {
    const currentChatFromQuery = data?.find(({ ID }) => chatId === String(ID));
    if (currentChatFromQuery) {
      handleSetCurrentChat(currentChatFromQuery);
    }
  }, [chatId, data, handleSetCurrentChat]);

  return {
    currentChat,
    setCurrentChat: handleSetCurrentChat,
    isLoading,
  };
};

export const useIsSidebarOpen = () => {
  const dispatch = useAppDispatch();

  const isSidebarOpen = useAppSelector((state) => state.chat.isSidebarOpen);

  const handleSetIsSidebarOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsSidebarOpen(isOpen));
    },
    [dispatch]
  );

  return {
    isSidebarOpen,
    setIsSidebarOpen: handleSetIsSidebarOpen,
  };
};

const { setCurrentChat, setIsSidebarOpen } = chatSlice.actions;
