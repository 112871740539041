import { useEffect, useState } from "react";
import UserDetails from "./UserDetails";
import { CircularProgress, styled } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "src/services/CheckAuth/useAxiosInstance";

const Container = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #f5f5f5;
`;

const UserDetailsContainer = () => {
  const { userID } = useParams();
  const [selectedUser, setSelectedUser] = useState(null);

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/bp/user?ID=${userID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setSelectedUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userID, token]);

  if (!selectedUser) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return <UserDetails user={selectedUser} />;
};

export default UserDetailsContainer;
