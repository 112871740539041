import { Typography, Grid, styled, Paper, Box, IconButton, Tooltip } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  padding: 10px;
`;

const ProfileHeader = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.action.selected,
	textAlign: 'left',
	padding: '20px',
	borderRadius: '4px',
	marginBottom: '5px'
}));

const UserDetails = ({ user }) => {
	const navigate = useNavigate();

	const handleEditClick = () => {
		navigate(`/settings/userlist/edit/${user.ID}`)
	}

	return (
		<Container>
			<Helmet>
				<title>Пользователь {user.Name}</title>
			</Helmet>
			<ProfileHeader>
				<Typography variant="h4">
					<strong>{user.Name}</strong>
					<Tooltip title='Редактировать'>
						<IconButton onClick={handleEditClick}>
							<BorderColorIcon fontSize='medium' />
						</IconButton>
					</Tooltip>
				</Typography>
				<Typography variant="body1">
					Email: <strong>{user.Email}</strong>
				</Typography>
				<Typography variant="body1">
					Телефон: <strong>{user.Phone}</strong>
				</Typography>
				<Typography variant="body1">
					Никнейм: <strong>{user.Nickname}</strong>
				</Typography>
				<Typography variant="body1">
					Зарегистрирован: <strong>{new Date(user.CT).toLocaleDateString()}</strong>
				</Typography>
			</ProfileHeader>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper elevation={3} style={{ padding: '20px', height: '100%' }}>

					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper elevation={3} style={{ padding: '20px', height: '100%' }}>

					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};

export default UserDetails;
