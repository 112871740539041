import React from 'react';
import TaskItem from './TaskItem';
import styles from '../../../assets/styles/TaskPage/TaskList.module.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import addButtonIcon from '../../../assets/images/add-button.png';
import loadingGif from '../../../assets/images/loading.gif';

const TaskList = ({ tasks, loading, error, handleDelete, lastTaskElementRef }) => {
  const navigate = useNavigate();

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Задачи</title>
      </Helmet>
      <div className={styles.content}>
        <div className={styles.tasks}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              <span>ID</span>
              <span>Название</span>
              <span>Категория</span>
              <span>Кому поручено</span>
            </div>
            <button className={styles.createButton} onClick={() => navigate(`/tasklist/createtask`)}>
              <img src={addButtonIcon} alt='Создать задачу' />
            </button>
          </div>
          <div className={styles.list}>
            {tasks.map((task, index) => {
              if (tasks.length === index + 1) {
                return (
                  <TaskItem
                    ref={lastTaskElementRef}
                    key={index}
                    task={task}
                    onDelete={handleDelete}
                  />
                );
              } else {
                return (
                  <TaskItem
                    key={index}
                    task={task}
                    onDelete={handleDelete}
                  />
                );
              }
            })}
          </div>
          {loading && <img src={loadingGif} alt='Loading...' />}
        </div>
      </div>
    </div>
  );
};

export default TaskList;
