import { UIEventHandler } from "react";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { LinearProgress, styled } from "@mui/material";

import { useCurrentUserQuery } from "src/entities/auth/hooks";
import { useMessagesQuery } from "src/entities/chat/hooks";
import { renderMessages } from "src/widgets/chat-list/utils";

export const ChatByIdPage = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const { chatId } = useParams<{ chatId: string }>();

  const { data, isLoading } = useMessagesQuery(
    {
      ID: chatId || "",
    },
    {
      skip: !chatId || !currentUser,
      pollingInterval: 1000,
      skipPollingIfUnfocused: true,
    }
  );

  const handleScroll = debounce<UIEventHandler<HTMLDivElement>>((e) => {
    const target = e.target as HTMLDivElement & EventTarget;
    const shouldFetchMore =
      target.scrollTop + (target.scrollHeight - target.offsetHeight) === 0;
    console.log(shouldFetchMore ? "Нужно подгрузить" : "подгружать не нужно");
  }, 500);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <ChatMessageListContainer onScroll={handleScroll}>
      <ChatMessageListContent>
        {renderMessages(data, currentUser?.UserID)}
      </ChatMessageListContent>
    </ChatMessageListContainer>
  );
};

const ChatMessageListContainer = styled("div")`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2, 2, 2, 3)};
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
`;

const ChatMessageListContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  & > :first-child {
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;
