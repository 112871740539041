import { formatNumber } from 'src/services/Utils/FormatNumber';
import ItemListContainer from '../../../../components/List/ItemListContainer';

const InvoiceListContainer = () => {
  const invoiceFields = [
    { label: 'Объект', key: 'ObjectName', path: '/work/objectlist/', pathID: 'ObjectID' },
    { label: 'Сумма с НДС', key: 'FPWithNDS', format: (value) => `${formatNumber(value)} руб.` }
  ];

  return (
    <ItemListContainer
      apiUrl="api/actions/page_data?page=ListInvoice"
      enumKey="Счёт"
      title="Счета"
      fields={invoiceFields}
      searchKeys={[
        'Name',
        'ObjectName',
        'CounteragentName',
        'InvoiceNumber',
        'FPWithNDS',
        'InvoiceDate',
        'ID'
      ]}
      navigatePath="/work/invoicelist"
      createPath="/work/invoicelist/createinvoice"
      multiSelect={true}
    />
  );
};

export default InvoiceListContainer;
