import { useState, useEffect } from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Papa from 'papaparse';
import { detect, convert } from 'encoding-japanese';

import FileUpload from './FileUpload';
import CsvInput from './CsvInput';
import CsvTable from './CsvTable';
import JsonOutput from './JsonOutput';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const CsvToJsonConverter = () => {
  const [csvData, setCsvData] = useState([]);
  const [csvInputValue, setCsvInputValue] = useState('');
  const [jsonHeaders, setJsonHeaders] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newHeader, setNewHeader] = useState('');
  const [jsonData, setJsonData] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (csvData.length > 0) {
      const headers = jsonHeaders.length ? jsonHeaders : csvData[0];
      const data = csvData.slice(1).map(row =>
        headers.reduce((obj, header, index) => {
          obj[header] = row[index];
          return obj;
        }, {})
      );
      setJsonData(JSON.stringify(data, null, 2));
    }
  }, [csvData, jsonHeaders]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      const detectedEncoding = detect(uint8Array);

      const dataView = new DataView(arrayBuffer);
      const decoder = new TextDecoder('windows-1251');

      const decodedString = detectedEncoding !== 'BINARY' ? convert(uint8Array, {
        to: 'unicode',
        from: detectedEncoding,
        type: 'string'
      }) : decoder.decode(dataView);

      setCsvInputValue(decodedString);

      Papa.parse(decodedString, {
        complete: (results) => {
          setCsvData(results.data);
          setJsonHeaders(results.data[0]);
        }
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const handleCsvInputChange = (csv) => {
    setCsvInputValue(csv);
    Papa.parse(csv, {
      complete: (result) => {
        setCsvData(result.data);
        setJsonHeaders(result.data[0]);
      },
      header: false,
      skipEmptyLines: true,
    });
  };

  const handleFileRemove = () => {
    setFileName('');
    setCsvInputValue('');
    setCsvData([]);
    setJsonHeaders([]);
    setJsonData('');
  };

  const handleEditHeader = (index) => {
    setEditingIndex(index);
    setNewHeader(jsonHeaders[index]);
  };

  const handleSaveHeader = () => {
    const updatedHeaders = [...jsonHeaders];
    updatedHeaders[editingIndex] = newHeader;
    setJsonHeaders(updatedHeaders);
    setEditingIndex(null);
    setNewHeader('');
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Конвертировать CSV в JSON</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <FileUpload
              onFileUpload={handleFileUpload}
              fileName={fileName}
              onFileRemove={handleFileRemove}
            />
          </Paper>
          <CsvInput
            csvValue={csvInputValue}
            onCsvInputChange={handleCsvInputChange}
          />
          {csvData.length > 0 && (
            <CsvTable
              headers={jsonHeaders}
              editingIndex={editingIndex}
              newHeader={newHeader}
              handleEditHeader={handleEditHeader}
              handleSaveHeader={handleSaveHeader}
              setNewHeader={setNewHeader}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <JsonOutput jsonData={jsonData} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CsvToJsonConverter;
