import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Item from './Item';

const InvoiceItemsTable = ({ invoice }) => (
  <TableContainer component={Paper} style={{ marginTop: 20 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Номер счёта</TableCell>
          <TableCell>Требуется к</TableCell>
          <TableCell>Наименование</TableCell>
          <TableCell>Кол-во</TableCell>
          <TableCell>Ед.</TableCell>
          <TableCell>Цена с НДС</TableCell>
          <TableCell>Цена со скидкой</TableCell>
          <TableCell>Скидка руб.</TableCell>
          <TableCell>Сумма с НДС</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoice.Items.map((item) => (
          <Item key={item.InvoiceItemID} item={item} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default InvoiceItemsTable;
