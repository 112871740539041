import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RequestViewerPage from "./RequestViewerPage";
import { CircularProgress, Box } from "@mui/material";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";

const RequestViewerContainer = () => {
  const [orders, setOrders] = useState([]);
  const { requestID } = useParams();
  const [request, setRequest] = useState();
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState([]);

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/actions/page_data?page=ViewingZayavka&ID=${requestID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setOrders(response.data.RequestItems || []);
        setRequest(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchStatuses = async () => {
      try {
        const statusesResponse = await axiosInstance.get(
          "api/actions/enums"
        );
        setOrderStatuses(statusesResponse.data["Заказ"].statuses);
        setRequestStatuses(statusesResponse.data["Заявка"].statuses);
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchData();
    fetchStatuses();
  }, [requestID, token]);

  if (!request) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <RequestViewerPage
      request={request}
      orders={orders}
      orderStatuses={orderStatuses}
      requestStatuses={requestStatuses}
      ID={requestID}
    />
  );
};

export default RequestViewerContainer;
