import PropTypes from 'prop-types';
import { TableCell, TableRow, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    NotificationsNone as NotifiedIcon,
    WorkOutline as AtWorkIcon,
    CheckCircleOutline as ApprovedIcon,
    HighlightOff as DeniedIcon,
  } from '@mui/icons-material';

const ItemName = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    color: '#007bff',
  },
});

const VerifierItem = ({ verifier }) => {
  const navigate = useNavigate();

  const getStatusIcon = (status) => {
    switch (status) {
        case 0: return <NotifiedIcon />;
        case 1: return <AtWorkIcon />;
        case 2: return <DeniedIcon />;
        case 3: return <ApprovedIcon />;
      default: return null;
    }
  };

  const statusIcon = getStatusIcon(verifier.VerificationStatus);

  return (
    <TableRow>
      <TableCell>
        {statusIcon !== null ? statusIcon : 'No Icon'}
      </TableCell>
      <TableCell>{verifier.DivisionName}</TableCell>
      <TableCell>
        <ItemName onClick={() => navigate(`/work/userdetails/${verifier.ID}`)}>
          {verifier.Name}
        </ItemName>
      </TableCell>
      <TableCell>{new Date(verifier.Date).toLocaleString()}</TableCell>
    </TableRow>
  );
};

VerifierItem.propTypes = {
  verifier: PropTypes.shape({
    VerificationStatus: PropTypes.number.isRequired,
    DivisionName: PropTypes.string.isRequired,
    ID: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Date: PropTypes.string.isRequired,
  }).isRequired,
};

export default VerifierItem;
