import { Box, Typography, Button, styled, IconButton, ButtonGroup } from '@mui/material';
import Item from '../../../../../components/List/Item';
import SearchInput from 'src/components/List/SearchInput';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ItemWrapper = styled(Box)({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
});

const StyledButtonGroup = styled(ButtonGroup)({
  marginTop: '10px',
  bottom: 0,
  position: 'sticky',
  backgroundColor: '#fff',
  boxShadow: '0 -2px 5px rgba(0,0,0,0.1)'
});


const ResourceList = ({ resources, requestItems, onResourceSelect, handleCancelClick, onSearch }) => {
  const [isHide, setIsHide] = useState(false);
  const resourceFields = [
    { label: 'Количество', key: 'Qty', format: (value, item) => `${value} ${item.Measure}` },
    { label: 'Примечание', key: 'Note' }
  ];

  return (
    <>
      <Typography variant="h6">
        Выберите ресурс
        <IconButton onClick={() => setIsHide(!isHide)}>
          {isHide ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      <SearchInput onSearch={onSearch} />
      {!isHide &&
        <ItemWrapper>
          {resources
            .filter(resource => !requestItems.some(item => item.Name === resource.Name))
            .map(resource => (
              <Item
                key={resource.ID}
                fields={resourceFields}
                item={resource}
                onSelect={onResourceSelect}
              />
            ))}
        </ItemWrapper>}
      <StyledButtonGroup fullWidth>
        <Button onClick={handleCancelClick} variant="outlined" color="secondary">
          Отмена
        </Button>
      </StyledButtonGroup>
    </>
  );
};

export default ResourceList;
