import { Button, InputLabel, FormControl, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

const StepOne = ({ objects, formData, handleSelectChange, handleSave, counteragents }) => {
  const [objectInputValue, setObjectInputValue] = useState('');
  const [payerInputValue, setPayerInputValue] = useState('');
  const [recipientInputValue, setRecipientInputValue] = useState('');
  const [supplierInputValue, setSupplierInputValue] = useState('');

  const [isActiveContinue, setIsActiveContinue] = useState(false);

  useEffect(() => {
    const newIsActiveContinue =
      (formData.ObjectID && formData.PayerID && formData.RecipientID && formData.SupplierID);
    setIsActiveContinue(newIsActiveContinue);
  }, [formData]);

  const handleAutocompleteChange = (key) => (event, newValue) => {
    handleSelectChange(key, newValue ? newValue.ID : '');
  };

  const handleInputChange = (setter) => (event, newInputValue) => {
    setter(newInputValue || '');
  };

  const getOptionLabel = (option) => {
    if (typeof option.Name === 'string') {
      return option.Name;
    }
    return '';
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="object-select-label" shrink>Объект</InputLabel>
        <Autocomplete
          options={objects}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Объект"
              variant="outlined"
              placeholder="Введите объект"
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
          value={objects.find(obj => obj.ID === parseInt(formData.ObjectID)) || null}
          onChange={handleAutocompleteChange('ObjectID')}
          inputValue={objectInputValue}
          onInputChange={handleInputChange(setObjectInputValue)}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="payer-select-label" shrink>Плательщик</InputLabel>
        <Autocomplete
          options={counteragents}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Плательщик"
              variant="outlined"
              placeholder="Введите плательщика"
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
          value={counteragents.find(agent => agent.ID === formData.PayerID) || null}
          onChange={handleAutocompleteChange('PayerID')}
          inputValue={payerInputValue}
          onInputChange={handleInputChange(setPayerInputValue)}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="recipient-select-label" shrink>Получатель</InputLabel>
        <Autocomplete
          options={counteragents}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Получатель"
              variant="outlined"
              placeholder="Введите получателя"
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
          value={counteragents.find(agent => agent.ID === formData.RecipientID) || null}
          onChange={handleAutocompleteChange('RecipientID')}
          inputValue={recipientInputValue}
          onInputChange={handleInputChange(setRecipientInputValue)}
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="supplier-select-label" shrink>Поставщик</InputLabel>
        <Autocomplete
          options={counteragents}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Поставщик"
              variant="outlined"
              placeholder="Введите поставщика"
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
          value={counteragents.find(agent => agent.ID === formData.SupplierID) || null}
          onChange={handleAutocompleteChange('SupplierID')}
          inputValue={supplierInputValue}
          onInputChange={handleInputChange(setSupplierInputValue)}
        />
      </FormControl>

      <Button variant="contained" color="primary" onClick={handleSave} disabled={!isActiveContinue}>
        Далее
      </Button>
    </>
  );
};

export default StepOne;
