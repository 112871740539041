import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../services/CheckAuth/useAxiosInstance';

export const useRegister = (email, password1, password2, name ) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const axiosInstance = useAxios();

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
    if (password1 !== password2){
      setError('Пароли не совпадают')
    } else {
      try {
        const response = await axiosInstance.post('api/register', { 
          Email: email,
          Password: password2,
          Name: name
        });
  
        if (response.status === 200) {
          navigate('/login')
          console.log(response.data);
        }
      } catch (err) {
        if (!err.response) {
          setError('Нет доступа к интернету');
        } else if (err.response.status >= 500) {
          setError('Ошибка сервера, попробуйте позже');
        } else if (err.response.status >= 400) {
          setError('Пользователь с таким email уже зарегистрирован');
        } else {
          setError('Произошла неизвестная ошибка');
        }
      }
    }
  };

  return [error, handleRegister];
};
