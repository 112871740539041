import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from '../../../../assets/styles/WorkPage/CircularProgressBar.module.css';

const CircularProgressBar = ({ value, text }) => {
    let color;
    if (value === 100) {
        color = 'green';
    } else if (value >= 50 && value < 100) {
        color = '#FF8C00';
    } else {
        color = 'red';
    }

    return (
        <div className={styles.circularProgressBarContainer}>
            <CircularProgressbar
                value={value}
                text={`${value}%`}
                styles={buildStyles({
                    pathColor: color,
                    textColor: color,
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })}
            />
            <p className={styles.textContainer}>{text}</p>
        </div>
    );
};

export default CircularProgressBar;
