import { Routes, Route, Navigate } from "react-router-dom";

import { LoginPage } from "./pages/Authorization/LoginPage";
import { ForgotPasswordPage } from "./pages/Authorization/ForgotPasswordPage";
import { NewPasswordPage } from "./pages/Authorization/NewPasswordPage";
import { RegisterPage } from "./pages/Authorization/RegisterPage";

import TaskListContainer from "./pages/TaskPage/TaskListPage/TaskListContainer";
import TaskViewerContainer from "./pages/TaskPage/TaskViewerPage/TaskViewerContainer";
import TaskCreateContainer from "./pages/TaskPage/TaskCreatePage/TaskCreateContainer";
import TaskEditContainer from "./pages/TaskPage/TaskEditPage/TaskEditContainer";

import ObjectListContainer from "./pages/WorkPage/Objects/ObjectList/ObjectListContainer";
import ObjectCreateContainer from "./pages/WorkPage/Objects/ObjectCreate/ObjectCreateContainer";
import ObjectViewerContainer from "./pages/WorkPage/Objects/ObjectViewer/ObjectViewerContainer";

import RequestItemListContainer from "./pages/WorkPage/RequestItems/RequestItemList/RequestItemListContainer";
import RequestItemViewerContainer from "./pages/WorkPage/RequestItems/RequestItemViewer/RequestItemViewerContainer";

import RequestListContainer from "./pages/WorkPage/Requests/RequestList/RequestListContainer";
import RequestViewerContainer from "./pages/WorkPage/Requests/RequestViewer/RequestViewerContainer";
import WorkListContainer from "./pages/WorkPage/Requests/RequestCreate/ArcWorkList/WorkListContainer";
import ArcWorkAddItemsContainer from "./pages/WorkPage/Requests/RequestCreate/ArcWorkAddItems/ArcWorkAddItemsContainer";
import RequestNeVedContainer from "./pages/WorkPage/Requests/RequestCreate/RequestNeVed/RequestNeVedContainer";

import InvoiceListContainer from "./pages/WorkPage/Invoices/InvoiceList/InvoiceListContainer";
import InvoiceViewerContainer from "./pages/WorkPage/Invoices/InvoiceViewer/InvoiceViewerContainer";
import CreateInvoiceContainer from "./pages/WorkPage/Invoices/CreateInvoice/CreateInvoiceContainer";
import CounterAgentViewerContainer from "./pages/WorkPage/Invoices/CounteragentViewer/CounteragentViewerContainer";

import UserDetailsContainer from "./pages/SettingsPage/Users/UserDetails/UserDetailsContainer";
import UserListContainer from "./pages/SettingsPage/Users/UserList/UserListContainer";
import UserEditContainer from "./pages/SettingsPage/Users/UserEdit/UserEditContainer";

import DivisionListContainer from "./pages/SettingsPage/Divisions/DivisionList/DivisionListContainer";

import ServiceMonitoringPage from "./pages/ServiceMonitoring/ServiceMonitoring";

import CsvToJsonConverter from "./pages/CsvToJsonConverter/CsvToJsonConverter";
import VisGrid from "./pages/VisGrid/VisGrid";

import Layout from "./components/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import { AppProvider } from "./app/providers/AppProvider";
import { ChatByIdPage, ChatPage } from "./pages/chat";

function App() {
  return (
    <AppProvider>
      <Layout>
        <Routes>
          <Route path="*" element={<Navigate to="/work" />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/chat" element={<ChatPage />}>
            <Route path="/chat/:chatId" element={<ChatByIdPage />} />
          </Route>

          <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
          <Route path="/newPassword" element={<NewPasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />

          <Route path="/tasklist" element={<TaskListContainer />} />
          <Route path="/tasklist/:taskID" element={<TaskViewerContainer />} />
          <Route
            path="/tasklist/createTask"
            element={<TaskCreateContainer />}
          />
          <Route
            path="/tasklist/edit/:taskID"
            element={<TaskEditContainer />}
          />

          <Route path="/work/objectlist" element={<ObjectListContainer />} />
          <Route
            path="/work/objectlist/:objectID"
            element={<ObjectViewerContainer />}
          />
          <Route
            path="/work/objectlist/createObject"
            element={<ObjectCreateContainer />}
          />
          <Route
            path="/work/objectlist/createrequest/:arcworkID/:materialID"
            element={<ArcWorkAddItemsContainer />}
          />

          <Route
            path="/work/requestitemlist"
            element={<RequestItemListContainer />}
          />
          <Route
            path="/work/requestitemlist/:requestItemID"
            element={<RequestItemViewerContainer />}
          />
          <Route
            path="/work/requestitemlist/createinvoice/:ObjectID/:RequestItemsID"
            element={<CreateInvoiceContainer />}
          />

          <Route path="/work/requestlist" element={<RequestListContainer />} />
          <Route
            path="/work/requestlist/:requestID"
            element={<RequestViewerContainer />}
          />
          <Route
            path="/work/requestlist/worklist/:objectName"
            element={<WorkListContainer />}
          />
          <Route
            path="/work/requestlist/worklist"
            element={<WorkListContainer />}
          />
          <Route
            path="/work/requestlist/createrequest/:arcworkID"
            element={<ArcWorkAddItemsContainer />}
          />
          <Route
            path="/work/requestlist/edit/:arcworkID/:requestID"
            element={<ArcWorkAddItemsContainer />}
          />
          <Route
            path="/work/requestlist/objectlist"
            element={
              <ObjectListContainer
                SelectObjectPath={`/work/requestlist/objectlist/`}
              />
            }
          />
          <Route
            path="/work/requestlist/objectlist/:objectID"
            element={<RequestNeVedContainer />}
          />
          <Route
            path="/work/requestlist/objectlist/:objectID/:requestID"
            element={<RequestNeVedContainer />}
          />

          <Route path="/work/invoicelist" element={<InvoiceListContainer />} />
          <Route
            path="/work/invoicelist/:invoiceID"
            element={<InvoiceViewerContainer />}
          />
          <Route
            path="/work/invoicelist/counteragent/:counteragentID"
            element={<CounterAgentViewerContainer />}
          />
          <Route
            path="/work/invoicelist/createinvoice"
            element={<CreateInvoiceContainer />}
          />
          <Route
            path="/work/invoicelist/editinvoice/:invoiceID"
            element={<CreateInvoiceContainer />}
          />

          <Route path="/settings/userlist" element={<UserListContainer />} />
          <Route
            path="/settings/userlist/:userID"
            element={<UserDetailsContainer />}
          />
          <Route
            path="/settings/userlist/edit/:userID"
            element={<UserEditContainer />}
          />
          <Route
            path="/work/userdetails/:userID"
            element={<UserDetailsContainer />}
          />
          <Route path="/profile/:userID" element={<UserDetailsContainer />} />

          <Route
            path="/settings/divisionlist"
            element={<DivisionListContainer />}
          />

          <Route
            path="/service-monitoring"
            element={<ServiceMonitoringPage />}
          />

          <Route path="/csvconverter" element={<CsvToJsonConverter />} />

          <Route path="/visgrid" element={<VisGrid />} />

          <Route path="/work" element={<Navigate to="/work/requestlist" />} />
          <Route
            path="/settings"
            element={<Navigate to="/settings/userlist" />}
          />
        </Routes>
        <ToastContainer />
      </Layout>
    </AppProvider>
  );
}

export default App;
