import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '300px',
});

const CsvTable = ({ headers, editingIndex, newHeader, handleEditHeader, handleSaveHeader, setNewHeader }) => {
  return (
    <StyledTableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Поле</TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {headers.map((header, index) => (
            <TableRow key={index}>
              <TableCell>
                {editingIndex === index ? (
                  <TextField
                    value={newHeader}
                    onChange={(e) => setNewHeader(e.target.value)}
                    fullWidth
                  />
                ) : (
                  header
                )}
              </TableCell>
              <TableCell>
                {editingIndex === index ? (
                  <IconButton onClick={handleSaveHeader}>
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleEditHeader(index)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CsvTable;
