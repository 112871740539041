import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ResourceTable = ({ resources, onDelete, onEdit }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>Действия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.map((resource) => (
            <TableRow key={resource.name}>
              <TableCell>{resource.name}</TableCell>
              <TableCell>{resource.url}</TableCell>
              <TableCell>{resource.status === 'Online' ? <CheckCircleIcon color='primary' /> : <ReportProblemIcon color='secondary' /> }</TableCell>
              <TableCell>
                <IconButton onClick={() => onEdit(resource)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(resource.name)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResourceTable;
