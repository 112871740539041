import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserEdit from './UserEdit';
import { CircularProgress, Box } from '@mui/material';
import { useAuth } from 'src/services/CheckAuth/AuthContext';
import useAxios from 'src/services/CheckAuth/useAxiosInstance';

const UserEditContainer = () => {
  const { userID } = useParams();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/bp/user?ID=${userID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setSelectedUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userID, token]);

  const handleSubmit = async (formData) => {
    try {
        await axiosInstance.put(
          `api/bp/user?ID=${userID}`,
            formData,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        navigate(-1);
      } catch (error) {
        console.error("Error editing user:", error);
      }
  };

  if (!selectedUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return <UserEdit user={selectedUser} onSubmit={handleSubmit}/>;
};

export default UserEditContainer;