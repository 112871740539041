import { Box, Typography, Button, IconButton, styled, TextField, ButtonGroup } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import GoBackIcon from '@mui/icons-material/ArrowBack'
import { roundNumber } from 'src/services/Utils/RoundNumber';

const ExcessMessage = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  color: '#ff0000',
});

const Input = styled(TextField)({
  marginBottom: '10px',
});

const StyledButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
  color: active ? theme.palette.common.white : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : theme.palette.grey[400],
  },
}));

const ResourceForm = ({
  selectedResource,
  residue,
  name,
  setName,
  measure,
  setMeasure,
  isAnalogue,
  setIsAnalogue,
  qty,
  setQty,
  requiredAtDateTime,
  setRequiredAtDateTime,
  note,
  setNote,
  excess,
  setExcess,
  handleBackClick,
  handleSaveClick,
  handleCancelClick,
  isActiveSave,
  isEditing
}) => {
  return (
    <>
      {!isEditing &&
        <IconButton onClick={handleBackClick}>
          <GoBackIcon />
        </IconButton>
      }
      <Typography variant="h6">{selectedResource.Name}</Typography>
      <Box>
        <Typography>Всего по ведомости: <strong>{residue?.Total} {selectedResource.Measure}</strong></Typography>
        <Typography>Остаток по ведомости: <strong>{roundNumber(residue?.Residue, 4)} {selectedResource.Measure} </strong></Typography>
        {selectedResource.Note && <Typography>Примечание: <strong>{selectedResource.Note}</strong></Typography>}
      </Box>
      <ButtonGroup fullWidth sx={{marginBottom: '10px'}}>
        <StyledButton active={!isAnalogue ? 1 : 0} onClick={() => { setIsAnalogue(false); setQty(''); }}>По ведомости</StyledButton>
        <StyledButton active={isAnalogue ? 1 : 0} onClick={() => { setIsAnalogue(true); setQty(''); setExcess(false); }}>Аналог</StyledButton>
      </ButtonGroup>
      {excess && (
        <ExcessMessage>
          <WarningIcon />
          <Typography>Превышение ведомости, заполните пояснение</Typography>
        </ExcessMessage>
      )}
      {isAnalogue && (
        <>
          <Input
            label="Название"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <Input
            label="Единица измерения"
            value={measure}
            onChange={(e) => setMeasure(e.target.value)}
            fullWidth
            required
          />
        </>
      )}
      <Input
        label="Количество"
        value={qty}
        onChange={(e) => {
          if ((e.target.value > residue?.Residue) && !isAnalogue) {
            setExcess(true);
          } else setExcess(false);
          setQty(e.target.value);
        }}
        fullWidth
        required
      />
      <Input
        label="Необходимо к"
        type="datetime-local"
        value={requiredAtDateTime}
        onChange={(e) => setRequiredAtDateTime(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        required
      />
      <Input
        label="Пояснение"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        fullWidth
        error={excess}
      />
      <ButtonGroup fullWidth>
        <Button onClick={handleSaveClick} disabled={!isActiveSave} variant="contained" color="primary">
          Сохранить
        </Button>
        <Button onClick={handleCancelClick} variant="outlined" color="secondary">
          Отмена
        </Button>
      </ButtonGroup>
    </>
  );
};

export default ResourceForm;
