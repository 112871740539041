import { Typography, IconButton, Button } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber } from '../../../../services/Utils/FormatNumber';

const Header = styled('div')({
  flex: 1,
  textAlign: 'left',
});

const ItemName = styled('span')({
  cursor: 'pointer',
  margin: 0,
  padding: 0,
  display: 'inline-block',
  width: 'fit-content',
  '&:hover': {
    color: '#007bff',
  },
});

const Sum = styled('div')({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
});

const InvoiceHeader = ({ invoice, statuses }) => {
  const {invoiceID} = useParams();
  const navigate = useNavigate();
  const statusName = statuses[invoice.Status];

  const handleEditClick = () => {
    navigate(`/work/invoicelist/editinvoice/${invoiceID}`)
  };

  return (
    <Header>
      <Typography variant="h4">
        <IconButton>
          <ChatIcon />
        </IconButton>
        Счёт {invoice.InvoiceNumber} от {new Date(invoice.Date).toLocaleDateString()}
        {statusName === 'Черновик' &&
          <IconButton onClick={handleEditClick}>
            <BorderColorIcon />
          </IconButton>
        }
      </Typography>
      <Typography>
        Создан: <ItemName onClick={() => navigate(`/work/userdetails/${invoice.CreatedByID}`)}>{invoice.CreatedByName}</ItemName> {new Date(invoice.CT).toLocaleString()}
      </Typography>
      <Typography>
        Объект: <ItemName onClick={() => navigate(`/work/objectlist/${invoice.ObjectID}`)}>{invoice.ObjectName}</ItemName>
      </Typography>
      <Typography>
        Поставщик: <ItemName onClick={() => navigate(`/work/invoicelist/counteragent/${invoice.SupplierID}`)}>{invoice.SupplierName}</ItemName>
      </Typography>
      <Typography>
        Плательщик: <ItemName onClick={() => navigate(`/work/invoicelist/counteragent/${invoice.PayerID}`)}>{invoice.PayerName}</ItemName>
      </Typography>
      <Typography>
        Получатель: <ItemName onClick={() => navigate(`/work/invoicelist/counteragent/${invoice.CustomerID}`)}>{invoice.CustomerName}</ItemName>
      </Typography>
      <Typography>Статус: {statusName}</Typography>
      <div>
        <Button variant="contained" color="success" style={{ marginRight: 5 }}>В оплату</Button>
        <Button variant="contained" color="error">Отказ</Button>
      </div>
      <Sum>
        <Typography variant="h5">Сумма с НДС: {formatNumber(invoice.FPWithNDS)} руб.</Typography>
      </Sum>
    </Header>
  );
};

export default InvoiceHeader;
