import { Helmet } from 'react-helmet';
import { Box, Typography, styled } from '@mui/material';
import Item from './Item';
import SearchBar from './SearchBar';

const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const List = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const ItemList = ({
    title,
    items,
    fields,
    navigatePath,
    SelectObjectPath,
    onSearch,
    onSelectAll,
    selectedItems,
    toggleItemSelection,
    selectAll,
    onCreate,
    statuses
}) => {

    return (
        <Container>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            {SelectObjectPath && <Typography variant='h4'>Выберите объект</Typography>}
            <SearchBar
                onSearch={onSearch}
                onSelectAll={onSelectAll}
                selectAll={selectAll}
                onCreate={onCreate}
            />
            <List>
                {items.slice().reverse().map(item => (
                    <Item
                        key={item.ID}
                        item={item}
                        fields={fields}
                        navigatePath={SelectObjectPath ? `${SelectObjectPath}${item.ID}` : `${navigatePath}/${item.ID}`}
                        statuses={statuses ? statuses : undefined}
                        isSelected={selectedItems ? selectedItems.includes(item) : undefined}
                        toggleSelection={toggleItemSelection ? () => toggleItemSelection(item) : undefined}
                    />
                ))}
            </List>
        </Container>
    );
};

export default ItemList;
