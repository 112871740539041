import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import {
  Controller,
  DefaultValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import { Button } from "src/shared/components";
import { Chat, CreateChatRequestPayload } from "src/entities/chat/types";
import { UserAutocomplete } from "src/widgets/user-autocomplete/components";

export interface ChatGroupUpdateDialogProps
  extends Omit<DialogProps, "children" | "onSubmit"> {
  title: string;
  onSubmit: SubmitHandler<any>;
  defaultValues?: DefaultValues<
    Chat & Pick<CreateChatRequestPayload, "Justification">
  >;
  shouldRenderMessageInput?: boolean;
  actionName?: string;
}

export const ChatGroupUpdateDialog = (props: ChatGroupUpdateDialogProps) => {
  const {
    onSubmit,
    title,
    defaultValues,
    shouldRenderMessageInput,
    actionName = "Создать",
    ...restProps
  } = props;
  const { onClose } = restProps;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => reset(defaultValues), [defaultValues?.ID]);

  return (
    <Dialog {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="Name"
                    render={({ field }) => (
                      <TextField label="Название чата" {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserAutocomplete
                    control={control}
                    name="Participants"
                    label="Участники чата"
                    selectProps={{
                      fullWidth: true,
                      placeholder: "asdasdasda",
                    }}
                  />
                </Grid>
                {shouldRenderMessageInput ? (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="Justification"
                      render={({ field }) => (
                        <TextField
                          label="Первое сообщение"
                          multiline
                          rows={6}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ) : undefined}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            onClick={(event) => onClose && onClose(event, "backdropClick")}
            color="error"
          >
            Отмена
          </Button>
          <Button
            variant="outlined"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {actionName}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
