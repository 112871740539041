import ItemListContainer from "../../../../components/List/ItemListContainer";
import SelectTypeRequestModal from "../../Requests/RequestList/SelectTypeRequestModal";

const RequestItemListContainer = () => {
  const RequestItemFields = [
    { label: 'Объект', key: 'ObjectName', path: '/work/objectlist/', pathID: 'ObjectID' },
    { label: 'Количество', key: 'Qty', format: (value, item) => `${value} ${item.Measure}` },
  ];

  return (
    <ItemListContainer
      apiUrl="api/actions/page_data?page=PageWorkMobile"
      listKey="RequestItems"
      title="Заказы"
      enumKey="Заказ"
      fields={RequestItemFields}
      searchKeys={[
        'Name',
        'ObjectName',
        'Qty',
        'Measure',
        'RequiredAtDateTime',
        'ID'
      ]}
      navigatePath="/work/requestitemlist"
      modalComponent={SelectTypeRequestModal}
      multiSelect={true}
    />
  );
};

export default RequestItemListContainer;
