import React, { useState } from 'react';
import styles from '../../../assets/styles/LoginPage.module.css';
import { useForgotPassword } from './ForgotPasswordPageLogic';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [error, handleForgotPassword] = useForgotPassword(email);

  return (
    <form onSubmit={handleForgotPassword} className={styles.form}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
          required
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <button type="submit" className={styles.loginButton}>Отправить</button>
    </form>
  );
};

export default ForgotPasswordForm;
