import ItemListContainer from "../../../../components/List/ItemListContainer";
import SelectTypeRequestModal from "./SelectTypeRequestModal";

const RequestListContainer = () => {
  const requestFields = [
    { label: 'Объект', key: 'ObjectName', path: '/work/objectlist/', pathID: 'ObjectID' },
    { label: 'Создана', key: 'CT', format: (value) => new Date(value).toLocaleDateString() }
  ];

  return (
    <ItemListContainer
      apiUrl="api/actions/page_data?page=ListZayavka"
      listKey="Requests"
      enumKey="Заказ"
      title="Заявки"
      fields={requestFields}
      searchKeys={['WorkName', 'ObjectName', 'CT', 'ID']}
      navigatePath="/work/requestlist"
      modalComponent={SelectTypeRequestModal}
      multiSelect={true}
    />
  );
};

export default RequestListContainer;
