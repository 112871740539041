import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; 
import RequestItemViewerPage from "./RequestItemViewerPage";
import ModalConfirmation from "../../../../components/ModalConfirmation";
import { CircularProgress, Box } from "@mui/material";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";

const RequestItemViewerContainer = () => {
  const [orderData, setOrderData] = useState(null);
  const { requestItemID } = useParams();
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [message, setMessage] = useState("");
  const [verifyConfirmationOpen, setVerifyConfirmationOpen] = useState(false);
  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/actions/page_data?page=ViewingZakaz&ID=${requestItemID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setOrderData(response.data);
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    };

    const fetchStatuses = async () => {
      try {
        const statusesResponse = await axiosInstance.get(
          "api/actions/enums"
        );
        setOrderStatuses(statusesResponse.data["Заказ"].statuses);
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchData();
    fetchStatuses();
  }, [requestItemID, token]);

  const handleVerifyOrder = () => {
    setMessage("Отправить заявку в работу?");
    setVerifyConfirmationOpen(true);
  };

  const handleVerifyConfirmaion = () => {
    handleVerify();
    setVerifyConfirmationOpen(false);
  };

  const handleVerify = async () => {
    try {
      await axiosInstance.post(
        `api/actions/request_to_verify?ID=${requestItemID}`,
        {
          headers: {
            "X-dmtoken": token,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return orderData ? (
    <>
      <RequestItemViewerPage
        orderData={orderData}
        orderStatuses={orderStatuses}
        onVerify={handleVerifyOrder}
        ID={requestItemID}
      />
      <ModalConfirmation
        isOpen={verifyConfirmationOpen}
        onClose={() => setVerifyConfirmationOpen(false)}
        onConfirm={handleVerifyConfirmaion}
        message={message}
      />
    </>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  );
};

export default RequestItemViewerContainer;
