import { Fragment } from 'react/jsx-runtime';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
  Tooltip
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { formatNumber } from 'src/services/Utils/FormatNumber';
import { roundNumber } from 'src/services/Utils/RoundNumber';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const WorkNameCell = styled(TableCell)({
  backgroundColor: '#e0f7fa',
  padding: '8px 16px',
});

const IconButtonCell = styled(TableCell)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  '& .MuiIconButton-root': {
    visibility: 'hidden',
    margin: '0'
  },
  '&:hover .MuiIconButton-root': {
    visibility: 'visible',
  },
});

const CustomTableCell = styled(TableCell)(() => ({
  padding: '8px 16px',
}));

const getResidueColor = (residue, qty) => {
  const percentage = (residue / qty) * 100;
  if (percentage >= 75) return 'blue';
  if (percentage >= 50) return 'green';
  if (percentage >= 25) return 'orange';
  return 'red';
};

const ResidueCell = styled(CustomTableCell)(({ residue, qty }) => ({
  color: getResidueColor(residue, qty),
}));

const ARCTable = ({ CombinedWorkMaterialList }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell>№ п/п</CustomTableCell>
            <CustomTableCell>Наименование</CustomTableCell>
            <CustomTableCell>Остаток</CustomTableCell>
            <CustomTableCell>Ед. изм</CustomTableCell>
            <CustomTableCell>Объем по проекту</CustomTableCell>
            <CustomTableCell>Расход на ед.изм</CustomTableCell>
            <CustomTableCell>Примечание</CustomTableCell>
            <CustomTableCell>Стомость, ед., руб. с НДС</CustomTableCell>
            <CustomTableCell>Стоимость, руб., с НДС</CustomTableCell>
            <CustomTableCell>Стоимость, руб., с НДС</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CombinedWorkMaterialList.map((work, index) => (
            <Fragment key={index}>
              <StyledTableRow>
                <CustomTableCell>{index + 1}</CustomTableCell>
                <WorkNameCell>{work.Name} #{work.ID}</WorkNameCell>
                <CustomTableCell></CustomTableCell>
                <CustomTableCell>{work.Measure}</CustomTableCell>
                <CustomTableCell>{work.Qty}</CustomTableCell>
                <CustomTableCell></CustomTableCell>
                <CustomTableCell></CustomTableCell>
                <CustomTableCell>{formatNumber(work.PPUWithNDS)}</CustomTableCell>
                <CustomTableCell>{formatNumber(work.PriceWithNDS)}</CustomTableCell>
                <CustomTableCell>{formatNumber(work.PriceWithNDS)}</CustomTableCell>
              </StyledTableRow>
              {work.materials.map((material, matIndex) => (
                <StyledTableRow key={matIndex}>
                  <CustomTableCell>{`${index + 1}.${matIndex + 1}`}</CustomTableCell>
                  <IconButtonCell>
                    {material.Name} #{material.ID}
                    <IconButton
                      onClick={() => navigate(`/work/objectlist/createrequest/${material.ARCWorkID}/${material.ID}`)}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </IconButtonCell>
                  <Tooltip title={`${roundNumber(material.Residue/material.Qty, 4)*100}%`}>
                  <ResidueCell residue={material.Residue} qty={material.Qty}>
                    {roundNumber(material.Residue, 4)}
                  </ResidueCell>
                  </Tooltip>
                  <CustomTableCell>{material.Measure}</CustomTableCell>
                  <CustomTableCell>{material.Qty}</CustomTableCell>
                  <CustomTableCell>{material.CPW}</CustomTableCell>
                  <CustomTableCell>{material.Note}</CustomTableCell>
                  <CustomTableCell>{formatNumber(material.PPUWithNDS)}</CustomTableCell>
                  <CustomTableCell>{formatNumber(material.PriceWithNDS)}</CustomTableCell>
                  <CustomTableCell>{formatNumber(material.PriceWithNDS)}</CustomTableCell>
                </StyledTableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ARCTable;
