import { useEffect, useState } from "react";
import InvoiceViewer from "./InvoiceViewer";
import { useParams } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";


const InvoiceViewerContainer = () => {
  const [invoice, setInvoice] = useState(null);
  const [statuses, setStatuses] = useState("");
  const { invoiceID } = useParams();
  const { user } = useAuth();

  const token = user ? user.Token : null;
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const invoiceResponse = await axiosInstance.get(
          `api/actions/page_data?page=ViewingInvoice&ID=${invoiceID}`,
          {
            headers: { "X-dmtoken": token },
          }
        );
        setInvoice(invoiceResponse.data);
      } catch (error) {
        console.error("Error fetching invoice data", error);
      }
    };
    const fetchStatuses = async () => {
      try {
        const statusResponse = await axiosInstance.get(
          "api/actions/enums"
        );
        setStatuses(statusResponse.data["Счёт"].statuses);
      } catch (error) {
        console.error("Error fetching statuses", error);
      }
    };
    fetchStatuses();
    fetchInvoiceData();
  }, [invoiceID, token]);

  if (!invoice || !statuses) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <InvoiceViewer invoice={invoice} statuses={statuses} />;
};

export default InvoiceViewerContainer;
