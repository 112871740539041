import { Paper, Skeleton, Typography, styled } from "@mui/material";

import { CHAT_MESSAGE_BORDER_RADIUS, ChatMessageType } from "../constants";

export type ChatMessageProps = {
  content?: string;
  type: ChatMessageType;
  author?: string;
  at?: string;
  loading?: boolean;
};

export const ChatMessage: React.FC<ChatMessageProps> = ({
  content,
  at,
  type,
  loading,
  author,
}) => {
  return (
    <div>
      <ChatMessageContainer type={type}>
        {loading ? (
          <Skeleton variant="rectangular">&nbsp;</Skeleton>
        ) : (
          <div>
            {type === ChatMessageType.RECIEVED && (
              <Typography color="primary">{author}</Typography>
            )}
            <Typography sx={{ wordWrap: "break-word" }} textAlign="initial">
              {content}
            </Typography>
            <Typography variant="body2" textAlign="end">
              {at}
            </Typography>
          </div>
        )}
      </ChatMessageContainer>
    </div>
  );
};

const ChatMessageContainer = styled("div")<{ type: ChatMessageType }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ type }) =>
    type === ChatMessageType.RECIEVED ? "row" : "row-reverse"};
  & > :first-child {
    border: ${({ type, theme }) =>
      type === ChatMessageType.RECIEVED
        ? `1px solid ${theme.palette.divider}`
        : "none"};
    border-radius: ${CHAT_MESSAGE_BORDER_RADIUS};
    border-bottom-right-radius: ${({ type }) =>
      type === ChatMessageType.SENT ? 0 : CHAT_MESSAGE_BORDER_RADIUS};
    border-bottom-left-radius: ${({ type }) =>
      type === ChatMessageType.RECIEVED ? 0 : CHAT_MESSAGE_BORDER_RADIUS};
    background: ${({ theme, type }) =>
      type === ChatMessageType.SENT
        ? theme.palette.primary.main
        : theme.palette.background.paper};
    padding: ${({ theme }) => theme.spacing(1, 3)};
    color: ${({ theme, type }) =>
      theme.palette.getContrastText(
        type === ChatMessageType.SENT
          ? theme.palette.primary.main
          : theme.palette.background.paper
      )};
    min-width: 300px;
    gap: ${({ theme }) => theme.spacing(1)};
    white-space: pre-line;
    max-width: 800px;
  }
`;
