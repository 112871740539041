import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

const ItemContainer = styled(Box)({
  backgroundColor: '#f8f9fa',
  border: '1px solid #000000',
  borderRadius: '5px',
  padding: '5px',
  marginBottom: '3px',
});

const ItemHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#e9ecef',
  padding: '2px 5px',
  borderRadius: '3px',
  margin: '2px 0',
});

const ItemInfo = styled(Box)({
  flexGrow: 1,
  textAlign: 'left',
  padding: '2px',
});

const ItemDetails = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2px 5px',
  backgroundColor: '#e9ecef',
  borderRadius: '3px',
  margin: '2px 0',
});

const ItemField = styled(Box)({
  width: '33.3%',
  flexGrow: 1,
  padding: '2px',
  textAlign: 'left',
});

const RequestItem = ({ requestItem, onDelete, onEdit, Index }) => {
  const {
    Name,
    Qty,
    Measure,
    Note,
    RequiredAtDateTime,
    IsAnalogueSet,
    AnalogName,
    Excess
  } = requestItem;

  const handleDeleteClick = () => {
    onDelete(requestItem);
  };

  const handleEditClick = () => {
    onEdit(requestItem, Index);
  };

  return (
    <ItemContainer>
      <ItemHeader>
        <ItemInfo>
          <Typography variant="h6">
            <IconButton onClick={handleEditClick}>
              <BorderColorIcon fontSize='medium'/>
            </IconButton>
            {Name}
          </Typography>
        </ItemInfo>
        {IsAnalogueSet && (
          <Tooltip title={`Аналог, вместо: ${AnalogName}`}>
            <WarningIcon color="warning" />
          </Tooltip>
        )}
        {Excess && (
          <Tooltip title="Превышение по ведомости">
            <WarningIcon color="error" />
          </Tooltip>
        )}
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon color="error" />
        </IconButton>
      </ItemHeader>
      <ItemDetails>
        <ItemField>
          <Typography variant="body2">
            <strong>Количество:</strong> {Qty} {Measure}
          </Typography>
        </ItemField>
        <ItemField>
          <Typography variant="body2">
            <strong>Пояснение:</strong> {Note}
          </Typography>
        </ItemField>
        <ItemField>
          <Typography variant="body2">
            <strong>Необходимо на объекте:</strong> {new Date(RequiredAtDateTime).toLocaleString()}
          </Typography>
        </ItemField>
      </ItemDetails>
    </ItemContainer>
  );
};

export default RequestItem;
