import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArcWorkAddItems from "./ArcWorkAddItems";
import ModalConfirmation from "../../../../../components/ModalConfirmation";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../../services/CheckAuth/useAxiosInstance";
import Fuse from "fuse.js";

const ArcWorkAddItemsContainer = () => {
  const { arcworkID, materialID, requestID } = useParams();
  const [work, setWork] = useState(null);
  const [requestItems, setRequestItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [residue, setResidue] = useState(null);
  const [isAnalogue, setIsAnalogue] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [createConfirmationOpen, setCreateConfirmationOpen] = useState(false);
  const [createStatus, setCreateStatus] = useState(null);
  const [createMessage, setCreateMessage] = useState("");
  const navigate = useNavigate();

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchWork = async () => {
      try {
        const response = await axiosInstance.get(
          `api/actions/page_data?page=CreateRequest&ID=${arcworkID}`,
          {
            headers: { "X-dmtoken": token },
          }
        );
        setWork(response.data.List[0]);
      } catch (error) {
        console.error("Error fetching work:", error);
      }
    };

    fetchWork();
  }, [arcworkID, token]);

  const handleDeleteResource = (resourceToDelete) => {
    setDeleteCandidate(resourceToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    setRequestItems((prevItems) =>
      prevItems.filter((item) => item !== deleteCandidate)
    );
    if (deleteCandidate.ID)
      setDeletedItems((prevDeletedItems) => [...prevDeletedItems, deleteCandidate])
    setDeleteConfirmationOpen(false);
  };

  const handleCreateRequest = (status) => {
    setCreateStatus(status);
    if (status === 1) setCreateMessage("Сохранить заявку как черновик?");
    else if (status === 2)
      setCreateMessage("Отправить заявку на согласование?");
    setCreateConfirmationOpen(true);
  };

  const handleCreateConfirmaion = () => {
    handleSubmit(createStatus);
    setCreateConfirmationOpen(false);
  };

  const handleAddResourceClick = async () => {
    setIsModalOpen(true);
    try {
      const response = await axiosInstance.get(
        "api/list/arcworkitem",
        {
          headers: { "X-dmtoken": token },
        }
      );
      const filteredResponse = response.data[0].filter(
        (material) => material.ARCWorkID === work.ID
      );
      setResources(filteredResponse);
      setFilteredResources(filteredResponse);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  const handleResourceSelect = useCallback(
    async (resource) => {
      setSelectedResource(resource);
      try {
        const response = await axiosInstance.get(
          `api/actions/residue_request?ID=${resource.ID}`,
          {
            headers: { "X-dmtoken": token },
          }
        );
        setResidue(response.data);
      } catch (error) {
        console.error("Error fetching residue:", error);
      }
    },
    [token]
  );

  useEffect(() => {
    const fetchParamsResource = async () => {
      setIsModalOpen(true);
      try {
        const response = await axiosInstance.get(
          "api/list/arcworkitem",
          {
            headers: { "X-dmtoken": token }
          }
        );
        const paramsResource = response.data[0].find(
          (material) => material.ID === parseInt(materialID)
        );
        handleResourceSelect(paramsResource);
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    };
    if (materialID)
      fetchParamsResource();
  }, [materialID, token, handleResourceSelect]);

  useEffect(() => {
    const fetchParamsRequest = async () => {
      try {
        const requestResponse = await axiosInstance.get(
          `api/actions/page_data?page=ViewingZayavka&ID=${requestID}`,
          {
            headers: { "X-dmtoken": token }
          }
        );
        const paramsRequest = requestResponse.data;
        const paramsOrders = requestResponse.data.RequestItems;
        const SelectResponse = await axiosInstance.get(
          "api/list/arcworkitem",
          {
            headers: { "X-dmtoken": token },
          }
        );
        paramsOrders.forEach(newOrder => {
          if (newOrder.AnalogueID === 0) {
            const newItem = {
              ID: newOrder.ID,
              Type: 1,
              Status: 1,
              RequestID: parseInt(requestID),
              ProjectID: 11,
              ObjectID: paramsRequest.ObjectID,
              ARCID: paramsRequest.ARCID,
              ARCWorkItemID: newOrder.ARCWorkItemID,
              ARCWorkID: paramsRequest.ARCWorkID,
              Measure: newOrder.Measure,
              Name: newOrder.Name,
              Qty: newOrder.Qty,
              IsAnalogueSet: false,
              AnalogueID: 0,
              Note: newOrder.Note,
              RequiredAtDateTime: newOrder.RequiredAtDateTime,
              Excess: newOrder.Residue.Residue < newOrder.Qty,
              Selected: SelectResponse.data[0].find(item => item.ID === newOrder.ARCWorkItemID)
            };
            setRequestItems((prevRequestItems) => [...prevRequestItems, newItem]);
          } else {
            const newItem = {
              ID: newOrder.ID,
              Type: 1,
              Status: 1,
              RequestID: parseInt(requestID),
              ProjectID: 11,
              ObjectID: paramsRequest.ObjectID,
              ARCID: paramsRequest.ARCID,
              ARCWorkItemID: newOrder.ID,
              ARCWorkID: paramsRequest.ARCWorkID,
              Measure: newOrder.Measure,
              Name: newOrder.Name,
              Qty: newOrder.Qty,
              IsAnalogueSet: true,
              AnalogueID: newOrder.AnalogueID,
              Note: newOrder.Note,
              RequiredAtDateTime: newOrder.RequiredAtDateTime,
              AnalogName: newOrder.AnalogueInitialName,
              Selected: SelectResponse.data[0].find(item => item.ID === newOrder.ARCWorkItemID)
            }
            setRequestItems((prevRequestItems) => [...prevRequestItems, newItem]);
          }
        });
      } catch (error) {
        console.error("Error fetching request:", error);
      }
    }

    if (requestID) {
      fetchParamsRequest();
    }
  }, [])

  const handleSaveResource = (newItem) => {
    setRequestItems((prevItems) => [newItem, ...prevItems]);
  };

  const handleEditResource = (EditedResource, index) => {
    const updatedItems = [...requestItems];
    updatedItems[index] = EditedResource;
    setRequestItems(updatedItems);
  };

  const handleSubmit = async (status) => {
    try {
      if (!requestID) {
        const requestResponse = await axiosInstance.post(
          "api/bp/request",
          {
            Type: 1,
            Status: status,
            ProjectID: 11,
            ObjectID: work.ObjectID,
            ARCID: requestItems[0].ARCID,
            ARCWorkID: work.ID
          },
          {
            headers: { "X-dmtoken": token },
          }
        )

        const requestId = requestResponse.data.ID;

        for (const item of requestItems) {
          item.Status = status;
          item.RequestID = requestId;
          if (item.IsAnalogueSet) {
            const analogueResponse = await axiosInstance.post(
              "api/bp/analogue",
              {
                CreatedDateTime: new Date().toISOString(),
                RequestID: item.RequestID,
                ProjectID: 11,
                ObjectID: work.ObjectID,
                ARCID: item.ARCID,
                ARCWorkItemID: item.Selected.ID,
                ARCWorkID: work.ID,
                Measure: item.Measure,
                Name: item.Name,
                Qty: item.Qty,
              },
              {
                headers: { "X-dmtoken": token },
              }
            );
            item.AnalogueID = parseInt(analogueResponse.data.ID);
            await axiosInstance.post(
              "api/bp/RequestItem",
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else {
            await axiosInstance.post(
              "api/bp/RequestItem",
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          }
        }
      } else {
        await axiosInstance.put(
          `api/bp/request?ID=${requestID}`,
          { Status: status },
          { headers: { "X-dmtoken": token } }
        )
        for (const item of requestItems) {
          item.Status = status;
          item.RequestID = parseInt(requestID);
          if (item.IsAnalogueSet && item.AnalogueID === 0 && item.ID === 0) {
            const analogueResponse = await axiosInstance.post(
              "api/bp/analogue",
              {
                CreatedDateTime: new Date().toISOString(),
                RequestID: item.RequestID,
                ProjectID: 11,
                ObjectID: work.ObjectID,
                ARCID: item.ARCID,
                ARCWorkItemID: item.Selected.ID,
                ARCWorkID: work.ID,
                Measure: item.Measure,
                Name: item.Name,
                Qty: item.Qty,
              },
              {
                headers: { "X-dmtoken": token },
              }
            );
            item.AnalogueID = parseInt(analogueResponse.data.ID);
            await axiosInstance.post(
              "api/bp/RequestItem",
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else if (item.IsAnalogueSet && item.AnalogueID !== 0 && item.ID !== 0) {
            await axiosInstance.put(
              `api/bp/analogue?ID=${item.AnalogueID}`,
              {
                Measure: item.Measure,
                Name: item.Name,
                Qty: item.Qty,
              },
              {
                headers: { "X-dmtoken": token },
              }
            );
            await axiosInstance.put(
              `api/bp/RequestItem?ID=${item.ID}`,
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else if (!item.IsAnalogueSet && item.ID === 0) {
            await axiosInstance.post(
              "api/bp/RequestItem",
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else if (!item.IsAnalogueSet && item.ID !== 0) {
            await axiosInstance.put(
              `api/bp/RequestItem?ID=${item.ID}`,
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          }
        }
      }
      for (const item of deletedItems) {
        if (item.ID)
          await axiosInstance.delete(
            `api/bp/requestitem?ID=${item.ID}`,
            {
              headers: { "X-dmtoken": token },
            }
          );
        if (item.IsAnalogueSet)
          await axiosInstance.delete(
            `api/bp/analogue?ID=${item.AnalogueID}`,
            {
              headers: { "X-dmtoken": token },
            }
          );
      }
      navigate("/works/requestlist");
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };

  const fuse = useMemo(() => {
    return new Fuse(resources, {
      keys: [
        'Name',
        'Measure',
        'Qty',
        'Note',
        'ID'
      ],
      includeScore: true,
      threshold: 0.3
    });
  }, [resources]);

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    if (lowercasedQuery !== '') {
      setFilteredResources(fuse.search(lowercasedQuery).map(result => result.item));
    } else {
      setFilteredResources(resources);
    }
  };

  return (
    <>
      <ArcWorkAddItems
        work={work}
        requestItems={requestItems}
        setRequestItems={setRequestItems}
        onAddResourceClick={handleAddResourceClick}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        resources={filteredResources}
        selectedResource={selectedResource}
        onResourceSelect={handleResourceSelect}
        residue={residue}
        isAnalogue={isAnalogue}
        setIsAnalogue={setIsAnalogue}
        setSelectedResource={setSelectedResource}
        onSaveResource={handleSaveResource}
        onEditResource={handleEditResource}
        onDelete={handleDeleteResource}
        onCreate={handleCreateRequest}
        onSearch={handleSearch}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={handleConfirmDelete}
        message={`Удалить ${deleteCandidate && deleteCandidate.Name}?`}
      />
      <ModalConfirmation
        isOpen={createConfirmationOpen}
        onClose={() => setCreateConfirmationOpen(false)}
        onConfirm={handleCreateConfirmaion}
        message={createMessage}
      />
    </>
  );
};

export default ArcWorkAddItemsContainer;
