import { useEffect } from "react";
import { CreateNewFolderOutlined, Search } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { useCreateChatMutation, useCurrentChat } from "src/entities/chat/hooks";
import { CreateChatRequestPayload } from "src/entities/chat/types";
import {
  ChatGroupUpdateDialog,
  ChatLayout,
} from "src/features/chat/components";
import { ChatGroupList } from "src/widgets/chat-list/components";
import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ChatTypearea } from "src/widgets/chat-typearea/components/ChatTypearea";
import { ChatTitle } from "src/widgets/chat-title/components";
import { useDialogProps } from "src/shared/hooks";

export const ChatPage = () => {
  const { dialogProps, toggle: toggleCreateChatDialog } = useDialogProps();

  const [createChatMutation] = useCreateChatMutation();

  const { currentChat } = useCurrentChat();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { chatId } = useParams<{ chatId: string }>();

  useEffect(() => {
    if (currentChat?.ID && !chatId) {
      navigate(generatePath(`${pathname}/${currentChat.ID}`));
    }
  }, [currentChat?.ID, chatId, navigate, pathname]);

  const onSubmit: SubmitHandler<CreateChatRequestPayload> = async (values) => {
    await createChatMutation(values);
    toggleCreateChatDialog();
  };

  return (
    <ChatLayout>
      <ChatLayout.Sidebar>
        <ChatLayout.Header>
          <Box
            display="flex"
            flexDirection="column"
            justifyItems="center"
            paddingBottom={2}
            paddingX={2}
            gap={1}
            justifyContent="center"
          >
            <Box
              height={60}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
              justifyContent="space-between"
            >
              <Typography fontWeight="bold">Мои чаты</Typography>
              <IconButton onClick={toggleCreateChatDialog}>
                <CreateNewFolderOutlined color="primary" />
              </IconButton>
            </Box>
            <TextField
              size="small"
              InputProps={{
                startAdornment: <Search sx={{ mr: 1 }} />,
              }}
              placeholder="Поиск"
            />
          </Box>
        </ChatLayout.Header>
        <ChatLayout.Content>
          <ChatGroupList />
        </ChatLayout.Content>
      </ChatLayout.Sidebar>
      <ChatLayout.Body>
        <ChatLayout.Header>
          <ChatTitle />
        </ChatLayout.Header>
        <ChatLayout.Content>
          <Outlet />
        </ChatLayout.Content>
        <ChatLayout.Footer>
          <ChatTypearea />
        </ChatLayout.Footer>
      </ChatLayout.Body>
      <ChatGroupUpdateDialog
        title="Создать чат"
        onSubmit={onSubmit}
        shouldRenderMessageInput
        {...dialogProps}
      />
    </ChatLayout>
  );
};
