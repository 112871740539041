import ItemListContainer from "src/components/List/ItemListContainer";

const DivisionListContainer = () => {
    const divisionFields = [
        { label: 'Глава подразделения', key: 'Manager' },
    ];

    return (
        <ItemListContainer
            apiUrl="api/list/division"
            listKey={0}
            title="Подразделения"
            fields={divisionFields}
            searchKeys={[
                'Name'
            ]}
        />
    );
};

export default DivisionListContainer;
