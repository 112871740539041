import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TaskCreatePage from "./TaskCreatePage";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../services/CheckAuth/useAxiosInstance";

const TaskCreateContainer = () => {
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useAuth();
  const axiosInstance = useAxios();
  const navigate = useNavigate();

  const token = user ? user.RedmineToken : null;
  const DMtoken = user ? user.Token : null;

  useEffect(() => {
    axiosInstance
      .get("projects.json", {
        headers: {
          "X-dmredirect": "redmine",
          "X-Redmine-API-Key": token,
        },
      })
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => {
        setError(
          "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
        );
        console.error("Error fetching projects:", error);
      });

    axiosInstance
      .get("api/actions/page_data?page=ListStaff", {
        headers: {
          "X-dmtoken": DMtoken,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        setError(
          "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
        );
        console.error("Error fetching users:", error);
      });
  }, [token, DMtoken, axiosInstance]);

  const handleSubmit = (formData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = userData ? userData.RedmineToken : null;
    console.log(formData);
    const requestData = {
      issue: {
        project_id: formData.project_id,
        tracker_id: formData.tracker_id,
        subject: formData.subject,
        description: formData.description,
        status_id: "1",
        priority_id: formData.priority_id,
        assigned_to_id: formData.assigned_to_id,
        parent_issue_id: formData.parent_issue_id,
        start_date: formData.start_date,
        due_date: formData.due_date,
        estimated_hours: formData.estimated_hours,
        done_ratio: "0",
      },
    };
    console.log(requestData);
    axiosInstance
      .post("issues.json", requestData, {
        headers: {
          "X-dmredirect": "redmine",
          "X-Redmine-API-Key": token,
        },
      })
      .then((response) => {
        setSuccessMessage("Задача успешно создана!");
        navigate("/tasklist");
        setError(null);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 500) {
            setError(
              "Ошибка: Некорректный запрос или данные. Пожалуйста, проверьте введенные данные и повторите попытку."
            );
          } else if (error.response.status >= 500) {
            setError("Ошибка сервера. Пожалуйста, повторите попытку позже.");
          }
        } else if (error.request) {
          setError(
            "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
          );
        } else {
          console.error("Error creating task:", error);
        }
      });
  };

  return (
    <TaskCreatePage
      projects={projects}
      users={users}
      error={error}
      successMessage={successMessage}
      onSubmit={handleSubmit}
    />
  );
};

export default TaskCreateContainer;
