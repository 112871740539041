import { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, ButtonGroup } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const UserEdit = ({ user, onSubmit }) => {
  const [formData, setFormData] = useState({
    Name: user.Name,
    Email: user.Email,
    StatusID: 1,
    Phone: user.Phone,
    Nickname: user.Nickname,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Box padding={2}>
      <Helmet>
        <title>Редактировать пользователя {user.Name}</title>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Редактировать пользователя
      </Typography>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Имя"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Телефон"
            name="Phone"
            value={formData.Phone}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Никнейм"
            name="Nickname"
            value={formData.Nickname}
            onChange={handleChange}
          />
          <ButtonGroup fullWidth>
            <Button type="submit" variant="contained" color="primary">
              Сохранить
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
              Отмена
            </Button>
          </ButtonGroup>
        </form>
      </Paper>
    </Box>
  );
};

export default UserEdit;
