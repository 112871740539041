import { useState, useEffect } from "react";
import TaskEditPage from "./TaskEditPage";
import { useLocation, useNavigate } from "react-router-dom";
import loadingGif from "../../../assets/images/loading.gif";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../services/CheckAuth/useAxiosInstance";

const TaskEditContainer = () => {
  const location = useLocation();
  const match = location.pathname.match(/\/tasklist\/edit\/(\d+)/);
  const taskId = match ? match[1] : null;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [task, setTask] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const axiosInstance = useAxios();

  const token = user ? user.RedmineToken : null;
  const DMtoken = user ? user.Token : null;

  useEffect(() => {
    axiosInstance
      .get("projects.json", {
        headers: {
          "X-dmredirect": "redmine",
          "X-Redmine-API-Key": `${token}`,
        },
      })
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => {
        console.log(
          "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
        );
        console.error("Error fetching projects:", error);
      });

    axiosInstance
      .get("api/actions/page_data?page=ListStaff", {
        headers: {
          "X-dmtoken": `${DMtoken}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(
          "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
        );
        console.error("Error fetching users:", error);
      });
  }, [token, DMtoken, axiosInstance]);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axiosInstance.get(
          `issues/${taskId}.json`,
          {
            headers: {
              "X-dmredirect": "redmine",
              "X-Redmine-API-Key": `${token}`,
            },
          }
        );
        setTask(response.data.issue);
      } catch (error) {
        console.error("Error fetching task:", error);
      } finally {
        setLoading(false);
      }
    };

    if (taskId) {
      fetchTaskData();
    } else {
      setLoading(false);
    }
  }, [taskId, token, axiosInstance]);

  const handleSubmit = (formData) => {
    axiosInstance
      .put(
        `issues/${taskId}.json`,
        {
          issue: formData,
        },
        {
          headers: {
            "X-dmredirect": "redmine",
            "X-Redmine-API-Key": `${token}`,
          },
        }
      )
      .then((response) => {
        navigate(`/tasklist/${taskId}`);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status >= 400 && error.response.status < 500) {
            console.log(
              "Ошибка: Некорректный запрос или данные. Пожалуйста, проверьте введенные данные и повторите попытку."
            );
          } else if (error.response.status >= 500) {
            console.log("Ошибка сервера. Пожалуйста, повторите попытку позже.");
          }
        } else if (error.request) {
          console.log(
            "Ошибка: Нет доступа к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку."
          );
        } else {
          console.error("Error creating task:", error);
        }
      });
  };

  if (loading) {
    return <img src={loadingGif} alt="Loading..." />;
  }

  return (
    <TaskEditPage
      projects={projects}
      users={users}
      task={task}
      onSubmit={handleSubmit}
    />
  );
};

export default TaskEditContainer;
