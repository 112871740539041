import { Box, Grid, TextField, Button, styled } from "@mui/material";

const Input = styled(TextField)({
  marginBottom: '10px',
});

const formContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: 2,
  marginBottom: 2,
};

const buttonGridStyles = {
  marginTop: 2,
  marginBottom: 2,
};

const saveButtonStyles = {
  marginRight: '8px',
};

const NeVedForm = ({
  name,
  setName,
  qty,
  setQty,
  measure,
  setMeasure,
  requiredAtDateTime,
  setRequiredAtDateTime,
  note,
  setNote,
  isEditing,
  isActiveSave,
  handleSaveClick,
  handleCancelClick
}) => {
  return (
    <Box sx={formContainerStyles}>
      <Input
        variant="outlined"
        label="Название"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      <Input
        variant="outlined"
        label="Количество"
        value={qty}
        onChange={(e) => setQty(e.target.value)}
        fullWidth
        required
      />
      <Input
        variant="outlined"
        label="Единица измерения"
        value={measure}
        onChange={(e) => setMeasure(e.target.value)}
        fullWidth
        required
      />
      <Input
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        label="Необходимо к"
        type="datetime-local"
        value={requiredAtDateTime}
        onChange={(e) => setRequiredAtDateTime(e.target.value)}
        fullWidth
        required
      />
      <Input
        variant="outlined"
        label="Пояснение"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        fullWidth
      />
      <Grid container spacing={2} justifyContent="center" sx={buttonGridStyles}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={!isActiveSave}
            fullWidth
            size="normal"
            sx={saveButtonStyles}
          >
            {isEditing ? 'Сохранить' : 'Добавить ресурс'}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelClick}
            fullWidth
            size="normal"
          >
            Очистить
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NeVedForm;
