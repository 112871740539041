import { Box, Typography, Button, IconButton, styled, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, ButtonGroup } from '@mui/material';
import GoBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/Warning';
import { useEffect, useState } from 'react';
import { roundNumber } from 'src/services/Utils/RoundNumber';

const Input = styled(TextField)({
  marginBottom: '10px',
});

const ExcessMessage = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  color: '#ff0000',
});

const OrderForm = ({
  selectedOrder,
  qty,
  setQty,
  PPUWithoutNDS,
  setPPUWithoutNDS,
  NDS,
  setNDS,
  discountChecked,
  setDiscountChecked,
  discountType,
  setDiscountType,
  discountValue,
  setDiscountValue,
  setDiscountSum,
  setUPWithDiscount,
  total,
  setTotal,
  handleBackClick,
  handleSaveClick,
  handleCancelClick,
  isEditing
}) => {
  const [isActiveSave, setIsActiveSave] = useState(false);
  const [excess, setExcess] = useState(false);

  useEffect(() => {
    const newIsActiveSave = 
    (qty !== '' && PPUWithoutNDS !== '' && discountChecked === false) ||
    (discountChecked && qty !== '' && PPUWithoutNDS !== '' && discountType !== '' && discountValue !== '') ||
    (discountChecked && qty !== '' && PPUWithoutNDS !== '' && discountType === 'Безвозмездно');
    setIsActiveSave(newIsActiveSave);
  }, [qty, PPUWithoutNDS, discountChecked, discountType, discountValue]);

  useEffect(() => {
    const calculateTotal = () => {
      let ppu = parseFloat(PPUWithoutNDS) || 0;
      let quantity = parseFloat(qty) || 0;
      let discount = parseFloat(discountValue) || 0;
      let vatMultiplier = NDS === 20 ? 1.2 : 1;
      let discountedPrice = ppu;
  
      switch (discountType) {
        case '% на цену за единицу ресурса':
          discountedPrice = ppu * (1 - discount / 100);
          setUPWithDiscount(discountedPrice);
          break;
        case 'Сумма от цены за единицу ресурса':
          discountedPrice = ppu - discount;
          setUPWithDiscount(discountedPrice);
          break;
        case 'Частично безвозмездно':
          quantity -= discount;
          setUPWithDiscount(ppu);
          break;
        case 'Безвозмездно':
          discountedPrice = 0;
          setUPWithDiscount(discountedPrice);
          break;
        default:
          break;
      }
      
      if (discountType !== 'Сумма скидки от общей стоимости за весь ресурс') {
        const totalValue = (discountedPrice * quantity) * vatMultiplier;
        setTotal(totalValue);
        const totalDiscountSum = ((ppu * qty) * vatMultiplier) - totalValue;
        setDiscountSum(totalDiscountSum);
        setUPWithDiscount(discountedPrice);
      } else {
        const totalValue = ((ppu * quantity) - discount) * vatMultiplier;
        setTotal(totalValue);
        const totalDiscountSum = ((ppu * qty) * vatMultiplier) - totalValue;
        setDiscountSum(totalDiscountSum);
      }
    };

    calculateTotal();
  }, [qty, PPUWithoutNDS, NDS, discountType, discountValue, setDiscountSum, setTotal]);

  

  return (
    <>
      {!isEditing &&
        <IconButton onClick={handleBackClick}>
          <GoBackIcon />
        </IconButton>
      }
      <Typography variant="h6">{selectedOrder.Name}</Typography>
      <Box>
        <Typography>Количество: <strong>{selectedOrder.Qty} {selectedOrder.Measure}</strong></Typography>
        <Typography>Необходимо: <strong>{new Date(selectedOrder.RequiredAtDateTime).toLocaleString()}</strong></Typography>
        { selectedOrder.Note && <Typography>Примечание: <strong>{selectedOrder.Note}</strong></Typography>}
      </Box>
      {excess && (
        <ExcessMessage>
          <WarningIcon />
          <Typography>Превышено количество заказанного товара</Typography>
        </ExcessMessage>
      )}
      <Input
        label="Количество"
        value={qty}
        onChange={(e) => {
          if (e.target.value > selectedOrder.Qty) {
            setExcess(true);
          } else setExcess(false);
          setQty(e.target.value);
        }}
        fullWidth
        required
      />
      <Input
        label="Стоимость за единицу без НДС"
        value={PPUWithoutNDS}
        onChange={(e) => setPPUWithoutNDS(e.target.value)}
        fullWidth
        required
      />
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel id="NDS-label">НДС</InputLabel>
        <Select
          labelId="NDS-label"
          value={NDS}
          label='НДС'
          onChange={(e) => setNDS(parseInt(e.target.value))}
        >
          <MenuItem value={0}>0%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Checkbox checked={discountChecked} onChange={(e) => setDiscountChecked(e.target.checked)} />}
        label="Скидка"
      />
      {discountChecked && (
        <>
          <FormControl fullWidth style={{ marginBottom: '10px' }}>
            <InputLabel id="discount-type-label">Вариант скидки*</InputLabel>
            <Select
              labelId="discount-type-label"
              value={discountType}
              label='Вариант скидки*'
              onChange={(e) => setDiscountType(e.target.value)}
            >
              <MenuItem value="% на цену за единицу ресурса">% на цену за единицу ресурса</MenuItem>
              <MenuItem value="Сумма от цены за единицу ресурса">Сумма от цены за единицу ресурса</MenuItem>
              <MenuItem value="Сумма скидки от общей стоимости за весь ресурс">Сумма скидки от общей стоимости за весь ресурс</MenuItem>
              <MenuItem value="Частично безвозмездно">Частично безвозмездно</MenuItem>
              <MenuItem value="Безвозмездно">Безвозмездно</MenuItem>
            </Select>
          </FormControl>
          { discountType !== 'Безвозмездно' && discountType !== '' &&
           <Input
            label={discountType && discountType.includes('%') ? "Процент скидки" : "Сумма скидки"}
            value={discountValue}
            onChange={(e) => setDiscountValue(e.target.value)}
            fullWidth
            required
          />}
        </>
      )}
      <Typography variant="h6">Итого: <strong>{roundNumber(total, 4)} руб.</strong></Typography>
      <ButtonGroup fullWidth>
        <Button onClick={handleSaveClick} disabled={!isActiveSave} variant="contained" color="primary">
          Сохранить
        </Button>
        <Button onClick={handleCancelClick} variant="outlined" color="secondary">
          Отмена
        </Button>
      </ButtonGroup>
    </>
  );
};

export default OrderForm;
