import { Box, styled } from '@mui/material';
import OrderList from './OrderList';
import OrderForm from './OrderForm';

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
});

const ModalContainer = styled(Box)({
  backgroundColor: '#fff',
  border: '1px solid #000000',
  borderRadius: '8px',
  padding: '20px',
  width: '90%',
  maxWidth: '900px',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  zIndex: 1001,
});

const ModalContent = ({
  selectedOrder,
  handleBackClick,
  handleSaveClick,
  handleCancelClick,
  orders,
  onOrderSelect,
  isEditing,
  onSearch,
  qty,
  setQty,
  PPUWithoutNDS,
  setPPUWithoutNDS,
  NDS,
  setNDS,
  discountChecked,
  setDiscountChecked,
  discountType,
  setDiscountType,
  discountValue,
  setDiscountValue,
  setDiscountSum,
  total,
  setTotal,
  requestItems,
  setUPWithDiscount
}) => {
  return (
    <Overlay>
      <ModalContainer>
        {!selectedOrder ? (
          <OrderList
            orders={orders}
            requestItems={requestItems}
            onOrderSelect={onOrderSelect}
            handleCancelClick={handleCancelClick}
            onSearch={onSearch}
          />
        ) : (
          <OrderForm
            selectedOrder={selectedOrder}
            qty={qty}
            setQty={setQty}
            PPUWithoutNDS={PPUWithoutNDS}
            setPPUWithoutNDS={setPPUWithoutNDS}
            NDS={NDS}
            setNDS={setNDS}
            discountChecked={discountChecked}
            setDiscountChecked={setDiscountChecked}
            discountType={discountType}
            setDiscountType={setDiscountType}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
            setDiscountSum={setDiscountSum}
            total={total}
            setTotal={setTotal}
            handleBackClick={handleBackClick}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            isEditing={isEditing}
            setUPWithDiscount={setUPWithDiscount}
          />
        )}
      </ModalContainer>
    </Overlay>
  );
};

export default ModalContent;
