import { NavLink, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListSubheader,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  AccountCircle,
  Chat,
  ListAlt,
  Folder,
  ShowChart,
  Work,
  Settings,
  ExitToApp,
  AccountTree,
} from "@mui/icons-material";
import { useAuth } from "src/services/CheckAuth/AuthContext";

import logo from "../assets/NavBarIcons/logo_navbar.png";

const NavBar = ({ onButtonClick }) => {
  const navigate = useNavigate();
  const { changeRole, logout, user } = useAuth();

  const handleRoleChange = (e) => {
    changeRole(e.target.value);
  };

  const handleButtonClick = (section) => {
    onButtonClick(section);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <LogoContainer>
          <LogoImg src={logo} alt="Logo" onClick={handleLogoClick} />
        </LogoContainer>
        <NavButtonsContainer>
          <Tooltip title="Профиль">
            <StyledNavLink
              to={`/profile/${user?.UserID}`}
              onClick={() => handleButtonClick("profile")}
            >
              <StyledIconButton>
                <AccountCircle />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="Чат">
            <StyledNavLink to="/chat" onClick={() => handleButtonClick("chat")}>
              <StyledIconButton>
                <Chat />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="Список задач">
            <StyledNavLink
              to="/tasklist"
              onClick={() => handleButtonClick("tasklist")}
            >
              <StyledIconButton>
                <ListAlt />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="CSV Конвертер">
            <StyledNavLink
              to="/csvconverter"
              onClick={() => handleButtonClick("csvConverter")}
            >
              <StyledIconButton>
                <Folder />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="Мониторинг сервисов">
            <StyledNavLink
              to="/service-monitoring"
              onClick={() => handleButtonClick("service-monitoring")}
            >
              <StyledIconButton>
                <ShowChart />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="Работа">
            <StyledNavLink to="/work" onClick={() => handleButtonClick("work")}>
              <StyledIconButton>
                <Work />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="VisGrid">
            <StyledNavLink
              to="/visgrid"
              onClick={() => handleButtonClick("visgrid")}
            >
              <StyledIconButton>
                <AccountTree />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
          <Tooltip title="Настройки пользователей">
            <StyledNavLink
              to="/settings"
              onClick={() => handleButtonClick("settings")}
            >
              <StyledIconButton>
                <Settings />
              </StyledIconButton>
            </StyledNavLink>
          </Tooltip>
        </NavButtonsContainer>
        <FormControl variant="outlined" margin="normal">
          <InputLabel id="role-select-label">Роль</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            name="role"
            value={user?.role || ""}
            onChange={handleRoleChange}
            label="Роль"
          >
            <ListSubheader>{user?.Name}</ListSubheader>
            <Divider />
            {/* Все, что ниже - необходимо выделить в enum и заюзать ниже */}
            <MenuItem value="1">ГД</MenuItem>
            <MenuItem value="2">Бухгалтерия</MenuItem>
            <MenuItem value="3">Финансы</MenuItem>
            <MenuItem value="4">СБ</MenuItem>
            <MenuItem value="5">ГИ</MenuItem>
            <MenuItem value="6">РП</MenuItem>
            <MenuItem value="7">Снабжение</MenuItem>
            <MenuItem value="8">ПТО</MenuItem>
            <MenuItem value="9">СДО</MenuItem>
            <MenuItem value="10">НУ</MenuItem>
            <MenuItem value="11">IT</MenuItem>
            <Divider />
            <StyledNavLink to="/login">
              <StyledIconButton
                onClick={() => {
                  logout();
                }}
              >
                <ExitToApp />
              </StyledIconButton>
            </StyledNavLink>
          </Select>
        </FormControl>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  background-color: #ffffff;
`;

const LogoContainer = styled("div")`
  margin-right: auto;
`;

const LogoImg = styled("img")`
  height: 40px;
  cursor: pointer;
`;

const NavButtonsContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 20px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-decoration: none;
  color: black;
  transition:
    background-color 0.3s,
    color 0.3s;
  border-radius: 50%;
  &.active {
    background-color: #007bff;
    color: white;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;
    width: 50px;
    height: 50px;
  }
`;

export default NavBar;
