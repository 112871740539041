import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, IconButton, Tooltip, Typography, styled } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HideIcon from '@mui/icons-material/ExpandLess';
import ShowIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { useState } from 'react';

const ItemContainer = styled('div')`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &.selected {
    background-color: #e8f0fe;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ItemHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  padding: 2px 3px;
  border-radius: 8px;
  margin-bottom: 3px;
`;

const ItemInfo = styled('div')`
  flex-grow: 1;
  text-align: left;

  h3 {
    margin: 0;
    font-size: 1.2em;
    font-weight: 500;
    color: #333;
  }

  p {
    margin: 0;
    font-size: 0.9em;
    color: #555;
  }

  .itemName {
    cursor: pointer;
    &:hover {
      color: #1a73e8;
    }
  }
`;

const ItemDetails = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 2px 3px;
  background-color: #fafafa;
  border-radius: 8px;
`;

const ItemField = styled('div')`
  flex-grow: 1;
  padding: 4px;
  text-align: left;
  width: ${({ width }) => width};
`;

const ItemFieldID = styled(ItemField)`
  text-align: right;
`;

const StyledSpan = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    color: '#007bff',
  },
});

const ExcessIcon = styled(ErrorOutlineIcon)`
  color: red;
  margin-right: 10px;
`;

const Item = ({
  item,
  fields,
  navigatePath,
  statuses,
  isSelected,
  toggleSelection,
  onSelect
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const statusName = statuses ? statuses[item.Status] : null;
  const hasExcess = item && (item.Excess || (item.RequestItems && item.RequestItems.some(item => item.Excess)));
  const hasAnalogue = item && ((item.RequestItems && item.RequestItems.some(item => item.ARCWorkItemName !== '' && item.ARCWorkItemName !== item.Name)) || (item.ARCWorkItemName && item.Name !== item.ARCWorkItemName));

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(item);
    } else {
      navigate(navigatePath);
    }
  };

  const renderFieldValue = (field, item) => {
    const value = item[field.key];
    if (!value)
      return 'Не указано'
    if (Array.isArray(value)) {
      const valueStr = value.map(subItem => subItem.Name).join(', ');
      return valueStr;
    }

    return field.format ? field.format(value, item) : value;
  };

  return (
    <ItemContainer className={isSelected ? 'selected' : ''} onClick={toggleSelection}>
      <ItemHeader>
        {toggleSelection && <Checkbox checked={isSelected} onChange={toggleSelection} onClick={toggleSelection} name='select' />}
        <ItemInfo>
          <h3>
            {item.ChatID && item.ChatID !== 0 ?
              <Tooltip title='Чат'>
                <IconButton onClick={(e) => { e.stopPropagation(); navigate(`/chat/${item.ChatID}`); }}>
                  <ChatIcon />
                </IconButton>
              </Tooltip>
              : null
            }
            <StyledSpan onClick={handleClick}>
              {item.Name || (item.InvoiceDate && `Счёт ${item.InvoiceNumber} от ${new Date(item.InvoiceDate).toLocaleDateString()}`) || item.ARCWorkName || item.WorkName}
            </StyledSpan>
          </h3>
          {item.CounteragentName && (
            <p>
              <StyledSpan
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/work/invoicelist/counteragent/${item.CounteragentID}`);
                }}
              >
                {item.CounteragentName}
              </StyledSpan>
            </p>
          )}
          {item.RequiredAtDateTime && <p>Требуется к {new Date(item.RequiredAtDateTime).toLocaleDateString()}</p>}
        </ItemInfo>
        {hasExcess &&
          <Tooltip title='Превышение ведомости'>
            <ExcessIcon />
          </Tooltip>
        }
        {item.RequestItems && hasAnalogue && (
          <Tooltip title='Содержит аналоги'>
            <WarningIcon color="warning" />
          </Tooltip>
        )}
        {!item.RequestItems && hasAnalogue && (
          <Tooltip title={`Аналог, вместо: ${item.ARCWorkItemName}`}>
            <WarningIcon color="warning" />
          </Tooltip>
        )}
        {item.RequestItems &&
          <>
            <Typography variant='body3'>
              <strong>Заказы({item.RequestItems ? item.RequestItems.length : '0'})</strong>
            </Typography>
            <IconButton onClick={handleExpandClick}>
              {isExpanded ? <HideIcon /> : <ShowIcon />}
            </IconButton>
          </>}
      </ItemHeader>
      <ItemDetails>
        {fields.map((field, index) => (
          <ItemField
            key={index}
            width={field.label === 'Объект' ? '60%' : '20%'}
          >
            {field.label}: <strong>
              {field.path ? (
                <StyledSpan
                  onClick={field.path ? (e) => {
                    e.stopPropagation();
                    navigate(`${field.path}${item[field.pathID]}`);
                  } : undefined}
                >
                  {renderFieldValue(field, item)}
                </StyledSpan>
              ) : (
                <span>{renderFieldValue(field, item)}</span>
              )}
            </strong>
          </ItemField>
        ))}
        {statusName && (
          <ItemField width={`10%`}>
            Статус: <strong>{statusName}</strong>
          </ItemField>
        )}
        <ItemFieldID width={`3%`}>
          <StyledSpan onClick={handleClick}>
            {`#${item.ID}`}
          </StyledSpan>
        </ItemFieldID>
      </ItemDetails>
      {isExpanded && item.RequestItems && (
        <Box mt={2} display="flex" flexDirection="column" gap="3px">
          {item.RequestItems.map(item => (
            <Item
              key={item.ID}
              item={item}
              fields={[{ label: 'Количество', key: 'Qty', format: (value, item) => `${value} ${item.Measure}` }, { label: 'Пояснение', key: 'Note'}]}
              navigatePath={`/work/requestitemlist/${item.ID}`}
              statuses={statuses}
            />
          ))}
        </Box>
      )}
    </ItemContainer>
  );
};

export default Item;
