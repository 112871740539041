import React from 'react';
import { Link } from 'react-router-dom';
import editIcon from '../../../assets/images/edit-button.png'
import styles from '../../../assets/styles/TaskPage/TaskViewer.module.css';
import { Helmet } from 'react-helmet';

const TaskViewer = ({ task }) => {
  if (!task) {
    return null;
  }

  return (
    <div className={styles.taskContainer}>
      <Helmet>
        <title>{task.subject}</title>
      </Helmet>
      <h1 className={styles.taskHeader}>
        {task.subject}
        <Link to={`/tasklist/edit/${task.id}`} className={styles.editButton}>
          <img src={editIcon} alt='Реадактировать' className={styles.editIcon} />
        </Link>
      </h1>
      <div className={styles.taskGrid}>
        <p className={styles.taskField}><strong>Проект:</strong> {task.project.name}</p>
        <p className={styles.taskField}><strong>Трекер:</strong> {task.tracker.name}</p>
        <p className={styles.taskField}><strong>Статус:</strong> {task.status.name}</p>
        <p className={styles.taskField}><strong>Приоритет:</strong> {task.priority.name}</p>
        <p className={styles.taskField}><strong>Автор:</strong> {task.author.name}</p>
        <p className={styles.taskField}><strong>Дата начала:</strong> {task.start_date}</p>
        <p className={styles.taskField}><strong>Назначено:</strong> {task.assigned_to?.name}</p>
        <p className={styles.taskField}><strong>Дата окончания:</strong> {task.due_date}</p>
        <p className={styles.taskField}><strong>Процент завершения:</strong> {task.done_ratio}%</p>
        <p className={styles.taskField}><strong>Оценка временных затрат:</strong> {task.estimated_hours}%</p>
      </div>
      <p className={styles.taskField}><strong>Описание:</strong></p>
      <pre className={styles.taskDescription}>{task.description}</pre>
    </div>
  );
};

export default TaskViewer;
