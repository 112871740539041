import { Avatar, ListItemButton, Typography, styled } from "@mui/material";

export type ChatGroupProps = {
  name: string;
  displayMessage: string;
  at: string;
  selected?: boolean;
  onSelect?: () => void;
};

export const ChatGroup = (props: ChatGroupProps) => {
  const { name, displayMessage, at, selected, onSelect } = props;

  return (
    <ChatGroupContainer selected={selected} onClick={onSelect}>
      <Avatar sx={{ width: 50, height: 50 }} />
      <ChatGroupTextContainer>
        <ChatGroupTitle>
          <Typography
            textOverflow="ellipsis"
            noWrap
            fontWeight={500}
            variant="body2"
          >
            {name}
          </Typography>
          <Typography variant="body2" fontWeight={200}>
            {at}
          </Typography>
        </ChatGroupTitle>
        <Typography
          color={(theme) => theme.palette.action.active}
          variant="body2"
        >
          {displayMessage}
        </Typography>
      </ChatGroupTextContainer>
    </ChatGroupContainer>
  );
};

const ChatGroupContainer = styled(ListItemButton)`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

const ChatGroupTitle = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

const ChatGroupTextContainer = styled("div")`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
