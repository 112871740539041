import {
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";

import { AuthProvider } from "src/services/CheckAuth/AuthContext";

import { theme } from "../theme";
import { store } from "../store";

export const AppProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <StoreProvider store={store}>
          <CssVarsProvider theme={theme}>
            <CssBaseline />
            {children}
          </CssVarsProvider>
        </StoreProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
