import { Paper, TextField } from '@mui/material';

const JsonOutput = ({ jsonData }) => {
  return (
    <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px', maxHeight: '1200px' }}>
      <TextField
        label="JSON"
        multiline
        rows={20}
        fullWidth
        variant="outlined"
        value={jsonData}
        InputProps={{
          readOnly: true,
        }}
      />
    </Paper>
  );
};

export default JsonOutput;
