import { useEffect, useState, useRef, useCallback } from "react";
import TaskList from "./TaskList";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../services/CheckAuth/useAxiosInstance";

const TaskListContainer = () => {
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const axiosInstance = useAxios();

  const token = user ? user.RedmineToken : null;

  const observer = useRef();

  const lastTaskElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchTasks = async (pageNum) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `issues.json?page=${pageNum}`,
          {
            headers: {
              "X-dmredirect": "redmine",
              "X-Redmine-API-Key": `${token}`,
            },
          }
        );
        return response.data.issues;
      } catch (err) {
        if (!navigator.onLine) {
          setError("Нет доступа к интернету");
        } else if (err.response) {
          if (err.response.status >= 400 && err.response.status < 500) {
            setError("Ошибка клиента");
          } else if (err.response.status >= 500) {
            setError("Ошибка сервера");
          }
        } else {
          setError("Нет доступа к серверу");
        }
        setLoading(false);
        return [];
      }
    };

    const loadTasks = async () => {
      const newTasks = await fetchTasks(page);
      setTasks((prevTasks) => [...prevTasks, ...newTasks]);
      setHasMore(newTasks.length > 0);
      setLoading(false);
    };
    loadTasks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, token]);

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(
        `issues/${id}.json`,
        {
          headers: {
            "X-dmredirect": "redmine",
            "X-Redmine-API-Key": `${token}`,
          },
        }
      );
      setTasks(tasks.filter((task) => task.id !== id));
    } catch (err) {
      setError("Ошибка при удалении задачи");
    }
  };

  return (
    <TaskList
      tasks={tasks}
      loading={loading}
      error={error}
      handleDelete={handleDelete}
      lastTaskElementRef={lastTaskElementRef}
    />
  );
};

export default TaskListContainer;
