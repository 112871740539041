import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import styled from '@mui/material/styles/styled';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Item from '../../../../components/List/Item';

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#f0f0f0'
});

const ItemHeader = styled('div')({
  display: 'flex',
  textAlign: 'left',
  marginBottom: '20px',
});

const ItemInfo = styled('div')({
  flexGrow: 1,
});

const ItemField = styled('div')({
  marginBottom: '10px',
  textAlign: 'left'
});

const StyledSpan = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    color: '#007bff',
  },
});

const RequestViewerPage = ({ request, orders, orderStatuses, requestStatuses, ID }) => {
  const navigate = useNavigate();

  const orderFields = [
    { label: 'Количество', key: 'Qty', format: (value, item) => `${value} ${item.Measure}` },
    { label: 'Пояснение', key: 'Note'}
  ];

  const handleEditClick = () => {
    if (WorkName)
      navigate(`/work/requestlist/edit/${ARCWorkID}/${ID}`)
    else
      navigate(`/work/requestlist/objectlist/${ObjectID}/${ID}`)
  }

  const excess = request.RequestItems && request.RequestItems.some((item) => item.Excess);
  const { ObjectID, WorkName, ProjectName, ObjectName, Status, ARCWorkID } = request;
  const statusName = requestStatuses[Status];

  return (
    <Container>
      <Helmet>
        <title>{WorkName}</title>
      </Helmet>
      <ItemHeader>
        <ItemInfo>
          <Typography variant="h4">
            {WorkName}
            {statusName === 'Черновик' &&
              <Tooltip title='Редактировать'>
                <IconButton onClick={handleEditClick}>
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>
            }
          </Typography>
          <Typography variant="subtitle1">#{ID}</Typography>
        </ItemInfo>
        {excess && (
          <Tooltip title='Превышение ведомости'>
            <ErrorIcon color="error" />
          </Tooltip>
        )}
      </ItemHeader>
      <Box>
        <ItemField>
          <strong>Проект: </strong> {ProjectName}
        </ItemField>
        <ItemField>
          <strong>Объект: </strong>
          <StyledSpan onClick={() => navigate(`/work/objectlist/${ObjectID}`)}>
            {ObjectName}
          </StyledSpan>
        </ItemField>
        <ItemField>
          <strong>Статус: </strong> {statusName}
        </ItemField>
      </Box>
      <Box mt={2}>
        <Typography variant="h4" textAlign="left">Заказы</Typography>
        <Grid container spacing={2}>
          {orders.slice().reverse().map((order) => (
            <Grid item key={order.ID} xs={12}>
              <Item
                item={order}
                fields={orderFields}
                navigatePath={`/work/requestitemlist/${order.ID}`}
                statuses={orderStatuses}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default RequestViewerPage;
