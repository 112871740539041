import React, { useState } from 'react';
import { useLogin } from './LoginPageLogic';
import styles from '../../../assets/styles/LoginPage.module.css';
import invisibleIcon from '../../../assets/images/streamline-invisible-2.svg';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, handleLogin] = useLogin(email, password, rememberMe);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleLogin} className={styles.form}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
          required
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="password" className={styles.label}>Пароль:</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            required
          />
          <button type="button" onClick={toggleShowPassword} className={styles.passwordToggle}>
            <img src={invisibleIcon} alt="Toggle visibility" className={styles.icon} />
          </button>
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Запомнить меня
      </label>
      <button type="submit" className={styles.loginButton}>Войти</button>
      <Link to={'/forgotPassword'} className={styles.forgotPasswordButton}>Забыли пароль?</Link>
    </form>
  );
};

export default LoginForm;
