import { baseApi } from "src/shared/api";
import { User } from "./types";

export const chatApi = baseApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    usersList: query<User[], void>({
      query: () => "api/actions/page_data?page=ListStaff",
    }),
  }),
});
