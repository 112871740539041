import { useEffect, useState } from "react";
import { Box, Typography, IconButton, Button } from '@mui/material'; // Добавлен импорт Button
import { styled } from '@mui/system';
import { Helmet } from "react-helmet";
import { parse, format } from "date-fns";
import RequestItem from "./RequestItem";
import ModalContent from './ModalContent';
import ActionButtons from './ActionButtons';
import HideIcon from '@mui/icons-material/HideSource';
import ShowIcon from '@mui/icons-material/UnfoldMore';

const Container = styled(Box)({
  padding: '20px',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
});

const ItemDetails = styled(Box)({
  marginBottom: '20px',
  '& > div': {
    marginBottom: '10px',
  },
});

const ArcWorkAddItems = ({
  work,
  requestItems,
  onAddResourceClick,
  isModalOpen,
  setIsModalOpen,
  resources,
  selectedResource,
  onResourceSelect,
  residue,
  isAnalogue,
  setIsAnalogue,
  setSelectedResource,
  onSaveResource,
  onEditResource,
  onDelete,
  onCreate,
  onSearch
}) => {
  const [name, setName] = useState("");
  const [qty, setQty] = useState("");
  const [measure, setMeasure] = useState("");
  const [requiredAtDateTime, setRequiredAtDateTime] = useState("");
  const [note, setNote] = useState("");
  const [excess, setExcess] = useState(false);
  const [isActiveSave, setIsActiveSave] = useState(false);
  const [isActiveCreate, setIsActiveCreate] = useState(false);
  const [isItemsCollapsed, setItemsCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [analogueID, setAnalogueID] = useState(0);
  const [editID, setEditID] = useState(0);

  useEffect(() => {
    const newIsActiveCreate = requestItems.length > 0;
    setIsActiveCreate(newIsActiveCreate);

    const newIsActiveSave =
      (isAnalogue && name && qty && measure && requiredAtDateTime && note) ||
      (!excess && !isAnalogue && qty && requiredAtDateTime) ||
      (excess && qty && requiredAtDateTime && note);
    setIsActiveSave(newIsActiveSave);
  }, [isAnalogue, name, qty, measure, requiredAtDateTime, note, requestItems, excess]);

  if (!work) return <div>Loading...</div>;

  const handleEditClick = (resource, index) => {
    setEditIndex(index);
    setIsModalOpen(true);
    onResourceSelect(resource.Selected);
    setIsEditing(true);
    const date = new Date(resource.RequiredAtDateTime);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    setRequiredAtDateTime(formattedDate);
    setQty(resource.Qty);
    setNote(resource.Note);
    setEditID(resource.ID || 0);
    if (resource.IsAnalogueSet) {
      setIsAnalogue(true);
      setAnalogueID(resource.AnalogueID);
      setName(resource.Name);
      setMeasure(resource.Measure);
    } else {
      setIsAnalogue(false);
      setExcess(resource.Excess);
    }
  };

  const handleBackClick = () => {
    setName('');
    setMeasure('');
    setQty("");
    setRequiredAtDateTime("");
    setNote("");
    setExcess(false);
    setSelectedResource(null);
    setIsEditing(false);
    setIsAnalogue(false);
    setAnalogueID(0);
    setEditID(0);
  };

  const handleCancelClick = () => {
    if (selectedResource) {
      handleBackClick();
    }
    setIsModalOpen(false);
  };

  const handleSaveClick = () => {
    const date = requiredAtDateTime;
    setExcess(false);

    const completeDate = `${date}:00.000Z`;

    const parsedDate = parse(
      completeDate,
      "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
      new Date()
    );
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'");

    if (selectedResource && !isAnalogue) {
      const newItem = {
        ID: editID,
        Type: 1,
        Status: 0,
        RequestID: 0,
        ProjectID: 11,
        ObjectID: work.ObjectID,
        ARCID: selectedResource.ARCID,
        ARCWorkItemID: selectedResource.ID,
        ARCWorkID: work.ID,
        Measure: selectedResource.Measure,
        Name: selectedResource.Name,
        Qty: parseFloat(qty),
        IsAnalogueSet: false,
        AnalogueID: 0,
        Note: note,
        RequiredAtDateTime: formattedDate,
        Excess: parseFloat(qty) > residue?.Residue,
        Selected: selectedResource
      };

      if (isEditing) {
        onEditResource(newItem, editIndex);
      } else {
        onSaveResource(newItem);
      }
      handleCancelClick();
    } else if (selectedResource && isAnalogue) {
      const newItem = {
        ID: editID,
        Type: 1,
        Status: 0,
        RequestID: 0,
        ProjectID: 11,
        ObjectID: work.ObjectID,
        ARCID: selectedResource.ARCID,
        ARCWorkItemID: selectedResource.ID,
        ARCWorkID: work.ID,
        Measure: measure,
        Name: name,
        Qty: parseFloat(qty),
        IsAnalogueSet: true,
        AnalogueID: analogueID,
        Note: note,
        RequiredAtDateTime: formattedDate,
        AnalogName: selectedResource.Name,
        Selected: selectedResource
      };
      if (isEditing) {
        onEditResource(newItem, editIndex);
      } else {
        onSaveResource(newItem);
      }
      handleCancelClick();
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Редактирование заявки</title>
      </Helmet>
      <Typography variant="h4">{work.ARCWorkName}</Typography>
      <ItemDetails>
        <Typography><strong>Объект:</strong> {work.ObjectName}</Typography>
        <Typography><strong>Количество:</strong> {work.Qty} {work.Measure}</Typography>
        <Typography><strong>Адрес:</strong> {work.Address}</Typography>
      </ItemDetails>

      <Button onClick={onAddResourceClick} variant="contained" color="primary" fullWidth>Добавить ресурс</Button>

      {isModalOpen && (
        <ModalContent
          selectedResource={selectedResource}
          isAnalogue={isAnalogue}
          setIsAnalogue={setIsAnalogue}
          qty={qty}
          setQty={setQty}
          requiredAtDateTime={requiredAtDateTime}
          setRequiredAtDateTime={setRequiredAtDateTime}
          note={note}
          setNote={setNote}
          excess={excess}
          setExcess={setExcess}
          handleBackClick={handleBackClick}
          handleSaveClick={handleSaveClick}
          handleCancelClick={handleCancelClick}
          isActiveSave={isActiveSave}
          resources={resources}
          onResourceSelect={onResourceSelect}
          residue={residue}
          name={name}
          setName={setName}
          measure={measure}
          setMeasure={setMeasure}
          requestItems={requestItems}
          isEditing={isEditing}
          onSearch={onSearch}
        />
      )}
      <ActionButtons isActiveCreate={isActiveCreate} onCreate={onCreate} />
      <Typography variant="h5">
        Ресурсы
        <IconButton onClick={() => setItemsCollapsed(!isItemsCollapsed)}>
          {isItemsCollapsed ? <ShowIcon /> : <HideIcon />}
        </IconButton>
      </Typography>
      <Box>
        {!isItemsCollapsed && requestItems.map((item, index) => (
          <RequestItem key={index} requestItem={item} onDelete={onDelete} onEdit={handleEditClick} Index={index} />
        ))}
      </Box>
    </Container>
  );
};

export default ArcWorkAddItems;
