import { Link } from 'react-router-dom';
import { Box, Typography, Button, styled, FormControl, InputLabel, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from 'src/services/CheckAuth/AuthContext';
import useAxios from 'src/services/CheckAuth/useAxiosInstance';

const ModalBackdrop = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
});

const ModalContent = styled(Box)({
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  width: '300px',
});

const ModalLink = styled(Button)({
  display: 'block',
  margin: '10px 0',
  padding: '10px',
  backgroundColor: '#e0e0e0',
  color: '#000',
  textDecoration: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#007bff',
    color: '#fff',
  },
});

const SelectTypeRequestModal = ({ onClose }) => {
  const [selectedObject, setSelectedObject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [objectInputValue, setObjectInputValue] = useState('');

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  const handleAutocompleteChange = () => (event, newValue) => {
    setSelectedObject(newValue ? newValue : '');
    console.log(newValue)
  };

  const handleInputChange = (setter) => (event, newInputValue) => {
    setter(newInputValue || '');
  };

  const getOptionLabel = (option) => {
    if (typeof option.Name === 'string') {
      return option.Name;
    }
    return '';
  };

  useEffect(() => {
    const fetchObjects = async () => {
      try {
        const objectsResponce = await axiosInstance.get(
          "api/list/object",
          {
            headers: {
              "X-dmtoken": token,
            }
          }
        );
        setObjects(objectsResponce.data[0]);
      } catch (error) {
        console.error("Error fetching objects:", error);
      }
    }
    fetchObjects();
  }, [token]);

  return (
    <ModalBackdrop>
      <ModalContent>
        <FormControl fullWidth>
          <InputLabel id="object-select-label" shrink>Объект</InputLabel>
          <Autocomplete
            options={objects}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Объект"
                variant="outlined"
                placeholder="Введите объект"
                InputLabelProps={{ shrink: true }}
              />
            )}
            value={null}
            onChange={handleAutocompleteChange('ObjectID')}
            inputValue={objectInputValue}
            onInputChange={handleInputChange(setObjectInputValue)}
          />
        </FormControl>

        <Typography variant="h6" component="h2">Что вы хотите заказать?</Typography>
        <ModalLink component={Link} to={selectedObject ? `/work/requestlist/worklist/${encodeURIComponent(selectedObject.Name)}` : "/work/requestlist/worklist"}>Материалы по ведомости</ModalLink>
        <ModalLink component={Link} to={selectedObject ? `/work/requestlist/objectlist/${selectedObject.ID}` : `/work/requestlist/objectlist`}>Накладные расходы</ModalLink>
        <ModalLink component={Link} to="/link3">Рабочие</ModalLink>
        <ModalLink component={Link} to="/link4">Механизмы</ModalLink>
        <ModalLink component={Link} to="/link5">Услуги</ModalLink>
        <Button onClick={onClose} variant="outlined" color="secondary" fullWidth>Отмена</Button>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default SelectTypeRequestModal;
