import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Box } from '@mui/material';
import axios from 'axios';
import ResourceTable from './ResourceTable';
import ResourceModal from './ResourceModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PROXY_URL = 'https://thingproxy.freeboard.io/fetch/';

const ServiceMonitoring = () => {
  const [resources, setResources] = useState([]);
  const [open, setOpen] = useState(false);
  const [newResource, setNewResource] = useState({ name: '', url: '' });
  const [editingResource, setEditingResource] = useState(null);

  useEffect(() => {
    const storedResources = JSON.parse(localStorage.getItem('resources')) || [];
    setResources(storedResources);

    const interval = setInterval(() => {
      updateStatuses(storedResources);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    localStorage.setItem('resources', JSON.stringify(resources));
  }, [resources]);

  const updateStatuses = (resources) => {
    resources.forEach(resource => {
      axios.get(`${PROXY_URL}${resource.url}`)
        .then(() => updateResourceStatus(resource.name, 'Online'))
        .catch(() => updateResourceStatus(resource.name, 'Offline'));
    });
  };

  const updateResourceStatus = (name, status) => {
    setResources(prevResources => prevResources.map(resource => 
      resource.name === name ? { ...resource, status } : resource
    ));
  };

  const handleOpen = (resource) => {
    setOpen(true);
    if (resource && resource.url) {
      setNewResource({ name: resource.name, url: resource.url });
      setEditingResource(resource.name);
    } else {
      setNewResource({ name: '', url: '' });
      setEditingResource(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingResource(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewResource({ ...newResource, [name]: value });
  };

  const handleSaveResource = () => {
    if (editingResource) {
      setResources(prevResources =>
        prevResources.map(resource =>
          resource.name === editingResource ? { ...newResource, status: resource.status } : resource
        )
      );
    } else {
      setResources([...resources, { ...newResource, status: 'Unknown' }]);
    }
    setNewResource({ name: '', url: '' });
    handleClose();
  };

  const handleDeleteResource = (name) => {
    setResources(resources.filter(resource => resource.name !== name));
  };

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={() => handleOpen(null)} sx={{ marginTop: '5px' }} fullWidth>
        Добавить ресурс
      </Button>
      <ResourceTable resources={resources} onDelete={handleDeleteResource} onEdit={handleOpen} />
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ResourceModal newResource={newResource} onChange={handleInputChange} onSave={handleSaveResource} />
        </Box>
      </Modal>
    </Container>
  );
};

export default ServiceMonitoring;
