import { useState } from "react";

import { ModalProps } from "@mui/material";

export type UseDialogPropsResult = {
  dialogProps: Omit<ModalProps, "children">;
  toggle: () => void;
};

export const useDialogProps = (): UseDialogPropsResult => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    dialogProps: {
      open: isOpen,
      onClose: toggle,
    },
    toggle,
  };
};
