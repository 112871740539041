import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem('user') || localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const login = (userData) => {
    setUser(userData);
    sessionStorage.setItem('user', JSON.stringify(userData));
    if (userData.saved) {
      localStorage.setItem('user', JSON.stringify(userData));
    } else {
      localStorage.removeItem('user');
    }
  };

  const logout = () => {
    navigate('/login');
    setUser(null);
    sessionStorage.removeItem('user');
    localStorage.removeItem('user');
  };

  const changeRole = (role) => {
    if (user) {
      const newUser = { ...user, role };
      setUser(newUser);
      sessionStorage.setItem('user', JSON.stringify(newUser));
      if (newUser.saved) {
        localStorage.setItem('user', JSON.stringify(newUser));
      } else {
        localStorage.removeItem('user');
      }
    }
  };

  useEffect(() => {
    const savedUser = sessionStorage.getItem('user') || localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ logout, login, user, changeRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
