import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '0 auto',
  padding: '16px',
  borderRadius: '8px',
});

const ItemHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.action.selected,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const CreateInvoice = ({
  step,
  objects,
  setSelectedObject,
  counteragents,
  requestItems,
  onAddOrderClick,
  isModalOpen,
  setIsModalOpen,
  orders,
  selectedOrder,
  setSelectedOrder,
  onOrderSelect,
  onSaveOrder,
  onEditOrder,
  onDelete,
  onCreate,
  formData,
  onSearch,
  handleSelectChange,
  handleInputChange,
  handleSave,
  inititalIDs
}) => {
  return (
    <Container>
      <ItemHeader>
        <Typography variant='h4'>Формирование счёта</Typography>
      </ItemHeader>
      {step === 1 && (
        <StepOne
          objects={objects}
          setSelectedObject={setSelectedObject}
          formData={formData}
          handleSelectChange={handleSelectChange}
          handleSave={handleSave}
          counteragents={counteragents}
        />
      )}
      {step === 2 && (
        <StepTwo
          objects={objects}
          formData={formData}
          handleInputChange={handleInputChange}
          onAddOrderClick={onAddOrderClick}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          orders={orders}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          onOrderSelect={onOrderSelect}
          onSaveOrder={onSaveOrder}
          onEditOrder={onEditOrder}
          onDelete={onDelete}
          onCreate={onCreate}
          onSearch={onSearch}
          requestItems={requestItems}
          counteragents={counteragents}
          handleSelectChange={handleSelectChange}
          inititalIDs={inititalIDs}
        />
      )}
    </Container>
  );
};

export default CreateInvoice;
