import { ArrowBackIos, ArrowForwardIos, MoreHoriz } from "@mui/icons-material";
import {
  Icon,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Stack,
  styled,
} from "@mui/material";

import {
  useCurrentChat,
  useDeleteChatMutation,
  useIsSidebarOpen,
} from "src/entities/chat/hooks";
import {
  ChatGroupCard,
  ChatGroupUpdateDialog,
} from "src/features/chat/components";
import { useChatTitlePopover } from "../hooks";
import { useDialogProps } from "src/shared/hooks";
import { ConfirmationDialog } from "src/features/confirmation-dialog/components";

export const ChatTitle = () => {
  const { currentChat } = useCurrentChat();
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  const {
    id,
    anchorEl,
    handleOpen: openPopover,
    handleClose: closePopover,
    open,
  } = useChatTitlePopover();
  const {
    dialogProps: confirmationDialogProps,
    toggle: toggleConfirmationDialog,
  } = useDialogProps();
  const { dialogProps: editChatDialogProps, toggle: toggleEditChatDialog } =
    useDialogProps();

  const [deleteChatMutation, { isLoading: isDeleting }] =
    useDeleteChatMutation();

  const handleCancel = () => {
    toggleConfirmationDialog();
  };

  const handleOpenConfirmationDialog = () => {
    closePopover();
    toggleConfirmationDialog();
  };

  const handleOpenEditChatDialog = () => {
    closePopover();
    toggleEditChatDialog();
  };

  const handleDelete = async () => {
    const id = currentChat?.ID;

    if (id) {
      await deleteChatMutation(currentChat?.ID);
    }
  };

  return (
    <ChatTitleContainer>
      <Stack direction="row" gap={1}>
        <IconButton
          disableRipple
          disableTouchRipple
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          size="small"
        >
          <Icon
            color="primary"
            component={isSidebarOpen ? ArrowBackIos : ArrowForwardIos}
          />
        </IconButton>
        {currentChat ? (
          <ChatGroupCard
            name={currentChat?.Name}
            description={currentChat?.Description}
            size="small"
          />
        ) : undefined}
      </Stack>
      <IconButton aria-describedby={id} onClick={openPopover}>
        <MoreHoriz color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItemButton
            sx={{ color: (theme) => theme.palette.primary.main }}
            color="primary"
            onClick={handleOpenEditChatDialog}
          >
            Изменить чат
          </ListItemButton>
          <ListItemButton
            sx={{ color: (theme) => theme.palette.error.main }}
            onClick={handleOpenConfirmationDialog}
            color="error"
          >
            Удалить чат
          </ListItemButton>
        </List>
      </Popover>
      <ConfirmationDialog
        {...confirmationDialogProps}
        title="Удалить чать"
        content="Вы уверены, что хотите удалить чат?"
        onOk={handleDelete}
        onCancel={handleCancel}
        loading={isDeleting}
      />
      {currentChat ? (
        <ChatGroupUpdateDialog
          title="Изменить чат"
          actionName="Изменить"
          onSubmit={() => {}}
          defaultValues={currentChat}
          {...editChatDialogProps}
        />
      ) : undefined}
    </ChatTitleContainer>
  );
};

const ChatTitleContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;
