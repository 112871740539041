import { useState } from 'react';
import { Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from '@mui/system';
import VerifierItem from '../../RequestItems/RequestItemViewer/VerifierItem';

const VerifiersBlock = styled('div')({
  flex: 1,
  maxWidth: '900px'
});

const VerifiersSection = ({ invoice }) => {
  const [isTableVerifiersCollapsed, setTableVerifiersCollapsed] = useState(false);

  return (
    <VerifiersBlock>
      <Typography variant="h5">
        Согласование
        <IconButton onClick={() => setTableVerifiersCollapsed(!isTableVerifiersCollapsed)}>
          {isTableVerifiersCollapsed ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      {!isTableVerifiersCollapsed && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Статус</TableCell>
                <TableCell>Подразделение</TableCell>
                <TableCell>ФИО</TableCell>
                <TableCell>Дата и время согласования</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { invoice.Verifiers && invoice.Verifiers.map((verifier) => (
                <VerifierItem key={verifier.ID} verifier={verifier} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </VerifiersBlock>
  );
};

export default VerifiersSection;
