import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ObjectViewer from "./ObjectViewer";
import { CircularProgress, Box } from "@mui/material";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";
import Fuse from "fuse.js";

const ObjectViewerContainer = () => {
  const { objectID } = useParams();
  const [object, setObject] = useState(null);
  const [workItems, setWorkItems] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [combinedList, setCombinedList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [arcID, setArcID] = useState();

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchObject = async () => {
      try {
        const response = await axiosInstance.get(
          `api/bp/object?ID=${objectID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setObject(response.data);
        setArcID(response.data.ARCID);
      } catch (error) {
        console.error("Ошибка при получении данных объекта:", error);
      }
    };

    fetchObject();
  }, [objectID, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [workResponse, materialResponse] = await Promise.all([
          axiosInstance.get("api/list/arcwork", {
            headers: { "X-dmtoken": token },
          }),
          axiosInstance.get("api/list/arcworkitem", {
            headers: { "X-dmtoken": token },
          }),

        ]);

        const filteredWorks = workResponse.data[0].filter(
          (work) => work.ARCID === arcID
        );

        setWorkItems(filteredWorks);

        const filteredMaterials = materialResponse.data[0].filter((material) =>
          filteredWorks.some((work) => work.ID === material.ARCWorkID)
        );

        const updatedMaterials = await Promise.all(
          filteredMaterials.map(async (material) => {
            const residueResponse = await axiosInstance.get(
              `api/actions/residue_request?ID=${material.ID}`,
              {
                headers: { 'X-dmtoken': token },
              }
            );

            return {
              ...material,
              Residue: residueResponse.data.Residue,
            };
          })
        );
        setMaterials(updatedMaterials);

        setCombinedList(filteredWorks.map((work) => ({
          ...work,
          materials: updatedMaterials.filter((material) => material.ARCWorkID === work.ID)
        })))

        setFilteredList(filteredWorks.map((work) => ({
          ...work,
          materials: updatedMaterials.filter((material) => material.ARCWorkID === work.ID)
        })));


      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (arcID !== undefined) {
      fetchData();
    }
  }, [arcID, token]);

  const fuse = useMemo(() => {
    return new Fuse(materials, {
      keys: [
        'Name',
        'Note',
        'Qty',
        'Measure',
        'PPUWithNDS',
        'PriceWithNDS',
        'ID'
      ],
      includeScore: true,
      threshold: 0.0
    });
  }, [materials]);

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    if (lowercasedQuery !== '') {
      const filteredMaterials = fuse.search(lowercasedQuery).map(result => result.item);
      setFilteredList(workItems.map(work => ({
        ...work,
        materials: filteredMaterials.filter(material => material.ARCWorkID === work.ID),
      })).filter(work => work.materials.length > 0));
    } else {
      setFilteredList(combinedList);
    }
  };

  if (!object) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ObjectViewer
      object={object}
      onSearch={handleSearch}
      CombinedWorkMaterialList={filteredList}
      ID={objectID}
    />
  );
};

export default ObjectViewerContainer;
