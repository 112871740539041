import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import SubNavBar from './SubNavBar';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('');

  const noNavBarRoutes = ['/login', '/register', '/newPassword', '/forgotPassword'];
  const showNavBar = !noNavBarRoutes.includes(location.pathname);

  useEffect(() => {
    const determineActiveSection = (pathname) => {
      if (pathname.startsWith('/work')) return 'work';
      if (pathname.startsWith('/tasklist')) return 'tasklist';
      if (pathname.startsWith('/profile')) return 'profile';
      if (pathname.startsWith('/folder')) return 'folder';
      if (pathname.startsWith('/chats')) return 'chats';
      if (pathname.startsWith('/graph')) return 'graph';
      if (pathname.startsWith('/note')) return 'note';
      if (pathname.startsWith('/settings')) return 'settings';
      return '';
    };

    setActiveSection(determineActiveSection(location.pathname));
  }, [location.pathname]);

  const handleNavBarClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div>
      {showNavBar && <NavBar onButtonClick={handleNavBarClick} />}
      {showNavBar && <SubNavBar section={activeSection} />}
      <div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
