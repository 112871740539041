import { useEffect, useMemo, useState } from "react";
import CreateInvoice from "./CreateInvoice";
import useAxios from "src/services/CheckAuth/useAxiosInstance";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import Fuse from "fuse.js";
import { parse, format } from "date-fns";
import ModalConfirmation from "src/components/ModalConfirmation";
import { useNavigate, useParams } from "react-router-dom";

const CreateInvoiceContainer = () => {
  const { ObjectID, RequestItemsID, invoiceID } = useParams();
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [requestItems, setRequestItems] = useState([]);
  const [objects, setObjects] = useState([]);
  const [counteragents, setCounteragents] = useState([]);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [createStatus, setCreateStatus] = useState(null);
  const [createMessage, setCreateMessage] = useState("");
  const [createConfirmationOpen, setCreateConfirmationOpen] = useState(false);

  const [formData, setFormData] = useState({
    ObjectID: ObjectID ? ObjectID : 0,
    PayerID: 0,
    RecipientID: 0,
    SupplierID: 0,
    invoiceNumber: "",
    invoiceDate: "",
    TotalWeight: "",
    MeasureWeight: "",
    FPWithNDS: 0,
    FPNDS: 0,
    FPWithDiscount: 0,
  });

  const inititalIDs = RequestItemsID
    ? RequestItemsID.split(",").map(Number)
    : null;

  const navigate = useNavigate();

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchObjects = async () => {
      try {
        const response = await axiosInstance.get(`api/list/object`, {
          headers: {
            "X-dmtoken": token,
          },
        });
        const data = response.data[0];
        setObjects(data);
      } catch (error) {
        console.error("Error fetching objects:", error);
      }
    };

    const fetchCounteragents = async () => {
      try {
        const response = await axiosInstance.get(`api/list/counteragent`, {
          headers: {
            "X-dmtoken": token,
          },
        });
        const data = response.data[0];
        setCounteragents(data);
      } catch (error) {
        console.error("Error fetching objects:", error);
      }
    };

    fetchObjects();
    fetchCounteragents();
  }, [token]);

  useEffect(() => {
    const fetchParamsInvoice = async () => {
      try {
        const invoiceResponse = await axiosInstance.get(
          `api/actions/page_data?page=ViewingInvoice&ID=${invoiceID}`,
          {
            headers: { "X-dmtoken": token },
          }
        );
        const paramsInvoice = invoiceResponse.data;
        setFormData({
          ObjectID: paramsInvoice.ObjectID,
          PayerID: paramsInvoice.PayerID,
          RecipientID: paramsInvoice.CustomerID,
          SupplierID: paramsInvoice.SupplierID,
          invoiceNumber: paramsInvoice.InvoiceNumber,
          invoiceDate: new Date(paramsInvoice.Date).toISOString().slice(0, 16),
          TotalWeight: paramsInvoice.TotalWeight,
          MeasureWeight: paramsInvoice.MeasureWeight,
          FPWithNDS: 0,
          FPNDS: 0,
          FPWithDiscount: 0,
        });
        const paramsInvoiceItems = invoiceResponse.data.Items;
        const ordersResponse = await axiosInstance.get(
          `api/actions/page_data?page=ListZakazZakupka&ObjectID=${paramsInvoice.ObjectID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        paramsInvoiceItems.forEach((newOrder) => {
          const newItem = {
            ID: newOrder.InvoiceItemID,
            Measure: newOrder.Measure,
            Name: newOrder.Name,
            Qty: newOrder.QtyInvoice,
            Note: newOrder.Note,
            RequiredAtDateTime: newOrder.RequiredAtDateTime,
            Selected: ordersResponse.data.find(item => item.ID === newOrder.RequestItemID),
            total: newOrder.TotalPrice,
            PPUWithoutNDS: newOrder.UPWithoutNDS,
            NDS: newOrder.UPWithNDS === newOrder.UPWithoutNDS ? 0 : 0.2,
            discount: newOrder.DiscountType !== "",
            discountType: newOrder.DiscountType,
            discountValue: newOrder.DiscountValue,
            discountSum:
              newOrder.TotalPrice -
              newOrder.QtyInvoice *
                newOrder.UPWithoutNDS *
                (newOrder.UPWithNDS === newOrder.UPWithoutNDS ? 1 : 1.2),
            UPWithDiscount: newOrder.UPWithDiscount,
            RequestID: newOrder.RequestID,
            ARCWorkItemID: newOrder.ARCWorkItemID,
            ARCID: newOrder.ARCID,
          };
          setRequestItems((prevRequestItems) => [...prevRequestItems, newItem]);
        });
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };

    if (invoiceID) {
      fetchParamsInvoice();
    }
  }, []);

  const handleDeleteOrder = (resourceToDelete) => {
    setDeleteCandidate(resourceToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setRequestItems((prevItems) =>
      prevItems.filter((item) => item !== deleteCandidate)
    );
    if (deleteCandidate.ID)
      setDeletedItems((prevDeletedItems) => [
        ...prevDeletedItems,
        deleteCandidate,
      ]);
    setDeleteConfirmationOpen(false);
  };

  const handleCreateInvoice = (status) => {
    setCreateStatus(status);
    if (status === 1) setCreateMessage("Сохранить счёт как черновик?");
    else if (status === 2) setCreateMessage("Отправить счёт на согласование?");
    setCreateConfirmationOpen(true);
  };

  const handleCreateConfirmaion = () => {
    handleSubmit(createStatus);
    setCreateConfirmationOpen(false);
  };

  const fetchOrders = async () => {
    try {
      const ordersResponse = await axiosInstance.get(
        `api/actions/page_data?page=ListZakazZakupka&ObjectID=${formData.ObjectID}`,
        {
          headers: {
            "X-dmtoken": token,
          },
        }
      );
      setOrders(ordersResponse.data || []);
      setFilteredOrders(ordersResponse.data || []);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    if (RequestItemsID || formData.ObjectID) {
      fetchOrders();
    }
  }, [RequestItemsID, formData.ObjectID]);

  const handleAddOrderClick = () => {
    setIsModalOpen(true);
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
  };

  const handleSaveOrder = (newItem) => {
    setRequestItems((prevItems) => [newItem, ...prevItems]);
  };

  const handleEditOrder = (EditedOrder, index) => {
    const updatedItems = [...requestItems];
    updatedItems[index] = EditedOrder;
    setRequestItems(updatedItems);
  };

  const handleSubmit = async (status) => {
    try {
      const date = formData.invoiceDate;
      const completeDate = `${date}:00.000Z`;
      const parsedDate = parse(
        completeDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
        new Date()
      );
      const formattedDate = format(
        parsedDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"
      );
      if (!invoiceID) {
        const invoiceResponse = await axiosInstance.post(
          "api/bp/invoice",
          {
            LastModifiedByID: -1,
            NumberStr: formData.invoiceNumber,
            Date: formattedDate,
            SupplierID: formData.SupplierID,
            CustomerID: formData.RecipientID,
            PayerID: formData.PayerID,
            Status: status,
            ProjectID: 11,
            ObjectID: formData.ObjectID,
            FPWithoutNDS: formData.FPWithNDS - formData.FPNDS,
            FPWithNDS: formData.FPWithNDS,
            FPNDS: formData.FPNDS,
            FPWithDiscount: formData.FPWithDiscount,
            TotalWeight: parseFloat(formData.TotalWeight),
            MeasureWeight: formData.MeasureWeight,
          },
          {
            headers: { "X-dmtoken": token },
          }
        );

        const invoiceId = invoiceResponse.data.ID;

        for (const item of requestItems) {
          const UPWithNDS = item.PPUWithoutNDS * (1 + item.NDS);
          const TCWithoutNDS = item.total * (1 - item.NDS) + item.discountSum;
          const TCWithoutDiscount =
            item.total * (1 - item.NDS) + item.discountSum;
          const TCWithDiscount = item.total * (1 - item.NDS);
          await axiosInstance.post(
            "api/bp/invoiceitem",
            {
              InvoiceID: invoiceId,
              ProjectID: 11,
              ObjectID: formData.ObjectID,
              ARCID: item.ARCID,
              ARCWorkItemID: item.ARCWorkItemID,
              RequestID: item.RequestID,
              RequestItemID: item.RequestItemID,
              Name: item.Name,
              Measure: item.Measure,
              RequiredAtDateTime: item.RequiredAtDateTime,
              Qty: item.Qty,
              UPWithoutNDS: item.PPUWithoutNDS,
              UPWithNDS: UPWithNDS,
              UPWithoutDiscount: item.PPUWithoutNDS,
              UPWithDiscount: item.UPWithDiscount,
              TCWithoutNDS: TCWithoutNDS,
              TCWithNDS: parseFloat(item.total),
              TCWithoutDiscount: TCWithoutDiscount,
              TCWithDiscount: TCWithDiscount,
              DiscountType: item.discountType,
              DiscountValue: parseFloat(item.discountValue),
            },
            {
              headers: { "X-dmtoken": token },
            }
          );
        }
      } else {
        await axiosInstance.put(
          `api/bp/invoice?ID=${invoiceID}`,
          {
            LastModifiedByID: -1,
            NumberStr: formData.invoiceNumber,
            Date: formattedDate,
            SupplierID: formData.SupplierID,
            CustomerID: formData.RecipientID,
            PayerID: formData.PayerID,
            Status: status,
            ProjectID: 11,
            ObjectID: formData.ObjectID,
            FPWithoutNDS: formData.FPWithNDS - formData.FPNDS,
            FPWithNDS: formData.FPWithNDS,
            FPNDS: formData.FPNDS,
            FPWithDiscount: formData.FPWithDiscount,
            TotalWeight: parseFloat(formData.TotalWeight),
            MeasureWeight: formData.MeasureWeight,
          },
          {
            headers: { "X-dmtoken": token },
          }
        );

        for (const item of requestItems) {
          const UPWithNDS = item.PPUWithoutNDS * (1 + item.NDS);
          const TCWithoutNDS = item.total * (1 - item.NDS) + item.discountSum;
          const TCWithoutDiscount =
            item.total * (1 - item.NDS) + item.discountSum;
          const TCWithDiscount = item.total * (1 - item.NDS);
          if (item.ID) {
            await axiosInstance.put(
              `api/bp/invoiceitem?ID=${item.ID}`,
              {
                InvoiceID: parseInt(invoiceID),
                ProjectID: 11,
                ObjectID: formData.ObjectID,
                ARCID: item.ARCID,
                ARCWorkItemID: item.ARCWorkItemID,
                RequestID: item.RequestID,
                RequestItemID: item.RequestItemID,
                Name: item.Name,
                Measure: item.Measure,
                RequiredAtDateTime: item.RequiredAtDateTime,
                Qty: item.Qty,
                UPWithoutNDS: item.PPUWithoutNDS,
                UPWithNDS: UPWithNDS,
                UPWithoutDiscount: item.PPUWithoutNDS,
                UPWithDiscount: item.UPWithDiscount,
                TCWithoutNDS: TCWithoutNDS,
                TCWithNDS: parseFloat(item.total),
                TCWithoutDiscount: TCWithoutDiscount,
                TCWithDiscount: TCWithDiscount,
                DiscountType: item.discountType,
                DiscountValue: parseFloat(item.discountValue),
              },
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else {
            await axiosInstance.post(
              "api/bp/invoiceitem",
              {
                InvoiceID: parseInt(invoiceID),
                ProjectID: 11,
                ObjectID: formData.ObjectID,
                ARCID: item.ARCID,
                ARCWorkItemID: item.ARCWorkItemID,
                RequestID: item.RequestID,
                RequestItemID: item.RequestItemID,
                Name: item.Name,
                Measure: item.Measure,
                RequiredAtDateTime: item.RequiredAtDateTime,
                Qty: item.Qty,
                UPWithoutNDS: item.PPUWithoutNDS,
                UPWithNDS: UPWithNDS,
                UPWithoutDiscount: item.PPUWithoutNDS,
                UPWithDiscount: item.UPWithDiscount,
                TCWithoutNDS: TCWithoutNDS,
                TCWithNDS: parseFloat(item.total),
                TCWithoutDiscount: TCWithoutDiscount,
                TCWithDiscount: TCWithDiscount,
                DiscountType: item.discountType,
                DiscountValue: parseFloat(item.discountValue),
              },
              {
                headers: { "X-dmtoken": token },
              }
            );
          }
        }
      }
      for (const item of deletedItems)
        await axiosInstance.delete(`api/bp/invoiceitem?ID=${item.ID}`, {
          headers: { "X-dmtoken": token },
        });
      navigate("/work/invoicelist");
    } catch (error) {
      console.error("Error submitting invoice:", error);
    }
  };

  const fuse = useMemo(() => {
    return new Fuse(orders, {
      keys: ["Name", "Measure", "Qty", "RequiredAtDateTime"],
      includeScore: true,
      threshold: 0.3,
    });
  }, [orders]);

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    if (lowercasedQuery !== "") {
      setFilteredOrders(
        fuse.search(lowercasedQuery).map((result) => result.item)
      );
    } else {
      setFilteredOrders(orders);
    }
  };

  const handleSelectChange = (field, dataValue) => {
    setFormData({
      ...formData,
      [field]: dataValue,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    setStep(2);
  };

  return (
    <>
      <CreateInvoice
        step={step}
        objects={objects}
        counteragents={counteragents}
        requestItems={requestItems}
        setRequestItems={setRequestItems}
        onAddOrderClick={handleAddOrderClick}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        orders={filteredOrders}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        onOrderSelect={handleOrderSelect}
        onSaveOrder={handleSaveOrder}
        onEditOrder={handleEditOrder}
        onDelete={handleDeleteOrder}
        onCreate={handleCreateInvoice}
        formData={formData}
        onSearch={handleSearch}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleSave={handleSave}
        inititalIDs={inititalIDs}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={handleConfirmDelete}
        message={`Удалить ${deleteCandidate && deleteCandidate.Name}?`}
      />
      <ModalConfirmation
        isOpen={createConfirmationOpen}
        onClose={() => setCreateConfirmationOpen(false)}
        onConfirm={handleCreateConfirmaion}
        message={createMessage}
      />
    </>
  );
};

export default CreateInvoiceContainer;
