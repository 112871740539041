import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Autocomplete, SelectProps, TextField } from "@mui/material";

import { useUsersListQuery } from "src/entities/users/hooks";

export type UserAutocompleteProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, any>;
  label?: string;
  selectProps?: SelectProps;
};

export const UserAutocomplete = <T extends FieldValues>(
  props: UserAutocompleteProps<T>
) => {
  const { name, control, label } = props;

  const { data, isLoading } = useUsersListQuery();

  const options = data?.map((user) => user.ID) || [];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          onChange={(_e, v) => onChange(v)}
          value={value}
          loading={isLoading}
          multiple
          options={options}
          getOptionLabel={(option) =>
            data?.find((user) => user.ID === option)?.Name || "Без имени"
          }
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      )}
    />
  );
};
