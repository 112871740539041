import { Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/services/CheckAuth/AuthContext';
import useAxios from 'src/services/CheckAuth/useAxiosInstance';

const MenuContainer = styled('div')`
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToastifyMenu = ({ onClose, selectedItems }) => {
    const navigate = useNavigate();
    console.log()

    const { user } = useAuth();
    const axiosInstance = useAxios();
    const token = user ? user.Token : null;

    const handleAction = (action) => {
        console.log(`Executing ${action}`);
        onClose();
    };

    const handleCreateInvoice = () => {
        const objectId = selectedItems[0].ObjectID;
        const requestItemsID = selectedItems.map(order => order.ID);
        navigate(`/work/requestitemlist/createinvoice/${objectId}/${requestItemsID.join(',')}`);
        onClose();
    };

    const handleRequestToVerify = async () => {
        try {
            await Promise.all(selectedItems.map(request => {
                return axiosInstance.post(`api/actions/request_to_verify?ID=${request.ID}`, {}, {
                    headers: {
                        'X-dmtoken': token,
                    },
                });
            }));
            onClose();
            navigate(0);
        } catch (error) {
            console.error('Error verifying requests:', error);
        }
    };

    const VisibleCreateInvoice = selectedItems.length > 0 && selectedItems ? selectedItems.every(order => order.ObjectID === selectedItems[0].ObjectID) && selectedItems.every(order => order.Status === 4) : false;
    const VisibleRequestToVerify = selectedItems.length > 0 && selectedItems ? selectedItems.every(request => (request.RequestItems && request.RequestItems.every(order => order.Status === 1))) && selectedItems.every(request => request.Status === 1) : false;

    return (
        <MenuContainer className="toastifyMenu">
                {VisibleCreateInvoice &&
                    <Button variant="contained" color="primary" onClick={handleCreateInvoice}>
                        Сформировать счёт
                    </Button>
                }
                {VisibleRequestToVerify &&
                    <Button variant="contained" color="primary" onClick={handleRequestToVerify}>
                        В работу
                    </Button>
                }
                <Button variant="contained" color="secondary" onClick={() => handleAction('action2')}>
                    Действие 2
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Закрыть
                </Button>
        </MenuContainer>
    );
};

export default ToastifyMenu;
