import React from "react";
import styles from "../../../assets/styles/TaskPage/TaskCreateForm.module.css";
import { Helmet } from "react-helmet";
import TaskForm from "../TaskForm";

const TaskEditPage = ({
  projects,
  users,
  error,
  onSubmit,
  task,
}) => {
  return (
    <div>
      <Helmet>
        <title>Изменить {task.subject}</title>
      </Helmet>
      <h1>Изменить задачу</h1>
      {error && <p className={styles.error}>{error}</p>}
      {task ? (
        <TaskForm
          projects={projects}
          users={users}
          onSubmit={onSubmit}
          task={task}
        />
      ) : (
        <p>Задача не найдена или не загружена.</p>
      )}
    </div>
  );
};

export default TaskEditPage;
