import { Button, ButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActionButtons = ({ isActiveCreate, onCreate }) => {
  const navigate = useNavigate();

  return (
    <ButtonGroup fullWidth sx={{ marginTop: '10px' }}>
          <Button
            onClick={() => onCreate(2)}
            disabled={!isActiveCreate}
            variant="contained"
            color="primary"
            fullWidth
          >
            Отправить на согласование
          </Button>
          <Button
            onClick={() => onCreate(1)}
            disabled={!isActiveCreate}
            variant="outlined"
            color="primary"
            fullWidth
          >
            Сохранить черновик
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Отменить
          </Button>
    </ButtonGroup>
  );
};

export default ActionButtons;
