import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";

export const theme = extendTheme(
  {
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: "#017BFE",
          },
        },
      },
    },
    components: {
      MuiIcon: {
        defaultProps: {
          fontSize: "inherit",
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          sx: {
            textTransform: "none",
          },
        },
        styleOverrides: {
          sizeLarge: {
            height: "56px",
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiInputBase: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiDialog: {
        defaultProps: {
          fullWidth: true,
          maxWidth: "md",
        },
      },
    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      fontFamily: "Montserrat",
    },
  },
  ruRU
);
