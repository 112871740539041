import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ModalConfirmation = ({ isOpen, onClose, onConfirm, message }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Подтверждение</DialogTitle>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Да
        </Button>
        <Button onClick={onClose} color="secondary">
          Нет
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmation;
