import { PayloadAction, createSlice } from "@reduxjs/toolkit/react";

import { Nullable } from "src/shared/types";
import { Chat } from "src/entities/chat/types";

type ChatState = {
  currentChat: Nullable<Chat>;
  isSidebarOpen: boolean;
};

const initialState: ChatState = {
  currentChat: null,
  isSidebarOpen: localStorage.getItem("isSidebarOpen") === "true",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setCurrentChat(state, { payload }: PayloadAction<Chat>) {
      state.currentChat = payload;
    },
    setIsSidebarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSidebarOpen = payload;
      localStorage.setItem("isSidebarOpen", String(payload));
    },
  },
});
