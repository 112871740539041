import styles from '../../../assets/styles/LoginPage.module.css';
import LoginForm from './LoginForm';
import WelcomeSection from '../WelcomeSection';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Авторизация</title>
      </Helmet>
      <div className={styles.rightSide}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h2 className={styles.authTitle}>Авторизация</h2>
        <LoginForm />
      </div>
      <div className={styles.leftSide}>
        <WelcomeSection title='Добро пожаловать!' text='Вы на странице приветствия ООО "Корпорация ДМ". Пройдите регистрацию,
        если еще нет аккаунта.' buttonName='Регистрация' buttonPath='/register' />
      </div>
    </div>
  );
};

export default LoginPage;
