import {
  CircularProgress,
  CircularProgressProps,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
  circularProgressProps?: CircularProgressProps;
};

export const Button = ({
  loading,
  circularProgressProps,
  ...restProps
}: ButtonProps) => (
  <MuiButton
    {...restProps}
    startIcon={
      loading ? (
        <CircularProgress
          color="inherit"
          size="15px"
          sx={(theme) => ({
            ml: theme.spacing(1),
          })}
          {...circularProgressProps}
        />
      ) : undefined
    }
  />
);
