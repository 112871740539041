import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import { Button } from "src/shared/components/Button";

export interface ConfirmationDialogProps
  extends Omit<DialogProps, "title" | "content" | "size"> {
  title: string;
  content: string;
  onOk: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
  loading?: boolean;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { title, content, onOk, onCancel, loading, ...restProps } = props;

  return (
    <Dialog maxWidth={"sm"} {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="error">
          Отменить
        </Button>
        <Button
          variant="outlined"
          loading={loading}
          onClick={onOk}
          color="primary"
        >
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
