import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../../assets/styles/TaskPage/TaskCreateForm.module.css';
import TaskForm from '../TaskForm';

const TaskCreatePage = ({ projects, users, error, successMessage, onSubmit }) => {
  return (
    <div>
      <Helmet>
        <title>Создать задачу</title>
      </Helmet>
      <h1>Создать задачу</h1>
      {error && <p className={styles.error}>{error}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      <TaskForm projects={projects} users={users} onSubmit={onSubmit} type='1' />
    </div>
  );
}

export default TaskCreatePage;
