import { TextField } from '@mui/material';
import { useState } from 'react';

const SearchInput = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
        onSearch(e.target.value);
    };

    return (
        <TextField
            variant="outlined"
            value={query}
            onChange={handleQueryChange}
            placeholder="Поиск..."
            fullWidth
        />
    );
};

export default SearchInput;
