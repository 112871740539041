import ItemListContainer from "src/components/List/ItemListContainer";

const WorkListContainer = () => {
  const workFields = [
    { label: 'Объект', key: 'ObjectName', path: '/work/objectlist/', pathID: 'ObjectID' },
    { label: 'Адрес', key: 'Address' },
    { label: 'Количество', key: 'Qty', format: (value, item) => `${value} ${item.Measure}` },
  ];

  return (
    <ItemListContainer
      apiUrl="api/actions/page_data?page=CreateRequest"
      listKey="List"
      title="Выберите вид работы"
      fields={workFields}
      searchKeys={[
        'ARCWorkName',
        'ObjectName',
        'Qty',
        'Address',
        'Measure',
        'ID'
      ]}
      navigatePath="/work/requestlist/createrequest"
    />
  );
};

export default WorkListContainer;
