import React, { useEffect, useRef } from 'react';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';

const VisNetwork = () => {
  const networkRef = useRef(null);

  useEffect(() => {
    // Создание данных для сетки
    const nodes = new DataSet([
      { id: 1, label: 'Node 1' },
      { id: 2, label: 'Node 2' },
      { id: 3, label: 'Node 3' },
      { id: 4, label: 'Node 4' },
    ]);

    const edges = new DataSet([
      { from: 1, to: 2 },
      { from: 1, to: 3 },
      { from: 2, to: 4 },
      { from: 3, to: 4 },
    ]);

    const data = { nodes, edges };

    // Настройка опций сетки
    const options = {
      layout: {
        hierarchical: false,
      },
      height: '400px',
      width: '100%',
      manipulation: {
        enabled: true,
        addNode: true,
        addEdge: true,
        editNode: true,
        deleteNode: true,
        deleteEdge: true,
      },
    };

    // Инициализация сетки
    const network = new Network(networkRef.current, data, options);

    return () => {
      network.destroy();
    };
  }, []);

  return (
    <div
      id="vis-network"
      style={{ height: '400px', width: '100%' }}
      ref={networkRef}
    />
  );
};

export default VisNetwork;
