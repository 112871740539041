import { useAuth } from "src/services/CheckAuth/AuthContext"; 

const ProtectedComponent = ({ UserRoles, flag, children }) => {
    const { user } = useAuth();

    if (!user || (!UserRoles.includes(user.role) && flag) || (UserRoles.includes(user.role) && !flag)) {
        return null;
    }

    return <>{children}</>;
};

export default ProtectedComponent;