import ItemListContainer from "../../../../components/List/ItemListContainer";

const ObjectListContainer = ({ SelectObjectPath }) => {
  const objectFields = [
    { label: 'Адрес', key: 'Address' },
    { label: 'Дата завершения строительства', key: 'CompletionDate', format: (value) => new Date(value).toLocaleDateString() },
  ];

  return (
    <ItemListContainer
      apiUrl="api/list/object"
      listKey={0}
      title="Объекты"
      fields={objectFields}
      searchKeys={[
        'Name',
        'Address',
        'CompletionDate',
        'ID'
      ]}
      navigatePath="/work/objectlist"
      createPath={SelectObjectPath ? undefined : "/work/objectlist/createObject"}
      SelectObjectPath={SelectObjectPath}
    />
  );
};

export default ObjectListContainer;
