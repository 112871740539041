import React from 'react';
import styles from '../../../assets/styles/LoginPage.module.css';
import logo from '../../../assets/images/logo.png'
import WelcomeSection from '../WelcomeSection';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Helmet } from 'react-helmet';

const ForgotPasswordPage = () => {

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Забыли пароль?</title>
      </Helmet>
      <div className={styles.rightSide}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h2 className={styles.authTitle}>Сброс пароля</h2>
        <ForgotPasswordForm />
      </div>
      <div className={styles.leftSide}>
        <WelcomeSection title='Забыли пароль?' text='Вы на странице сброса пароля вашей электронной почты ООО "Корпорация ДМ". Укажите вашу почту, чтобы вам отправили письмо для восстановления пароля.' buttonName='Авторизоваться' buttonPath='/login' />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
