import { useState } from 'react';
import useAxios from 'src/services/CheckAuth/useAxiosInstance';

export const useForgotPassword = (email) => {
  const [error, setError] = useState('');

  const axiosInstance = useAxios();

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await axiosInstance.post('api/resetpassword', {
        Email: email,
      });

      if (response.status === 200) {
        setError('Письмо успешно отправлено!');
        console.log(response.data);
      }
    } catch (err) {
      if (!err.response) {
        setError('Нет доступа к интернету');
      } else if (err.response.status >= 500) {
        setError('Ошибка сервера, попробуйте позже');
      } else if (err.response.status >= 400) {
        setError('Неправильный email');
      } else {
        setError('Произошла неизвестная ошибка');
      }
    }
  };

  return [error, handleForgotPassword];
};
