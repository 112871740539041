import { Helmet } from 'react-helmet';
import { Box, Typography, Paper, styled } from '@mui/material';
import ARCTable from './ARCTable';
import SearchInput from '../../../../components/List/SearchInput';
import ProtectedComponent from 'src/components/ProtectedComponent';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ItemHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.action.selected,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const ItemDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const ItemField = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const ObjectViewerPage = ({ object, CombinedWorkMaterialList, ID, onSearch }) => {
  const { Name, Address, CompletionDate } = object;

  return (
    <Container>
      <Helmet>
        <title>{object.Name}</title>
      </Helmet>
      <ItemHeader>
        <Typography variant="h4">{Name}</Typography>
        <Typography variant="subtitle1">#{ID}</Typography>
      </ItemHeader>
      <ItemDetails>
        <ItemField><strong>Адрес: </strong>{Address}</ItemField>
        <ItemField><strong>Дата завершения строительства: </strong>{new Date(CompletionDate).toLocaleDateString()}</ItemField>
      </ItemDetails>
      <ProtectedComponent UserRoles={'1'} flag={true}>
        <SearchInput onSearch={onSearch} />
        <Paper>
          <ARCTable CombinedWorkMaterialList={CombinedWorkMaterialList} />
        </Paper>
      </ProtectedComponent>
    </Container>
  );
};

export default ObjectViewerPage;
