import { TextField, Button, Box, IconButton, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WarningIcon from '@mui/icons-material/Warning';
import HideIcon from '@mui/icons-material/HideSource';
import ShowIcon from '@mui/icons-material/UnfoldMore';
import ModalContent from './ModalContent';
import ActionButtons from '../../Requests/RequestCreate/ArcWorkAddItems/ActionButtons';
import RequestItem from './RequestItem';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { roundNumber } from 'src/services/Utils/RoundNumber';

const ItemDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const ItemField = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

const PriceBlock = styled(FlexBox)(({ theme }) => ({
  justifyContent: 'space-between',
  backgroundColor: theme.palette.action.selected,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const StepTwo = ({
  objects,
  formData,
  handleInputChange,
  onAddOrderClick,
  isModalOpen,
  setIsModalOpen,
  orders,
  selectedOrder,
  setSelectedOrder,
  onOrderSelect,
  onSaveOrder,
  onEditOrder,
  onDelete,
  onCreate,
  onSearch,
  requestItems,
  counteragents,
  handleSelectChange,
  inititalIDs
}) => {
  const [isItemsCollapsed, setItemsCollapsed] = useState(false);
  const [isInitialEdited, setIsInitialEdited] = useState(false);
  const [isActiveCreate, setIsActiveCreate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editID, setEditID] = useState(0);

  const [qty, setQty] = useState('');
  const [PPUWithoutNDS, setPPUWithoutNDS] = useState('');
  const [NDS, setNDS] = useState(0);
  const [discountChecked, setDiscountChecked] = useState(false);
  const [discountType, setDiscountType] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [discountSum, setDiscountSum] = useState(0);
  const [UPWithDiscount, setUPWithDiscount] = useState(0);
  const [total, setTotal] = useState(0);

  const [PriceWithNDS, setPriceWithNDS] = useState(0);
  const [FullDiscountSum, setFullDiscountSum] = useState(0);
  const [FullNDSSum, setFullNDSSum] = useState(0);

  useEffect(() => {
    const calcPriceWithNDS = requestItems.length > 0
      ? requestItems.reduce((total, item) => {
        const itemTotal = parseFloat(item.total);
        return total + (!isNaN(itemTotal) ? itemTotal : 0);
      }, 0)
      : 0;
    setPriceWithNDS(calcPriceWithNDS);

    const calcFullNDSSum = requestItems.length > 0
      ? requestItems.reduce((total, item) => {
        const itemTotal = parseFloat(item.total);
        return total + (item.NDS === 0.2 && !isNaN(itemTotal) ? itemTotal / 6 : 0);
      }, 0)
      : 0;
    setFullNDSSum(calcFullNDSSum);

    const calcFullDiscountSum = requestItems.length > 0
      ? requestItems.reduce((total, item) => {
        const itemDiscountSum = parseFloat(item.discountSum);
        return total + (!isNaN(itemDiscountSum) ? itemDiscountSum : 0);
      }, 0)
      : 0;
    setFullDiscountSum(calcFullDiscountSum);

    handleSelectChange('FPWithNDS', calcPriceWithNDS + calcFullDiscountSum);
    handleSelectChange('FPNDS', calcFullNDSSum);
    handleSelectChange('FPWithDiscount', calcPriceWithNDS + calcFullDiscountSum - calcFullNDSSum);

    const newIsActiveCreate = (requestItems.length > 0 && formData.invoiceNumber && formData.invoiceDate);
    setIsActiveCreate(newIsActiveCreate);
  }, [requestItems, formData.invoiceDate, formData.invoiceNumber]);

  const handleBackClick = () => {
    setQty('');
    setPPUWithoutNDS('');
    setDiscountChecked(false);
    setDiscountType('');
    setDiscountValue('');
    setDiscountSum(0);
    setNDS(0);
    setTotal(0);
    setSelectedOrder(null);
  };

  const handleCancelClick = () => {
    if (selectedOrder) {
      handleBackClick();
    }
    setIsEditing(false);
    setEditID(0);
    setIsModalOpen(false);
  };

  const handleEditClick = (order, index) => {
    setEditIndex(index);
    setIsModalOpen(true);
    setSelectedOrder(order.Selected);
    setIsEditing(true);
    setEditID(order.ID || 0);
    setQty(order.Qty);
    setPPUWithoutNDS(order.PPUWithoutNDS);
    setDiscountChecked(order.discount || false);
    setDiscountType(order.discountType || '');
    setDiscountValue(order.discountValue || '');
    setDiscountSum(order.discountSum || 0);
    setUPWithDiscount(order.UPWithDiscount || 0);
    setNDS(order.NDS * 100 || 0);
  };

  useEffect(() => {
    if (Array.isArray(inititalIDs) && inititalIDs.length > 0) {
      inititalIDs.forEach(id => {
        const Order = orders.find(order => order.ID === id);
        if (Order) {
          const newItem = {
            RequestItemID: id,
            Name: Order.Name,
            Qty: '',
            Note: Order.Note,
            RequiredAtDateTime: Order.RequiredAtDateTime,
            Selected: Order,
            PPUWithoutNDS: ''
          };
          onSaveOrder(newItem);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(inititalIDs) && inititalIDs.length > 0) {
      const hasEmptyFields = requestItems.some(item => item.Qty === '' && item.PPUWithoutNDS === '');
      if (!hasEmptyFields) {
        setIsInitialEdited(false);
      } else {
        setIsInitialEdited(true);
      }
    }
  }, [inititalIDs, requestItems]);

  const handleSaveClick = () => {
    if (selectedOrder) {
      const newItem = {
        ID: editID,
        RequestItemID: selectedOrder.ID,
        Measure: selectedOrder.Measure,
        Name: selectedOrder.Name,
        Qty: parseFloat(qty),
        Note: selectedOrder.Note,
        RequiredAtDateTime: selectedOrder.RequiredAtDateTime,
        Selected: selectedOrder,
        total: total,
        PPUWithoutNDS: parseFloat(PPUWithoutNDS),
        NDS: NDS / 100,
        discount: discountChecked,
        discountType: discountType,
        discountValue: discountValue,
        discountSum: discountSum,
        UPWithDiscount: UPWithDiscount,
        RequestID: selectedOrder.RequestID,
        ARCWorkItemID: selectedOrder.ARCWorkItemID,
        ARCID: objects.find(object => object.ID === parseInt(formData.ObjectID)).ARCID
      };
      if (isEditing) {
        onEditOrder(newItem, editIndex);
      } else {
        onSaveOrder(newItem);
      }
      handleCancelClick();
    }
  };

  return (
    <>
      <ItemDetails>
        <ItemField>Объект: <strong>{objects.find(obj => obj.ID === parseInt(formData.ObjectID)).Name}</strong></ItemField>
        <ItemField>Плательщик: <strong>{counteragents.find(payer => payer.ID === parseInt(formData.PayerID)).Name}</strong></ItemField>
        <ItemField>Получатель: <strong>{counteragents.find(recipient => recipient.ID === parseInt(formData.RecipientID)).Name}</strong></ItemField>
        <ItemField>Поставщик: <strong>{counteragents.find(supplier => supplier.ID === parseInt(formData.SupplierID)).Name}</strong></ItemField>
      </ItemDetails>
      <FlexBox>
        <TextField
          fullWidth
          label="Номер Счёта"
          name="invoiceNumber"
          value={formData.invoiceNumber}
          onChange={handleInputChange}
          required
        />
        <IconButton>
          <AttachFileIcon />
        </IconButton>
      </FlexBox>
      <TextField
        fullWidth
        label="Дата Счёта"
        name="invoiceDate"
        type="datetime-local"
        value={formData.invoiceDate}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        required
      />
      <FlexBox>
        <TextField
          fullWidth
          label="Общий вес"
          name="TotalWeight"
          value={formData.TotalWeight}
          onChange={handleInputChange}
        />
        <TextField
          label="Ед. Изм."
          name="MeasureWeight"
          value={formData.MeasureWeight}
          onChange={handleInputChange}
          sx={{ marginLeft: '15px' }}
        />
      </FlexBox>
      <Button onClick={onAddOrderClick} variant="contained" color="primary">Добавить Заказ</Button>
      {isModalOpen && (
        <ModalContent
          handleCancelClick={handleCancelClick}
          orders={orders}
          onOrderSelect={onOrderSelect}
          requestItems={requestItems}
          onSearch={onSearch}
          isEditing={isEditing}
          selectedOrder={selectedOrder}
          handleBackClick={handleBackClick}
          handleSaveClick={handleSaveClick}
          qty={qty}
          setQty={setQty}
          PPUWithoutNDS={PPUWithoutNDS}
          setPPUWithoutNDS={setPPUWithoutNDS}
          NDS={NDS}
          setNDS={setNDS}
          discountChecked={discountChecked}
          setDiscountChecked={setDiscountChecked}
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          setDiscountSum={setDiscountSum}
          setUPWithDiscount={setUPWithDiscount}
          total={total}
          setTotal={setTotal}
        />
      )}
      <PriceBlock>
        <Typography>Итого по счёту, руб. с НДС:<strong>{roundNumber(PriceWithNDS, 4)}</strong></Typography>
        <Typography>Итого сумма НДС, руб.:<strong>{roundNumber(FullNDSSum, 4)}</strong></Typography>
        <Typography>Итого сумма скидки, руб.:<strong>{roundNumber(FullDiscountSum, 4)}</strong></Typography>
      </PriceBlock>
      <ActionButtons onCreate={onCreate} isActiveCreate={isActiveCreate} />
      <FlexBox>
        <Typography variant="h5">
          Заказы
          <IconButton onClick={() => setItemsCollapsed(!isItemsCollapsed)}>
            {isItemsCollapsed ? <ShowIcon /> : <HideIcon />}
          </IconButton>
        </Typography>
        <FlexBox>
          {isInitialEdited && (
            <FlexBox style={{ color: '#ff0000' }}>
              <WarningIcon />
              <Typography>Необходимо отредактировать заказы</Typography>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
      <Box>
        {!isItemsCollapsed && requestItems.map((item, index) => (
          <RequestItem key={index} requestItem={item} onDelete={onDelete} onEdit={handleEditClick} Index={index} />
        ))}
      </Box>
    </>
  );
};

export default StepTwo;
