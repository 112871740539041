export const CHAT_MESSAGE_BORDER_RADIUS = "16px";

export enum ChatMessageType {
  SENT = "sent",
  RECIEVED = "recieved",
}

export enum ChatCardAvatarSizes {
  "small" = 40,
  "default" = 60,
}

export enum ChatCardTypographySizes {
  "small" = 13,
  "default" = 15,
}
