import { isNil } from "lodash";
import { format } from "date-fns";

import { Message } from "src/entities/chat/types";
import { ChatMessageType } from "src/features/chat/constants";
import { Nullable } from "src/shared/types";
import { ChatMessage } from "src/features/chat/components";

export const renderMessages = (
  data: Nullable<Message[]>,
  currentUserId: Nullable<number>
) => {
  return data?.map(({ ID, Content, CT, AuthorID, Author }) => {
    const at = format(CT || new Date(), "dd.MM.yy");
    const type =
      currentUserId === AuthorID
        ? ChatMessageType.SENT
        : ChatMessageType.RECIEVED;

    const content =
      isNil(Content) || Content === "" ? "Сообщение без текста" : Content;

    return (
      <ChatMessage
        key={ID}
        content={content}
        at={at}
        type={type}
        author={Author}
      />
    );
  });
};
