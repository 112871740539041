import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography, styled, IconButton } from '@mui/material';
import { Warning } from '@mui/icons-material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircularProgressBar from './CircularProgressBar';
import VerifiersSection from '../../Invoices/InvoiceViewer/VerifiersSection';

const Container = styled(Paper)`
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ResourceBlock = styled(Paper)`
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const AnalogueMessage = styled('div')`
    color: red;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ArcBlock = styled(Paper)`
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const ExcessWarning = styled('div')`
    color: red;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const Header = styled(Paper)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
`;

const ButtonContainer = styled(Box)`
    margin-bottom: 10px;
`;

const StyledSpan = styled('span')({
    cursor: 'pointer',
    '&:hover': {
        color: '#007bff',
    },
});

const VerifyStyled = styled(Box)({
    display: 'flex',
    justifyContent: 'center'
});

const RequestItemViewerPage = ({ orderData, orderStatuses, onVerify, ID }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Helmet>
                <title>{orderData.RequestedItem.Name}</title>
            </Helmet>
            <ResourceBlock>
                <Typography variant="h5">
                    {orderData.RequestedItem.Name}
                    <span> #{ID}</span>
                    <IconButton>
                        <BorderColorIcon fontSize='medium' />
                    </IconButton>
                </Typography>
                <Typography><strong>Количество:</strong> {orderData.RequestedItem.Qty} {orderData.RequestedItem.Measure}</Typography>
                <Typography><strong>Необходимо на объекте:</strong> {new Date(orderData.RequestedItem.RequiredAtDateTime).toLocaleString()}</Typography>
                {orderData.isAnalogue && (
                    <AnalogueMessage>
                        <Warning color="error" />
                        <Typography>Аналог, вместо: {orderData.RequestedItem.AnalogueInitialName}</Typography>
                    </AnalogueMessage>
                )}
                <ArcBlock>
                    <Typography><strong>Всего по ведомости:</strong> {orderData.ARCTotal}</Typography>
                    {orderData.ARCResidue >= 0 &&
                        <Typography><strong>Остаток по ведомости:</strong> {orderData.ARCResidue.toFixed(6)}</Typography>
                    }
                    {orderData.Excess && (
                        <>
                            <Typography className="excessData"><strong>Превышение по ведомости на:</strong> {((orderData.ARCResidue / orderData.ARCTotal) * -100).toFixed(2)}%</Typography>
                            <Typography><strong>Обоснование по превышению:</strong> {orderData.Note} </Typography>
                        </>
                    )}
                </ArcBlock>
            </ResourceBlock>
            {orderData.Excess && (
                <ExcessWarning>
                    <Warning color="error" />
                    <Typography>Внимание! Превышение по ведомости</Typography>
                </ExcessWarning>
            )}
            <ButtonContainer>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" fullWidth onClick={() => onVerify()}>В работу</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" fullWidth>Добавить рецензента</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" fullWidth>Делегировать</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth>Оценить необходимость</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" style={{ backgroundColor: '#ff8c00', color: 'white' }} fullWidth>Выполнить переоценку</Button>
                    </Grid>
                </Grid>
            </ButtonContainer>
            <Grid container spacing={2} justifyContent="space-around" bottom="20%">
                <Grid item>
                    <CircularProgressBar value={orderData.Percentages.QtyOrdered >= 0 ? Math.round(orderData.Percentages.QtyOrdered * 100) : (100 + Math.abs(Math.round(orderData.Percentages.QtyOrdered * 100)))} text="Заказано" />
                </Grid>
                <Grid item>
                    <CircularProgressBar value={orderData.Percentages.QtyPayed * 100} text="Оплачено" />
                </Grid>
                <Grid item>
                    <CircularProgressBar value={orderData.Percentages.QtyDelivered * 100} text="Доставлено" />
                </Grid>
            </Grid>
            <Header>
                <div>
                    <Typography><strong>Проект:</strong> {orderData.ProjectName}</Typography>
                    <Typography><strong>Объект:</strong> <StyledSpan className="itemName" onClick={() => navigate(`/work/objectlist/${orderData.ObjectID}`)}>{orderData.ObjectName}</StyledSpan></Typography>
                    <Typography><strong>Вид работы:</strong> {orderData.WorkName}</Typography>
                </div>
                <div>
                    <Typography><strong>Статус:</strong> {orderStatuses[orderData.Status]} </Typography>
                    <Typography><strong>Создал:</strong> <StyledSpan className="itemName" onClick={() => navigate(`/work/userdetails/${orderData.CreatedByID}`)}>{orderData.CreatedByName}</StyledSpan></Typography>
                    <Typography><strong>Дата создания:</strong> {new Date(orderData.CreatedDateTime).toLocaleString()}</Typography>
                </div>
            </Header>
            {orderData.Verifiers &&
                <VerifyStyled>
                    <VerifiersSection invoice={orderData} />
                </VerifyStyled>
            }
        </Container>
    );
};

export default RequestItemViewerPage;
