import { useMemo } from "react";
import { LinearProgress, styled } from "@mui/material";
import { format } from "date-fns";
import { generatePath, useNavigate } from "react-router-dom";

import { useChatListQuery, useCurrentChat } from "src/entities/chat/hooks";
import { ChatGroup } from "src/features/chat/components";

export const ChatGroupList = () => {
  const navigate = useNavigate();
  const { currentChat, setCurrentChat } = useCurrentChat();

  const { data, isLoading } = useChatListQuery(undefined, {
    pollingInterval: 1000,
    skipPollingIfUnfocused: true,
  });

  const renderChatList = useMemo(
    () =>
      data?.map((chat) => {
        const { Name, Description, ID, CT } = chat;
        const at = format(CT || new Date(), "dd.MM.yy");

        const onSelect = () => {
          setCurrentChat(chat);
          navigate(
            generatePath("/chat/:chatId", {
              chatId: String(ID),
            })
          );
        };

        return (
          <ChatGroup
            key={ID}
            selected={currentChat?.ID === ID}
            onSelect={onSelect}
            name={Name}
            at={at}
            displayMessage={Description}
          />
        );
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentChat?.ID, data?.length, navigate]
  );

  return (
    <GroupListContainer>
      <GroupListContent>
        {isLoading ? <LinearProgress /> : renderChatList}
      </GroupListContent>
    </GroupListContainer>
  );
};

const GroupListContainer = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const GroupListContent = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
`;
