import ItemListContainer from "src/components/List/ItemListContainer";

const UserListContainer = () => {
    const userFields = [
        { label: 'Подразделения', key: 'Divisions' },
        { label: 'Телефон', key: 'Phone' },
        { label: 'Никнейм', key: 'Nickname' },
        { label: 'Email', key: 'Email' },
        { label: 'Зарегистрирован', key: 'CT', format: (value) => new Date(value).toLocaleDateString() }
    ];

    return (
        <ItemListContainer
            apiUrl="api/actions/page_data?page=ListStaff"
            title="Сотрудники"
            fields={userFields}
            searchKeys={[
                'Name',
                'Phone',
                'Email',
                'Nickname',
                'ID',
                {name: 'Divisions.Name', weight: 0.3}
            ]}
            navigatePath="/settings/userlist"
        />
    );
};

export default UserListContainer;
