import { Avatar, Box, Stack, Typography } from "@mui/material";

import { ChatCardAvatarSizes, ChatCardTypographySizes } from "../constants";

export type ChatGroupCardProps = {
  size?: "small" | "default";
  name: string;
  description?: string;
  src?: string;
};

export const ChatGroupCard: React.FC<ChatGroupCardProps> = ({
  size = "default",
  name,
  description,
  src,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar
        sx={{
          width: ChatCardAvatarSizes[size],
          height: ChatCardAvatarSizes[size],
        }}
        src={src}
      />
      <Box textAlign="initial">
        <Typography fontWeight="bold" fontSize={ChatCardTypographySizes[size]}>
          {name}
        </Typography>
        <Typography fontSize={ChatCardTypographySizes[size] - 1}>
          {description}
        </Typography>
      </Box>
    </Stack>
  );
};
