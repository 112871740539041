import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../assets/styles/TaskPage/TaskList.module.css';
import deleteIcon from '../../../assets/images/fluent-delete-24-regular.png';

const TaskItem = forwardRef(({ task, onDelete }, ref) => {
  const navigate = useNavigate();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(task.id);
  };

  return (
    <div ref={ref} className={styles.item} onClick={() => navigate(`/tasklist/${task.id}`)}>
      <span className={styles.index}>{task.id}</span>
      <div className={styles.nameColumn}>
        <span>{task.subject}</span>
      </div>
      <span>{task.tracker && task.tracker.name}</span>
      <div className={styles.assignedColumn}>
        <span>{task.assigned_to && task.assigned_to.name}</span>
        <button onClick={handleDeleteClick} className={styles.deleteButton}>
          <img src={deleteIcon} alt="Удалить" />
        </button>
      </div>
    </div>
  );
});

export default TaskItem;
