import { NavLink } from 'react-router-dom';
import { Box, styled } from '@mui/system';

const SubNavBar = ({ section }) => {

  const NavButton = styled(NavLink)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    border: 'none',
    padding: '8px',
    flex: 1,
    fontSize: '24px',
    cursor: 'pointer',
    borderRadius: 0,
    transition: 'background-color 0.3s, color 0.3s',
    textDecoration: 'none',
    fontFamily: 'Bellota Text, sans-serif',
    textAlign: 'center',
    boxSizing: 'border-box',
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: 'black',
      fontWeight: 'bold',
    },
  }));

  const SubNavBarContainer = styled(Box)(() =>({
    backgroundColor: '#f0f0f0',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '46px',
    boxSizing: 'border-box',
  }));

  const renderContent = () => {
    switch (section) {
      case 'work':
        return (
          <SubNavBarContainer>
            <NavButton to="/work/objectlist">
              Объекты
            </NavButton>
            <NavButton to="/work/requestlist">
              Заявки
            </NavButton>
            <NavButton to="/work/requestitemlist">
              Заказы
            </NavButton>
            <NavButton to="/work/invoicelist">
              Счета
            </NavButton>
          </SubNavBarContainer>
        );
      case 'settings':
        return (
          <SubNavBarContainer>
            <NavButton to="/settings/userlist">
              Пользователи
            </NavButton>
            <NavButton to="/settings/divisionlist">
              Подразделения
            </NavButton>
          </SubNavBarContainer>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default SubNavBar;
