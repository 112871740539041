import { Box, Typography, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { roundNumber } from 'src/services/Utils/RoundNumber';

const ItemContainer = styled(Box)({
  backgroundColor: '#f8f9fa',
  border: '1px solid #000000',
  borderRadius: '5px',
  padding: '5px',
  marginBottom: '3px',
});

const ItemHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#e9ecef',
  padding: '2px 5px',
  borderRadius: '3px',
  margin: '2px 0',
});

const ItemInfo = styled(Box, { 
  shouldForwardProp: (prop) => prop !== 'isEmpty'
})(({ isEmpty }) => ({
  flexGrow: 1,
  textAlign: 'left',
  padding: '2px',
  color: isEmpty ? '#ff0000' : 'inherit',
}));

const ItemDetails = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2px 5px',
  backgroundColor: '#e9ecef',
  borderRadius: '3px',
  margin: '2px 0',
});

const ItemField = styled(Box)({
  width: '33.3%',
  flexGrow: 1,
  padding: '2px',
  textAlign: 'left',
});

const RequestItem = ({ requestItem, onDelete, onEdit, Index }) => {
  const {
    Name,
    Qty,
    Measure,
    RequiredAtDateTime,
    total,
    PPUWithoutNDS,
    NDS,
    discount,
    discountSum
  } = requestItem;

  const isEmpty = Qty === '' && PPUWithoutNDS === '';

  const handleDeleteClick = () => {
    onDelete(requestItem);
  };

  const handleEditClick = () => {
    onEdit(requestItem, Index);
  };

  return (
    <ItemContainer>
      <ItemHeader>
        <ItemInfo isEmpty={isEmpty}>
          <Typography variant="h6">
            <IconButton onClick={handleEditClick}>
              <BorderColorIcon fontSize='medium'/>
            </IconButton>
            {Name}
          </Typography>
        </ItemInfo>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon color="error" />
        </IconButton>
      </ItemHeader>
      <ItemDetails>
        {Qty &&
        <ItemField>
          <Typography variant="body2">
            <strong>Количество:</strong> {Qty} {Measure}
          </Typography>
        </ItemField>}
        {PPUWithoutNDS &&
        <ItemField>
          <Typography variant="body2">
            <strong>Цена за ед.:</strong> {`${PPUWithoutNDS} руб.`}
          </Typography>
        </ItemField>}
        {(NDS === 0 || NDS === 0.2) &&
        <ItemField>
          <Typography variant="body2">
            <strong>НДС:</strong> {`${NDS * 100} %`}
          </Typography>
        </ItemField>}
        { discount && 
        <ItemField>
          <Typography variant="body2">
            <strong>Скидка:</strong> {`${roundNumber(discountSum, 4)} руб.`}
          </Typography>
        </ItemField>}
        {total &&
        <ItemField>
          <Typography variant="body2">
            <strong>Сумма с НДС:</strong> {`${roundNumber(total, 4)} руб.`}
          </Typography>
        </ItemField>}
        <ItemField>
          <Typography variant="body2">
            <strong>Необходимо на объекте:</strong> {new Date(RequiredAtDateTime).toLocaleString()}
          </Typography>
        </ItemField>
      </ItemDetails>
    </ItemContainer>
  );
};

export default RequestItem;
