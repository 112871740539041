import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TaskViewer from "./TaskViewer";
import loadingGif from "../../../assets/images/loading.gif";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../services/CheckAuth/useAxiosInstance";

const TaskViewerContainer = () => {
  const location = useLocation();
  const match = location.pathname.match(/\/tasklist\/(\d+)/);
  const taskId = match ? match[1] : null;

  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.RedmineToken : null;

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axiosInstance.get(
          `issues/${taskId}.json`,
          {
            headers: {
              "X-dmredirect": "redmine",
              "X-Redmine-API-Key": `${token}`,
            },
          }
        );
        setTask(response.data.issue);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskData();
  }, [taskId, token, axiosInstance]);

  if (loading) {
    return <img src={loadingGif} alt="Loading..." />;
  }

  if (error) {
    return <div>Ошибка: {error.message}</div>;
  }

  return <TaskViewer task={task} />;
};

export default TaskViewerContainer;
