import { Helmet } from 'react-helmet';
import { styled } from '@mui/system';
import InvoiceHeader from './InvoiceHeader';
import VerifiersSection from './VerifiersSection';
import InvoiceItemsTable from './InvoiceItemsTable';

const InvoiceViewerContainer = styled('div')({
  padding: 20,
});

const HeaderAndVerifiers = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const InvoiceViewer = ({ invoice, statuses }) => (
  <InvoiceViewerContainer>
    <Helmet>
      <title>Просмотр счёта</title>
    </Helmet>
    <HeaderAndVerifiers>
      <InvoiceHeader invoice={invoice} statuses={statuses} />
      <VerifiersSection invoice={invoice} />
    </HeaderAndVerifiers>
    <InvoiceItemsTable invoice={invoice} />
  </InvoiceViewerContainer>
);

export default InvoiceViewer;
