import axios from 'axios';
import { useAuth } from 'src/services/CheckAuth/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const errorMessage = 'Произошла ошибка. Пожалуйста, попробуйте снова позже.';

const useAxios = () => {
  const { logout } = useAuth();

  const axiosInstance = axios.create({ baseURL: 'https://s.dmcorporation.ru/' });

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 401:
            logout();
            toast.error('Ошибка 401: Неавторизован');
            break;
          case 400:
            toast.error('Ошибка 400: Некорректный запрос');
            break;
          case 403:
            toast.error('Ошибка 403: Доступ запрещен');
            break;
          case 404:
            toast.error('Ошибка 404: Ресурс не найден');
            break;
          case 500:
            toast.error('Ошибка 500: Внутренняя ошибка сервера');
            break;
          case 502:
            toast.error('Ошибка 502: Плохой шлюз');
            break;
          case 503:
            toast.error('Ошибка 503: Сервис недоступен');
            break;
          case 504:
            toast.error('Ошибка 504: Превышено время ожидания шлюза');
            break;
          default:
            toast.error(`Ошибка ${status}: ${errorMessage}`);
            break;
        }
      } else {
        toast.error(`Ошибка: ${error.message || errorMessage}`);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
