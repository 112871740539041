import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { parse, format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RequestItem from "../ArcWorkAddItems/RequestItem";
import ActionButtons from "../ArcWorkAddItems/ActionButtons";
import NeVedForm from "./NeVedForm";

const RequestNeVed = ({
  object,
  requestItems,
  onSaveResource,
  onDelete,
  onCreate,
  onEditResource
}) => {
  const [name, setName] = useState("");
  const [qty, setQty] = useState("");
  const [measure, setMeasure] = useState("");
  const [requiredAtDateTime, setRequiredAtDateTime] = useState("");
  const [note, setNote] = useState("");
  const [isActiveSave, setIsActiveSave] = useState(false);
  const [isActiveCreate, setIsActiveCreate] = useState(false);
  const [isItemsCollapsed, setItemsCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editID, setEditID] = useState(0);

  useEffect(() => {
    const newIsActiveCreate = requestItems.length > 0;
    setIsActiveCreate(newIsActiveCreate);

    const newIsActiveSave = name && qty && measure && requiredAtDateTime && note;
    setIsActiveSave(newIsActiveSave);
  }, [name, qty, measure, requiredAtDateTime, note, requestItems]);

  const handleEditClick = (resource, index) => {
    setEditIndex(index);
    setIsEditing(true);
    const date = new Date(resource.RequiredAtDateTime);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    setRequiredAtDateTime(formattedDate);
    setQty(resource.Qty);
    setNote(resource.Note);
    setName(resource.Name);
    setMeasure(resource.Measure);
    setEditID(resource.ID || 0);
  };

  const handleCancelClick = () => {
    setName("");
    setQty("");
    setMeasure("");
    setRequiredAtDateTime("");
    setNote("");
    setEditID(0);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    const date = requiredAtDateTime;

    const completeDate = `${date}:00.000Z`;

    const parsedDate = parse(
      completeDate,
      "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
      new Date()
    );
    const formattedDate = format(
      parsedDate,
      "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"
    );

    const newItem = {
      ID: editID,
      Type: 1,
      Status: 0,
      RequestID: 0,
      ProjectID: 11,
      ObjectID: object.ID,
      Measure: measure,
      Name: name,
      Qty: parseFloat(qty),
      IsAnalogueSet: false,
      AnalogueID: null,
      Note: note,
      RequiredAtDateTime: formattedDate,
    };

    if (isEditing) {
      onEditResource(newItem, editIndex);
      setIsEditing(false);
    } else {
      onSaveResource(newItem);
    }
    handleCancelClick();
  };

  if (!object)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  return (
    <Box p={2}>
      <Helmet>
        <title>Редактирование заявки</title>
      </Helmet>
      <Typography variant="h4" component="div" gutterBottom>
        {object.Name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Адрес:</strong> {object.Address}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Дата завершения строительства:</strong>{" "}
            {new Date(object.CompletionDate).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>

      <NeVedForm
        name={name}
        setName={setName}
        qty={qty}
        setQty={setQty}
        measure={measure}
        setMeasure={setMeasure}
        requiredAtDateTime={requiredAtDateTime}
        setRequiredAtDateTime={setRequiredAtDateTime}
        note={note}
        setNote={setNote}
        isEditing={isEditing}
        isActiveSave={isActiveSave}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
      />

      <ActionButtons
        isActiveCreate={isActiveCreate}
        onCreate={onCreate}
      />

      <Box mt={2} mb={1}>
        <Typography variant="h5" component="div" display="flex" alignItems="center">
          Ресурсы{" "}
          <IconButton
            onClick={() => setItemsCollapsed(!isItemsCollapsed)}
            aria-label={isItemsCollapsed ? "Развернуть" : "Свернуть"}
            size="small"
          >
            {isItemsCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Typography>
      </Box>
      <Collapse in={!isItemsCollapsed}>
        <Box mt={1}>
          {requestItems.slice().map((item, index) => (
            <RequestItem key={index} requestItem={item} onDelete={onDelete} onEdit={handleEditClick} Index={index} />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default RequestNeVed;
