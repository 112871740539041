import React, { useState } from 'react';
import { useRegister } from './RegisterPageLogic';
import styles from '../../../assets/styles/LoginPage.module.css';
import invisibleIcon from '../../../assets/images/streamline-invisible-2.svg';


const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [error, handleRegister] = useRegister(email, password1, password2, name);

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <form onSubmit={handleRegister} className={styles.form}>
      <div className={styles.inputGroup}>
        <label htmlFor="name" className={styles.label}>Имя:</label>
        <input
          type="name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.input}
          required
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
          required
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="password" className={styles.label}>Пароль:</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPassword1 ? 'text' : 'password'}
            id="password1"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            className={styles.input}
            required
          />
          <button type="button" onClick={toggleShowPassword1} className={styles.passwordToggle}>
            <img src={invisibleIcon} alt="Toggle visibility" className={styles.icon} />
          </button>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="password" className={styles.label}>Подтвердите пароль:</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPassword2 ? 'text' : 'password'}
            id="password2"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            className={styles.input}
            required
          />
          <button type="button" onClick={toggleShowPassword2} className={styles.passwordToggle}>
            <img src={invisibleIcon} alt="Toggle visibility" className={styles.icon} />
          </button>
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <button type="submit" className={styles.loginButton}>Регистрация</button>
    </form>
  );
};

export default RegisterForm;
