import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../../services/CheckAuth/useAxiosInstance';

export const useNewPassword = (password1, password2) => {
  const [error, setError] = useState('');
  const navigate = useNavigate('');
  const axiosInstance = useAxios();

  const handleNewPassword = async (event) => {
    event.preventDefault();
    setError('');

    if (password1 !== password2) {
      setError('Пароли не совпадают')
    } else {
      try {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('Token');

        const response = await axiosInstance.post('api/resetpassword', 
        {
          Token: token,
          Password: password1
        }
      );
  
        if (response.status === 200) {
          navigate('/login')
          console.log(response.data);
        }

      } catch (err) {
        if (!err.response) {
          setError('Нет доступа к интернету');
        } else if (err.response.status >= 500) {
          setError('Ошибка сервера, попробуйте позже');
        } else if (err.response.status >= 400) {
          setError('Не получилось обновить данные');
        } else {
          setError('Произошла неизвестная ошибка');
        }
      }
    }
  };

  return [error, handleNewPassword];
};
