import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../../../services/Utils/FormatNumber';
import { styled } from '@mui/system';

const ItemName = styled('span')({
  cursor: 'pointer',
  '&:hover': {
    color: '#007bff',
  },
});

const Item = ({ item }) => {
  const navigate = useNavigate();
  return (
    <TableRow>
      <TableCell>{item.InvoiceItemID}</TableCell>
      <TableCell>{new Date(item.RequiredAtDateTime).toLocaleDateString()}</TableCell>
      <TableCell>
        <ItemName onClick={() => navigate(`/work/requestitemlist/${item.RequestItemID}`)}>
          {item.Name}
        </ItemName>
      </TableCell>
      <TableCell>{item.QtyInvoice}</TableCell>
      <TableCell>{item.Measure}</TableCell>
      <TableCell>{formatNumber(item.TotalPrice)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>{formatNumber(item.TotalPrice)}</TableCell>
    </TableRow>
  );
};

export default Item;
