import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/TaskPage/TaskCreateForm.module.css';
import { buildTree, formatTree } from '../../services/Utils/TreeUtil';

const TaskForm = ({ projects, users, onSubmit, task={}, type }) => {

  const [formattedProjectTree, setFormattedProjectTree] = useState([]);

  useEffect(() => {
      const tree = buildTree(projects);

      const formattedTree = formatTree(tree);
      setFormattedProjectTree(formattedTree);
  }, [projects]);

  const [formData, setFormData] = useState({
    project_id: task.project?.id || "",
    tracker_id: task.tracker?.id || "",
    subject: task.subject || "",
    description: task.description || "",
    status_id: task.status?.id || "",
    priority_id: task.priority?.id || "",
    assigned_to_id: task.assigned_to?.id || "",
    parent_issue_id: "",
    start_date: task.start_date || "",
    due_date: task.due_date || "",
    estimated_hours: task.estimated_hours || "",
    done_ratio: task.done_ratio || ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Проект:</label>
          <select name="project_id" value={formData.project_id} onChange={handleChange}>
          <option value="">Выберите проект</option>
                {formattedProjectTree.map((project, index) => (
                    <option key={index} value={project.id}>{project.name}</option>
                ))}
          </select>
        </div>
        <div>
          <label>Тема:</label>
          <input type="text" name="subject" value={formData.subject} onChange={handleChange} />
        </div>
        <div>
          <label>Описание:</label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div>
          <label>Приоритет:</label>
          <select name="priority_id" value={formData.priority_id} onChange={handleChange}>
            <option value="">Выберите приоритет</option>
            <option value="1">Низкий</option>
            <option value="2">Нормальный</option>
            <option value="3">Высокий</option>
            <option value="4">Срочный</option>
            <option value="5">Немедленный</option>
          </select>
        </div>
        <div>
          <label>Назначена:</label>
          <select name="assigned_to" value={formData.assigned_to} onChange={handleChange}>
            <option value="">Выберите пользователя</option>
            {users && users.map(user => (
              <option key={user.ID} value={user.RedmineUserID}>{user.Name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Дата начала:</label>
          <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} />
        </div>
        <div>
          <label>Срок завершения:</label>
          <input type="date" name="due_date" value={formData.due_date} onChange={handleChange} />
        </div>
        <div>
          <label>Оценка временных затрат:</label>
          <input type="number" name="estimated_hours" value={formData.estimated_hours} onChange={handleChange} />
        </div>
        {type !== '1' &&
        <div>
          <label>Процент завершения:</label>
          <input type="number" name="done_ratio" value={formData.done_ratio} onChange={handleChange} />
        </div>}
        <button type="submit">Сохранить</button>
      </form>
    </div>
  );
}

export default TaskForm;
