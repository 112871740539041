import { useState, useEffect } from "react";
import CounteragentViewer from "./CounteragentViewer";
import { useParams } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../services/CheckAuth/useAxiosInstance";

const CounterAgentViewerContainer = () => {
  const { counteragentID } = useParams();
  const [counteragent, setCounteragent] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const axiosInstance = useAxios();

  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/bp/counteragent?ID=${counteragentID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );
        setCounteragent(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [token, counteragentID]);

  if (loading) return;
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <CircularProgress />
  </Box>;

  return <CounteragentViewer counteragent={counteragent} />;
};

export default CounterAgentViewerContainer;
