import { AttachFile, Send } from "@mui/icons-material";
import { IconButton, InputBase, styled } from "@mui/material";
import { useForm } from "react-hook-form";

import { useCurrentUserQuery } from "src/entities/auth/hooks";
import {
  useCurrentChat,
  useSendMessageMutation,
} from "src/entities/chat/hooks";
import { Button } from "src/shared/components";

export const ChatTypearea = () => {
  const { currentChat } = useCurrentChat();
  const { data: currentUser } = useCurrentUserQuery();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      text: "",
    },
  });

  const [sendMessageMutation] = useSendMessageMutation();

  const onSubmit = async ({ text }: { text: string }) => {
    await sendMessageMutation({
      ChatID: currentChatId || 0,
      AuthorID: currentUser?.UserID || 0,
      Content: text,
    });
    reset();
  };

  if (!currentChat) {
    return null;
  }

  const currentChatId = currentChat?.ID;

  return (
    <ChatTypeareaForm onSubmit={handleSubmit(onSubmit)}>
      <IconButton>
        <AttachFile />
      </IconButton>
      <InputBase placeholder="Сообщение..." {...register("text")} />
      <Button type="submit" variant="outlined">
        <Send />
      </Button>
    </ChatTypeareaForm>
  );
};

const ChatTypeareaForm = styled("form")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;
