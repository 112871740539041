import { baseApi } from "src/shared/api";
import { ApiTag } from "src/shared/constants/api-tag";

import {
  ChatListResponse,
  CreateChatRequestPayload,
  Message,
  MessageListRequestPayload,
  SendMessageRequestPayload,
} from "./types";
import { HttpMethod } from "src/shared/constants/http";

export const chatApi = baseApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    participants: query<any, string>({
      query: (id) => ({
        url: `/api/actions/chat_participants/${id}`,
      }),
      providesTags: [ApiTag.PARTICIPANT],
    }),
    messages: query<Message[], MessageListRequestPayload>({
      query: (data) => ({
        url: `/api/actions/chat_messages`,
        params: data,
      }),
      providesTags: [ApiTag.MESSAGE],
      // serializeQueryArgs: ({ queryArgs }) => {
      //   return queryArgs.ID;
      // },
      // merge: (currentCache, newItems) => {
      //   currentCache.push(...newItems);
      // },
      // forceRefetch({ currentArg, previousArg }) {
      //   return (
      //     currentArg?.LAST !== previousArg?.LAST ||
      //     currentArg?.COUNT !== previousArg?.COUNT
      //   );
      // }, 
    }),
    chatList: query<ChatListResponse, void>({
      query: () => "/api/actions/user_chats",
      providesTags: [ApiTag.CHAT],
    }),
    chatById: query<any, string>({
      query: (chatId) => ({
        url: `/api/bp/chat/${chatId}`,
        method: HttpMethod.GET,
      }),
      providesTags: [ApiTag.CHAT],
    }),
    createChat: mutation<void, CreateChatRequestPayload>({
      query: (data) => ({
        url: "/api/actions/create_chat",
        body: data,
        method: HttpMethod.POST,
      }),
      invalidatesTags: [ApiTag.CHAT],
    }),
    sendMessage: mutation<Message[], SendMessageRequestPayload>({
      query: (data) => ({
        url: "/api/bp/message",
        method: HttpMethod.POST,
        body: data,
      }),
      invalidatesTags: [ApiTag.MESSAGE],
    }),
    deleteChat: mutation<Message[], number>({
      query: (ID) => ({
        url: "/api/bp/chat",
        method: HttpMethod.DELETE,
        params: {
          ID,
        },
      }),
      invalidatesTags: [ApiTag.MESSAGE],
    }),
  }),
});
