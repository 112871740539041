import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalConfirmation from "../../../../../components/ModalConfirmation";
import RequestNeVed from "./RequestNeVed";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "../../../../../services/CheckAuth/useAxiosInstance";

const RequestNeVedContainer = () => {
  const { objectID, requestID } = useParams();
  const [object, setObject] = useState(null);
  const [requestItems, setRequestItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [createConfirmationOpen, setCreateConfirmationOpen] = useState(false);
  const [createStatus, setCreateStatus] = useState(null);
  const [createMessage, setCreateMessage] = useState("");
  const navigate = useNavigate();

  const { user } = useAuth();
  const axiosInstance = useAxios();
  const token = user ? user.Token : null;

  useEffect(() => {
    const fetchObject = async () => {
      try {
        const response = await axiosInstance.get(
          `api/bp/object?ID=${objectID}`,
          {
            headers: {
              "X-dmtoken": token,
            },
          }
        );

        setObject(response.data);
      } catch (error) {
        console.error("Ошибка при получении данных объекта:", error);
      }
    };
    fetchObject();
  }, [objectID, token]);

  useEffect(() => {
    const fetchParamsRequest = async () => {
      try {
        const requestResponse = await axiosInstance.get(
          `api/actions/page_data?page=ViewingZayavka&ID=${requestID}`,
          {
            headers: { "X-dmtoken": token }
          }
        );
        const paramsOrders = requestResponse.data.RequestItems;
        paramsOrders.forEach(newOrder => {
          const newItem = {
            ID: newOrder.ID,
            Type: 1,
            Status: 1,
            RequestID: parseInt(requestID),
            ProjectID: 11,
            ObjectID: parseInt(objectID),
            Measure: newOrder.Measure,
            Name: newOrder.Name,
            Qty: newOrder.Qty,
            IsAnalogueSet: false,
            AnalogueID: null,
            Note: newOrder.Note,
            RequiredAtDateTime: newOrder.RequiredAtDateTime,
          };
          setRequestItems((prevRequestItems) => [...prevRequestItems, newItem]);
        });
      } catch (error) {
        console.error("Error fetching request:", error);
      }
    }

    if (requestID) {
      fetchParamsRequest();
    }
  }, [])

  const handleDeleteResource = (resourceToDelete) => {
    setDeleteCandidate(resourceToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    setRequestItems((prevItems) =>
      prevItems.filter((item) => item !== deleteCandidate)
    );
    setDeletedItems((prevDeletedItems) => [...prevDeletedItems, deleteCandidate]);
    setDeleteConfirmationOpen(false);
  };

  const handleCreateRequest = (status) => {
    setCreateStatus(status);
    if (status === 1) setCreateMessage("Сохранить заявку как черновик?");
    else if (status === 2) setCreateMessage("Отправить заявку в работу?");
    setCreateConfirmationOpen(true);
  };

  const handleCreateConfirmaion = () => {
    handleSubmit(createStatus);
    setCreateConfirmationOpen(false);
  };

  const handleSaveResource = (newItem) => {
    setRequestItems((prevItems) => [newItem, ...prevItems]);
  };

  const handleEditResource = (EditedResource, index) => {
    const updatedItems = [...requestItems];
    updatedItems[index] = EditedResource;
    setRequestItems(updatedItems);
  };

  const handleSubmit = async (status) => {
    try {
      if (!requestID) {
        const requestResponse = await axiosInstance.post(
          "api/bp/request",
          {
            Type: 1,
            Status: status,
            ProjectID: 1,
            ObjectID: parseInt(objectID),
          },
          {
            headers: { "X-dmtoken": token },
          }
        );

        const requestId = requestResponse.data.ID;

        for (const item of requestItems) {
          item.Status = status;
          item.RequestID = requestId;
          await axiosInstance.post(
            "api/bp/RequestItem",
            item,
            {
              headers: { "X-dmtoken": token },
            }
          );
        }
      } else {
        await axiosInstance.put(
          `api/bp/request?ID=${requestID}`,
          { Status: status },
          { headers: { "X-dmtoken": token } }
        )
        for (const item of requestItems) {
          item.Status = status;
          item.RequestID = parseInt(requestID);
          if (item.ID === 0) {
            await axiosInstance.post(
              "api/bp/RequestItem",
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          } else if (item.ID !== 0) {
            await axiosInstance.put(
              `api/bp/RequestItem?ID=${item.ID}`,
              item,
              {
                headers: { "X-dmtoken": token },
              }
            );
          }
        }
      }
      for (const item of deletedItems) {
        if (item.ID)
          await axiosInstance.delete(
            `api/bp/requestitem?ID=${item.ID}`,
            {
              headers: { "X-dmtoken": token },
            }
          );
      }
      navigate("/works/requestlist");
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };
  return (
    <>
      <RequestNeVed
        object={object}
        requestItems={requestItems}
        onSaveResource={handleSaveResource}
        onDelete={handleDeleteResource}
        onCreate={handleCreateRequest}
        onEditResource={handleEditResource}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={handleConfirmDelete}
        message={`Удалить ${deleteCandidate && deleteCandidate.Name}?`}
      />
      <ModalConfirmation
        isOpen={createConfirmationOpen}
        onClose={() => setCreateConfirmationOpen(false)}
        onConfirm={handleCreateConfirmaion}
        message={createMessage}
      />
    </>
  );
};

export default RequestNeVedContainer;
