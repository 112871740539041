import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/services/CheckAuth/AuthContext";
import useAxios from "src/services/CheckAuth/useAxiosInstance";
import { Box, CircularProgress, Modal, styled } from "@mui/material";
import Fuse from "fuse.js";
import ToastifyMenu from "./ToastifyMenu";
import ItemList from "./ItemList";
import { useNavigate, useParams } from "react-router-dom";

const ItemListContainer = ({
    apiUrl,
    listKey,
    enumKey,
    title,
    fields,
    searchKeys,
    navigatePath,
    modalComponent: ModalComponent,
    createPath,
    multiSelect,
    SelectObjectPath
}) => {
    const { objectName } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const { user } = useAuth();
    const axiosInstance = useAxios();
    const token = user ? user.Token : null;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(apiUrl, {
                    headers: {
                        "X-dmtoken": token,
                    },
                });
                if (objectName) {
                    setItems(response.data.List.filter(work => work.ObjectName === objectName));
                    setFilteredItems(response.data.List.filter(work => work.ObjectName === objectName));
                } else {
                    setItems(response.data[listKey] || response.data || []);
                    setFilteredItems(response.data[listKey] || response.data || []);
                }
            } catch (error) {
                console.error(`Error fetching ${listKey}:`, error);
            }
        };

        const fetchEnums = async () => {
            try {
                const response = await axiosInstance.get("api/actions/enums");
                setStatuses(response.data[enumKey]?.statuses || []);
            } catch (error) {
                console.error("Error fetching statuses:", error);
            }
        };

        fetchData();
        if (enumKey)
            fetchEnums();
    }, [token]);

    const fuse = useMemo(() => {
        return new Fuse(items, {
            keys: searchKeys,
            includeScore: true,
            threshold: 0.3
        });
    }, [items, searchKeys]);

    const handleSearch = (query) => {
        const lowercasedQuery = query.toLowerCase();
        if (lowercasedQuery !== '') {
            setFilteredItems(fuse.search(lowercasedQuery).map(result => result.item));
        } else {
            setFilteredItems(items);
        }
    };

    const handleSelectAll = (selectAll) => {
        setSelectAll(selectAll);
        if (selectAll) {
            setSelectedItems(filteredItems.map(item => item));
        } else {
            setSelectedItems([]);
        }
    };

    const toggleItemSelection = (item) => {
        setSelectedItems((prevState) => {
            const newSelectedItems = prevState.includes(item)
                ? prevState.filter(prevItem => prevItem.ID !== item.ID)
                : [...prevState, item];

            setSelectAll(newSelectedItems.length === filteredItems.length);
            return newSelectedItems;
        });
    };

    const closeMenu = () => {
        setSelectedItems([]);
        setSelectAll(false);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleCreateClick = () => {
        navigate(createPath);
    }

    if (!items.length)
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );

    return (
        <>
            <ItemList
                title={title}
                items={filteredItems}
                fields={fields}
                navigatePath={navigatePath}
                onSearch={handleSearch}
                onSelectAll={multiSelect ? handleSelectAll : undefined}
                selectedItems={multiSelect ? selectedItems : undefined}
                toggleItemSelection={multiSelect ? toggleItemSelection : undefined}
                selectAll={multiSelect ? selectAll : undefined}
                onCreate={createPath ? handleCreateClick : (ModalComponent && openModal) || undefined}
                statuses={statuses}
                SelectObjectPath={SelectObjectPath}
            />
            {ModalComponent && (
                <Modal open={isModalOpen} onClose={closeModal}>
                    <ModalBox>
                        <ModalComponent onClose={closeModal} />
                    </ModalBox>
                </Modal>
            )}
            {selectedItems.length > 0 && <ToastifyMenu onClose={closeMenu} selectedItems={selectedItems} />}
        </>
    )
};

export default ItemListContainer;

const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    boxShadow: 24,
    padding: 4,
    borderRadius: 2,
    width: '80%',
    maxWidth: '600px',
});
