import { TextField, Paper } from '@mui/material';

const CsvInput = ({ csvValue, onCsvInputChange }) => {
  return (
    <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
      <TextField
        label="CSV"
        multiline
        rows={6}
        fullWidth
        variant="outlined"
        value={csvValue}
        onChange={(event) => onCsvInputChange(event.target.value)}
      />
    </Paper>
  );
};

export default CsvInput;
