import { Checkbox, FormControlLabel, styled, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchInput from './SearchInput';

const SearchBarContainer = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateButton = styled(Fab)({
    alignSelf: 'flex-end',
    width: '60px'
});

const SearchBar = ({ onSearch, onSelectAll, selectAll, onCreate }) => {

    const handleSelectAllChange = (e) => {
        onSelectAll(e.target.checked);
    };

    return (
        <SearchBarContainer>
            {onSelectAll &&
                <FormControlLabel
                    control={<Checkbox checked={selectAll} onChange={handleSelectAllChange} />}
                    label="Выбрать все"
                    name='selectAll'
                />
            }
            {onCreate &&
                <CreateButton color="primary" onClick={onCreate}>
                    <AddIcon />
                </CreateButton>
            }
            {onSearch &&
                <SearchInput onSearch={onSearch} />
            }
        </SearchBarContainer>
    );
};

export default SearchBar;
